import React from 'react'
import PropTypes from 'prop-types'
import FormatHelper from '../../helpers/format-helper'

const ReservationsTable = ({ reservations, sorting = false, handleSort = undefined, sort, sortedField }) => {
  const renderSortButton = (field) => {
    let buttonContent

    if (sort === 'desc' && sortedField === field) {
      buttonContent = <p className="text-indigo-600">↓</p>
    } else if (sort === 'asc' && sortedField === field) {
      buttonContent = <p className="text-indigo-600">↑</p>
    } else {
      buttonContent = <p>⇅</p>
    }

    return (
      <button type="button" onClick={() => handleSort(field)}>
        {buttonContent}
      </button>
    )
  }

  return (
    <>
      {reservations.length > 0
        ? <table className="table-auto w-full text-xs lg:text-base">
          <thead>
          <tr className="text-left">
            <th className="border p-2">ID {sorting && renderSortButton('id')}</th>
            <th className="border p-2">Customer&apos;s name {sorting && renderSortButton('customer_name')}</th>
            <th className="border p-2">Reservation created {sorting && renderSortButton('created_at')}</th>
            <th className="border p-2">Pickup date {sorting && renderSortButton('pickup_date')}</th>
            <th className="border p-2">Status {sorting && renderSortButton('status')}</th>
            <th className="border p-2">Due Back {sorting && renderSortButton('due_back')}</th>
            <th className="border p-2">Made Res. {sorting && renderSortButton('made_res')}</th>
          </tr>
          </thead>
          <tbody>
          {
            reservations.map((res) => (
              <tr className="text-left sm:text-[10px] md:text-[10px]" key={res.id}>
                <td className="border p-2 text-indigo-600">
                  <a href={`reservations/${res.id}`}>{res.id}</a>
                </td>
                <td className="border p-2 text-indigo-600">
                  <a href={`contacts/${res.contact?.id}`}>{res.contact?.name}</a>
                </td>
                <td className="border p-2">{new Date(res.created).toDateString()}</td>
                <td className="border p-2">{res.pickupDate && new Date(res.pickupDate).toDateString()}</td>
                <td className="border p-2">{res.statusName}</td>
                <td className="border p-2">{res.dueBack && new Date(res.dueBack).toDateString()}</td>
                <td className="border p-2">{FormatHelper.truncate(res.reservationDetail.createdBy)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        : <h2 className="flex justify-center font-semibold text-lg mt-4">Nothing to see here.</h2>
      }
    </>
  )
}

ReservationsTable.propTypes = {
  handleSort: PropTypes.func,
  reservations: PropTypes.array.isRequired,
  sort: PropTypes.string,
  sortedField: PropTypes.string,
  sorting: PropTypes.bool
}

export default ReservationsTable
