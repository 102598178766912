import React from 'react'
import PropTypes from 'prop-types'

const ReportLostItemReadOnly = ({ item, lostReason, reasonText }) => {
  return (
    <div className="bg-white rounded-md shadow px-8">
      <div className="flex gap-10 items-stretch justify-between">
        <div className="flex">
          <div className="flex items-center w-32">
            <img
              className="w-32"
              src={ item.product_descriptor.image_url }
            />
          </div>
        </div>

        <div className="flex flex-col items-start justify-center text-left w-48">
          <p>Rocksbox SKU</p>
          <p>{ item.product_descriptor.sku }</p>
        </div>

        <div className="flex flex-col items-start justify-center text-left w-48">
          <p>Zales SKU</p>
          <p>{ item.product_descriptor.designer_sku }</p>
        </div>

        <div className="flex flex-col gap-1 items-end justify-center w-48">
          {(reasonText) && <div>{reasonText}</div>}
        </div>
      </div>
    </div>
  )
}

ReportLostItemReadOnly.propTypes = {
  item: PropTypes.object.isRequired,
  lostReason: PropTypes.string.isRequired,
  reasonText: PropTypes.string.isRequired
}

export default ReportLostItemReadOnly
