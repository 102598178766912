import ReservationsApi from '../api/ReservationsApi'
import ErrorHelper from './error-helper'

export const handleRecurlySuccess = async (params, setError, setCreateRecurlyIsLoading, onSuccess = undefined) => {
  // make api request to create recurly customer
  await ReservationsApi.createReservationRecurlyContact(params)
    .then(res => res.json())
    .then(json => {
      if (json.error_message) {
        setCreateRecurlyIsLoading(false)
        ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document.querySelector('div'))
      } else {
        if (onSuccess) {
          onSuccess()
        }
      }
    })
    .catch(() => {
      ErrorHelper.addError({ bannerMessage: 'There was an error creating a Recurly account' }, setError, document.querySelector('div'))
    })
}
