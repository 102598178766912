import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FilteringHelper from '../../helpers/filtering-helper'

const ReservationsFilter = ({ reservationStatuses, appliedFilters, setAppliedStatusFilters }) => {
  const [checkedStatuses, setCheckedStatuses] = useState(appliedFilters)
  const [displayed, setDisplayed] = useState(false)
  const [selectAll, setSelectAll] = useState(false)

  const toggleDisplay = () => setDisplayed(prevDisplay => !prevDisplay)

  const applyFilter = (e) => {
    e.preventDefault()
    setAppliedStatusFilters(checkedStatuses)
    toggleDisplay()
  }

  const handleChangeStatuses = (event) => {
    const item = event.target.value
    const isChecked = event.target.checked
    setCheckedStatuses(prevState => ({ ...prevState, [item]: isChecked }))
  }

  const handleSelectAll = () => {
    Object.values(reservationStatuses).forEach((value) => {
      setCheckedStatuses(prevState => ({ ...prevState, [value]: !selectAll }))
    })
    setSelectAll(prevState => !prevState)
  }

  return (
    <>
      <button className="bg-indigo-500 rounded text-white p-2 w-24" onClick={() => toggleDisplay()}>
        Filter
      </button>

      { displayed &&
        <div id='display-filter-modal' className='fixed flex inset-0 backdrop-blur-sm bg-black/60 transition-opacity overflow-x-hidden overflow-y-auto z-10'>
          <div className='bg-white p-8 inset-0 text-left rounded-md sm:w-[40%] lg:w-[30%] my-auto mx-auto py-10'>
          <div className='flex justify-end'>
            <button onClick={() => toggleDisplay()} className="font-bold text-sm p-2" type='button'>X</button>
          </div>
          <div className="px-4">
            <h2 className='reservations-filter-title text-lg font-medium text-gray-900 '>Filter</h2>
            <form>
              <h5 className="text-base font-medium py-2">Reservation Status</h5>
              <label>
                <input checked={selectAll} className='mr-2 accent-indigo-600' name='Select All' onChange={handleSelectAll} type='checkbox' />
                Select All
              </label>
              <br/>
              {FilteringHelper.renderCheckboxes(reservationStatuses, checkedStatuses, handleChangeStatuses)}
              <div className='flex'>
                <button onClick={applyFilter} className="px-8 py-2 p-2 bg-indigo-600 rounded text-white ml-auto">Apply</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      }
    </>
  )
}

ReservationsFilter.propTypes = {
  reservationStatuses: PropTypes.object.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  setAppliedStatusFilters: PropTypes.func.isRequired
}

export default ReservationsFilter
