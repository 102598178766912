import React from 'react'
import DatesHelper from '../../helpers/dates-helper'
import PropTypes from 'prop-types'

const ReturnDateInput = ({ pickupDate }) => {
  return (
    <label htmlFor='return_date' className='mt-2 block font-bold text-left'>Return date
      <input
        type='date'
        name='return_date'
        className='pl-4 sm:w-[75vw] block w-full rounded-md shadow-lg border-solid border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 h-12 font-normal md:text-md'
        disabled
        value={DatesHelper.formatForDateInput(DatesHelper.addWeeksToDate(new Date(pickupDate), 2))}
      />
    </label>
  )
}

ReturnDateInput.propTypes = {
  pickupDate: PropTypes.string.isRequired
}

export default ReturnDateInput
