import camelize from 'camelize'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReservationsApi from '../../../../api/ReservationsApi'
import AddOrEditPickupPersonModal from './AddOrEditPickupPersonModal'
import PickupPersonList from './PickupPersonList'
import ErrorHelper from '../../../../helpers/error-helper'
import ErrorPopup from '../../../shared/ErrorPopup'
import { removePickupPerson } from '../../../../helpers/pickup-person-helper'
import PopUpModal from '../../../shared/PopUpModal'
import FormValidations from '../../../../helpers/form-validations'

const PickupPersonContainer = ({ isVisible, reservationInfo, setReservationInfo }) => {
  const [pickupPersonModal, setPickupPersonModal] = useState(false)
  const [editedPickupPersonId, setEditedPickUpPersonId] = useState(null)
  const [removedPickupPersonId, setRemovedPickUpPersonId] = useState(null)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [error, setError] = useState(null)

  const closeModal = () => {
    if (isEditModal()) {
      setEditedPickUpPersonId(null)
    }
    setPickupPersonModal(prevState => !prevState)
  }

  const isEditModal = () => {
    return !!editedPickupPersonId
  }

  const handleCloseCancelModal = () => {
    setRemovedPickUpPersonId(null)
    setIsCancelModalOpen(false)
  }

  const addOrEditPickupPerson = (values) => {
    if (isEditModal()) {
      return editPickupPerson(values)
    }
    return addPickupPerson(values)
  }

  const handleEditPickupPersonClick = (pickupPersonId) => {
    setPickupPersonModal(true)
    setEditedPickUpPersonId(pickupPersonId)
  }

  const handleRemovePickupPersonClick = (pickupPersonId) => {
    setIsCancelModalOpen(true)
    setRemovedPickUpPersonId(pickupPersonId)
  }

  const handleRemovePickupPerson = () => {
    setRemovedPickUpPersonId(null)
    setIsCancelModalOpen(false)
    removePickupPerson(reservationInfo, setError, setReservationInfo, removedPickupPersonId)
  }

  const getPopUpMainText = () => {
    const pickupContacts = reservationInfo.reservationShipment.reservationDetail.pickupContacts
    const removedPerson = pickupContacts.find(contact => contact.id === removedPickupPersonId)

    return `Are you sure you want to remove ${removedPerson.name} from this reservation?`
  }

  const getEditedPersonInfo = () => {
    if (!isEditModal()) return

    const pickupContacts = reservationInfo.reservationShipment.reservationDetail.pickupContacts
    const editedPerson = pickupContacts.find(contact => contact.id === editedPickupPersonId)
    const formattedPhone = FormValidations.formatPhoneNumber(editedPerson.phone)
    const formValues = {
      firstName: editedPerson.firstName,
      lastName: editedPerson.lastName,
      email: editedPerson.email,
      streetAddress1: editedPerson.address,
      streetAddress2: '',
      city: editedPerson.city,
      state: editedPerson.state,
      zip: editedPerson.zip,
      phone: formattedPhone
    }
    return formValues
  }

  const addPickupPerson = (info) => {
    const reservationDetailId = { reservationDetailId: reservationInfo.reservationShipment.reservationDetail.id }
    const params = { ...info, ...reservationDetailId }

    ReservationsApi.addPickupPersonToReservation(params)
      .then(res => res.json())
      .then(json => {
        if (json.error_message) {
          ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document.querySelector('div'))
        } else {
          const updatedReservationShipment = Object.assign({}, reservationInfo)
          updatedReservationShipment.reservationShipment.reservationDetail.pickupContacts.push(camelize(json.pickup_contact))
          setReservationInfo(updatedReservationShipment)
        }
      })
      .catch(() => {
        ErrorHelper.addError({ bannerMessage: 'There was an error creating alternate assignment' }, setError, document.querySelector('div'))
      })
  }

  const editPickupPerson = (values) => {
    const params = { ...values, id: editedPickupPersonId, reservationDetailId: reservationInfo.reservationShipment.reservationDetail.id }
    ReservationsApi.editPickupPersonFromReservation(params)
      .then(res => res.json())
      .then(json => {
        if (json.error_message) {
          ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document.querySelector('div'))
        } else {
          const updatedReservationShipment = Object.assign({}, reservationInfo)
          const updatedContacts = updatedReservationShipment.reservationShipment.reservationDetail.pickupContacts.filter(
            person => person.id !== editedPickupPersonId
          )
          updatedContacts.push(camelize(json.pickup_contact))
          updatedReservationShipment.reservationShipment.reservationDetail.pickupContacts = updatedContacts
          setReservationInfo(updatedReservationShipment)
        }
      })
      .catch(() => {
        ErrorHelper.addError({ bannerMessage: 'There was an error editing alternate assignment' }, setError, document.querySelector('div'))
      })
  }

  return (
    <div className='md:col-span-4 bg-[#FBFAFC] border-t-2 border-gray-200 outline-none'>
      <div className='px-8 py-6'>
        <h3 className='text-md font-medium leading-6 text-gray-900'>
          Alternate Assignments
        </h3>
        {isVisible &&
          <>
            {error && <ErrorPopup error={error} />}
            {reservationInfo &&
              <PickupPersonList
                pickupContacts={reservationInfo.reservationShipment.reservationDetail.pickupContacts}
                handleRemovePickupPerson={handleRemovePickupPersonClick}
                handleEditPickupPerson={handleEditPickupPersonClick}
              />
            }
            {pickupPersonModal &&
              <AddOrEditPickupPersonModal
                isEdit={isEditModal()}
                addOrEditPickupPerson={addOrEditPickupPerson}
                closeModal={closeModal}
                currentFormValues={getEditedPersonInfo()}
              />
            }
            <button
              type='button'
              className='text-zinc-800 border border-solid border-gray-200 inline-flex justify-center py-2 px-4 text-sm font-medium text-white shadow-sm rounded w-max h-10'
              onClick={closeModal}
            >
              + Add alternate assignment
            </button>
          </>
        }
        {
          isCancelModalOpen &&
          <PopUpModal
            closeModal={handleCloseCancelModal}
            handleAccept={handleRemovePickupPerson}
            header="Remove Alternate Assignment"
            mainText={getPopUpMainText()}
          />
        }
      </div>
    </div>
  )
}

PickupPersonContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  reservationInfo: PropTypes.object.isRequired,
  setReservationInfo: PropTypes.func.isRequired
}

export default PickupPersonContainer
