import React from 'react'
import LoadingSpinner from '../shared/LoadingSpinner'
import PropTypes from 'prop-types'
import CancelReservationCreation from './CancelReservationCreation'

const ConfirmationAgreementSection = ({ transactionalCommunications, setTransactionalCommunications, marketingCommunications, setMarketingCommunications, tosToggle, confirmAndContinue, loadingSubmit, reservationShipment }) => {
  // TODO: remove this code if we decide permanently opening in a new tab is ok
  // const renderTosLink = () => {
  //   return (
  //     <span data-testid='tos-link' onClick={tosToggle} className="hover:underline text-blue-600 cursor-pointer tos-toggle">Terms and Conditions</span>
  //   )
  // }

  return (
    <div className="py-4 px-8">
      <h2 className="text-xl pb-4 font-semibold">Communication preferences</h2>
      <div className='flex text-gray-600'>
        <input
          type='checkbox'
          value={transactionalCommunications}
          onClick={() => setTransactionalCommunications(prev => !prev)}
          className="h-12 w-12 scale-150 mt-1 mr-2 p-2 accent-[#6558F5]"
          data-testid='transactional-communications-checkbox'
        />
        <p className='pl-2 text-sm'>
          {
            'Checking this box is my signature to consent to receive recurring automated promotional and personalized marketing text messages \
            (e.g. offers and cart reminders) from Zales x Rocksbox Fine jewelry Rental at this cell phone number. \
            Consent is not a condition of any purchase. Reply STOP to cancel. \
            Opting out of this text program will not opt you out of other text programs from Zales x Rocksbox. \
            Msg frequency varies. Msg & data rates may apply. \
            By enrolling you accept our '
          }
          <a className="hover:underline text-blue-600 cursor-pointer" href={'https://www.zales.com/terms-and-conditions'}>Terms of Use</a>
          { ' & ' }
          <a className="hover:underline text-blue-600 cursor-pointer" href={'https://www.zales.com/privacy-policy'}>Privacy Policy.</a>
        </p>
      </div>

      <div className='mt-4 flex text-gray-600'>
        <input
          type='checkbox'
          value={marketingCommunications}
          onClick={() => setMarketingCommunications(prev => !prev)}
          className="h-12 w-12 scale-150 mt-1 mr-2 p-2 accent-[#6558F5]"
          data-testid='marketing-communications-checkbox'
        />
        <p className='pl-2 text-sm'>
          {
            'Checking this box is my signature to consent to receive automated promotional and personalized \
            marketing text messages (e.g. offers and cart reminders) from Zales x Rocksbox Fine Jewelry Rental at this cell phone number. \
            Consent is not a condition of purchase. Reply STOP to cancel. \
            Opting out of this text program will not opt you out of other text programs from Zales x Rocksbox. \
            Msg frequency varies. Msg & data rates may apply. By enrolling you accept our '
          }
          <a className="hover:underline text-blue-600 cursor-pointer" href={'https://www.zales.com/terms-and-conditions'}>Terms of Use</a>
          { ' & ' }
          <a className="hover:underline text-blue-600 cursor-pointer" href={'https://www.zales.com/privacy-policy'}>Privacy Policy.</a>
        </p>
      </div>

      <div className='mt-8 text-gray-600 text-sm'>
        { 'By clicking Agree and Continue, you agree to the Zales x Rocksbox Fine Jewelry Rental '}
        <a className="hover:underline text-blue-600 cursor-pointer" href="https://rocksbox.s3.amazonaws.com/Event+Rental/Jewelry_Rental_Agreement_6.27.23.pdf" rel="noreferrer" target = "_blank">Terms and Conditions</a>
        { ', our ' }
        <a className="hover:underline text-blue-600 cursor-pointer" href={'https://www.zales.com/privacy-policy'}>Privacy Policy</a>
        { ', and our ' }
        <a className="hover:underline text-blue-600 cursor-pointer" href={'https://www.zales.com/terms-and-conditions'}>Terms of Use.</a>
      </div>

      <div className='col-span-2 outline-none mt-8 flex flex-row space-x-8'>
        <CancelReservationCreation reservationShipment={reservationShipment} />
        <button
          className='flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-neutral-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none h-10 confirm-agreement'
          id='agree-and-continue-button'
          onClick={confirmAndContinue}
          disabled={loadingSubmit}
        >
          {
            loadingSubmit ? <LoadingSpinner/> : 'Agree and Continue →'
          }
        </button>
      </div>
    </div>
  )
}

export default ConfirmationAgreementSection

ConfirmationAgreementSection.propTypes = {
  transactionalCommunications: PropTypes.bool.isRequired,
  setTransactionalCommunications: PropTypes.func.isRequired,
  marketingCommunications: PropTypes.bool.isRequired,
  setMarketingCommunications: PropTypes.func.isRequired,
  tosToggle: PropTypes.func.isRequired,
  confirmAndContinue: PropTypes.func.isRequired,
  loadingSubmit: PropTypes.bool.isRequired,
  reservationShipment: PropTypes.object.isRequired
}
