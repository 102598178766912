import React from 'react'
import SortableTable from '../shared/table/SortableTable'
import PropTypes from 'prop-types'

const ProductsTable = ({ products }) => {
  const columns = [
    { label: 'ID', accessor: 'id', sortable: true },
    { label: 'Status', accessor: 'statusName', sortable: true },
    { label: 'Reason', accessor: 'reasonName', sortable: true },
    { label: 'Details', accessor: 'detailsName', sortable: true },
    { label: 'Last Updated', accessor: 'updatedAt', sortable: true },
    { label: 'Available', accessor: 'isAvailable', sortable: true },
    { label: 'Inspection', accessor: 'flagged', sortable: true }
  ]

  return (
    <div>
      <SortableTable columns={columns} data={products} />
    </div>
  )
}

ProductsTable.propTypes = {
  products: PropTypes.array.isRequired
}

export default ProductsTable
