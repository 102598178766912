import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import FormatHelper from '../../helpers/format-helper'

const ContactsTable = ({ contacts, handleOrderSort = null, orderSort = null }) => {
  const renderSortButton = (sortingColumnName) => {
    if (handleOrderSort && orderSort) {
      let buttonContent

      if (orderSort.order === 'desc' && orderSort.sort === sortingColumnName) {
        buttonContent = <p className="text-indigo-600">↓</p>
      } else if (orderSort.order === 'asc' && orderSort.sort === sortingColumnName) {
        buttonContent = <p className="text-indigo-600">↑</p>
      } else {
        buttonContent = <p>⇅</p>
      }

      return (
        <button type="button" onClick={() => handleOrderSort(sortingColumnName)}>{buttonContent}</button>
      )
    }
  }

  return (
    <>
      {contacts.length > 0
        ? <table className="table-auto w-full mt-4 text-xs lg:text-base">
          <thead>
          <tr className="text-left">
            <th className="border p-2">Customer {renderSortButton('last_name')}</th>
            <th className="border p-2">Email address {renderSortButton('email')}</th>
            <th className="border p-2">Phone number {renderSortButton('phone')}</th>
            <th className="border p-2">Street Address {renderSortButton('street_address_1')}</th>
            <th className="border p-2">City {renderSortButton('city')}</th>
            <th className="border p-2">State {renderSortButton('state')}</th>
            <th className="border p-2">Zip {renderSortButton('zip')}</th>
          </tr>
          </thead>
          <tbody>
          {contacts.map((contact) => (
            <tr className="text-left sm:text-[10px] md:text-[10px] lg:text-base" key={contact.id}>
              <td className="border p-2 text-indigo-600 whitespace-nowrap">
                <Link to={`/contacts/${contact.id}`}>{contact.name}</Link>
              </td>
              <td className="border p-2">{FormatHelper.truncate(contact.email)}</td>
              <td className="border p-2">{contact.phone}</td>
              <td className="border p-2">{contact.address}</td>
              <td className="border p-2">{contact.city}</td>
              <td className="border p-2">{contact.state}</td>
              <td className="border p-2">{contact.zip}</td>
            </tr>
          ))}
          </tbody>
        </table>
        : <h2 className="flex justify-center font-semibold text-lg mt-4">Nothing to see here.</h2>
      }
    </>
  )
}

ContactsTable.propTypes = {
  contacts: PropTypes.array.isRequired,
  handleOrderSort: PropTypes.func,
  orderSort: PropTypes.object
}

export default ContactsTable
