import ApiHelper from '../helpers/api-helper'
import Cookies from 'js-cookie'
class ContactsApi {
  // TODO: unstub api calls
  static fetchIndex (order, sort, searchParams) {
    console.log('fetching contacts', order, sort, searchParams)

    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) searchParams += `[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts?order=${order}&sort=${sort}&${searchParams}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static fetchContact (id) {
    console.log('fetching contact', id)
    let searchParams = `${id}`
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) searchParams += `/?[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts/${searchParams}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static updateContact (params) {
    console.log('updating contact', params)
    const contact = params.contact
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts/${params.id}`, {
      method: 'PATCH',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        recurly_update_token: params.recurlyToken,
        event_rental_store_id: eventRentalStoreIdCookie ?? '',
        contact: {
          first_name: contact.firstName,
          last_name: contact.lastName,
          email: contact.email,
          street_address_1: contact.streetAddress1,
          street_address_2: contact.streetAddress2,
          city: contact.city,
          state: contact.state,
          zip: contact.zip,
          phone: contact.phone
        }
      })
    })
  }
}

export default ContactsApi
