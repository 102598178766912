import React from 'react'
import MoneyHelper from '../../helpers/money-helper'
import ReservationShipmentProducts from '../shared/ReservationShipmentProducts'
import PropTypes from 'prop-types'
import ReservationDatesRow from '../shared/ReservationDatesRow'
import PromoCodeField from '../reservations/create_reservation_shipments/PromoCodeField'
import ReservationsHelper from '../../helpers/reservations-helper'

const PurchaseProductsDetailsColumn = ({ promotion, reservationInfo, setPromotion }) => {
  const {
    purchasedReservationsBySkus,
    reservationShipment
  } = reservationInfo

  const reservationsBySkus =
    [
      ...(purchasedReservationsBySkus || [])
    ]

  const { contact: { id: contactId }, purchaseProductsTaxInfo } = reservationShipment

  return (
    <div>
      <div className="border-b py-4 px-8">
        <h2 className="text-xl pb-4 font-bold">Order Details</h2>
        <ReservationDatesRow reservationShipment={reservationShipment}/>
      </div>

      <ReservationShipmentProducts
        reservationShipment={reservationShipment}
        reservationsBySkus={reservationsBySkus}
      />

      <div className="border-b py-4 px-8 flex relative hidden">
        <input placeholder="Insert Promo Code" className="bg-gray-100 w-full p-2 rounded border"/>
        <button className="absolute right-10 top-6 text-indigo-600">Apply</button>
      </div>

      <div className="py-4 px-8 grid grid-cols-2 border-b">
        <div className="text-gray-500">
          Subtotal
        </div>
        <div className="justify-self-end">
          {MoneyHelper.centsToDollars(reservationShipment.subtotalPurchasePriceBeforeCredit)}
        </div>
        <div className="text-gray-500">
          Tax ({MoneyHelper.formatTaxRate(purchaseProductsTaxInfo.taxRate * 100)}%)
        </div>
        <div className="justify-self-end text-gray-500" data-testid='total-due-at-pickup-amount'>
          {MoneyHelper.centsToDollars(purchaseProductsTaxInfo.taxInCents)}
        </div>
        <div className="text-gray-500">Rental fee credit</div>
        <div className="justify-self-end">
          - {MoneyHelper.centsToDollars(reservationShipment.totalRentalFeeCreditForPurchase)}
        </div>
        {promotion &&
          <>
            <div className="text-gray-500" data-testid='promotion'>
              {promotion.name}
            </div>
            <div className="justify-self-end" data-testid='promotion-amount'>
              - {MoneyHelper.centsToDollars(ReservationsHelper.getPromotionDiscount(promotion, purchaseProductsTaxInfo.totalBeforeTax))}
            </div>
          </>
        }
      </div>

      <div className="px-8 grid grid-cols-2">
        <div className="font-bold py-4">
          Total
        </div>
        <div className="justify-self-end pt-4 font-bold">
          {MoneyHelper.centsToDollars(purchaseProductsTaxInfo.totalAfterTax - ReservationsHelper.getPromotionDiscount(promotion, purchaseProductsTaxInfo.totalBeforeTax))}
        </div>
      </div>
      <PromoCodeField contactId={contactId} rentalStep='purchase' setPromotion={setPromotion} />
    </div>
  )
}
PurchaseProductsDetailsColumn.defaultProps = {
  promotion: null
}
PurchaseProductsDetailsColumn.propTypes = {
  promotion: PropTypes.object,
  reservationInfo: PropTypes.object.isRequired,
  setPromotion: PropTypes.func.isRequired
}

export default PurchaseProductsDetailsColumn
