import { useQuery } from '@tanstack/react-query'
import ReservationsApi from './ReservationsApi'
import camelize from 'camelize'

export const fetchReservations = async (params) => {
  const data = await ReservationsApi.fetchIndex(params)
  return data
}

export const useGetReservations = (params) => {
  return useQuery({
    queryKey: ['reservations'],
    queryFn: () => fetchReservations(params).then(response => response.json()),
    select: (data) => camelize(data)
  })
}
