import React from 'react'
import ReportLostItem from './ReportLostItem'
import PropTypes from 'prop-types'

const ReportLostList = ({ items, updateLostReason, handleConfirm, isSubmitted, isValid }) => {
  return (
    <div>
      <p className="mt-8 font-bold">Select correct status for each lost item.</p>
      <div className="mt-4 flex flex-col gap-2 align-center justify-center">
        {items.map(item => <ReportLostItem
            key={item.id}
            item={item}
            updateLostReason={updateLostReason}
            lostReason={item.lostReason}
          />)}
      </div>
      {isSubmitted && !isValid && <div className="mt-4 text-red-600">Inputs are invalid. Select correct status for any lost items.</div>}
      <div className="mt-4 mx-auto flex justify-center gap-2">
        <button
          className="block bg-indigo-500 rounded text-white px-4 py-2 hover:bg-indigo-700 focus:bg-indigo-600 duration-150"
          onClick={ handleConfirm }
        >
          Confirm
        </button>
        <a
          className="block bg-white rounded px-4 py-2 text-indigo-500 hover:bg-indigo-200 focus:bg-indigo-300 duration-150 border border-solid border-indigo-500"
          href="/mpc"
        >Cancel</a>
      </div>
    </div>
  )
}

ReportLostList.propTypes = {
  items: PropTypes.array.isRequired,
  updateLostReason: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired
}

export default ReportLostList
