import React from 'react'
import PropTypes from 'prop-types'
const ProductDescriptorHeader = ({ productDescriptor, tab, setTab }) => {
  return (
    <div className="border-b-2">
      <div className="flex items-center">
        <img className="w-48" src={productDescriptor.imageUrl} alt="SKU Image" />
        <div>
          <h1 className="text-3xl font-bold pb-4">{productDescriptor.name}</h1>
          <h2 className="text-[#84818A]">{productDescriptor.sku} SKU: {productDescriptor.id}</h2>
        </div>
      </div>
      <ul className="flex flex-row mt-10">
        <li className={`mr-10 pb-5 ${tab === 'details' ? 'border-b-4 border-[#5542F6]' : ''}`} onClick={() => { setTab('details') }}><a href="#details">Details</a></li>
        <li className={`${tab === 'items' ? 'border-b-4 border-[#5542F6]' : ''}`} onClick={() => { setTab('items') }}><a href="#items">Items</a></li>
      </ul>
    </div>
  )
}

ProductDescriptorHeader.propTypes = {
  productDescriptor: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired
}

export default ProductDescriptorHeader
