import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import camelize from 'camelize'
import ProcessShipmentApi from '../../api/ProcessShipmentApi'
import ContactInfoColumn from '../shared/ContactInfoColumn'
import PurchaseProductsDetailColumn from './PurchaseProductsDetailColumn'
import PurchaseProductsButtons from './PurchaseProductsButtons'
import { ProcessShipmentContext } from '../../contexts/ProcessShipmentContext'
import { PURCHASE_STATUSES } from '../../constants'

const PurchaseProducts = () => {
  const reservationShipmentId = parseInt(useParams().id)
  const [reservationInfo, setReservationInfo] = useState(null)
  const [promotion, setPromotion] = useState(null)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const { errorHandler } = useOutletContext()
  const { productStatuses, pickupOrReturnPerson } = useContext(ProcessShipmentContext)

  const navigate = useNavigate()

  useEffect(() => {
    const productsToPurchase = getProductsToPurchase()

    ProcessShipmentApi.fetchPurchaseInfo(reservationShipmentId, productsToPurchase)
      .then(resp => resp.json())
      .then(json => {
        const response = camelize(json)
        const { errorMessage } = response

        if (response.reservationShipment) {
          setReservationInfo(response)
        } else {
          errorHandler({ bannerMessage: errorMessage || 'Something went wrong fetching reservation information' })
        }
      })
      .catch(() => {
        errorHandler({ bannerMessage: 'Something went wrong fetching reservation information' })
      })
  }, [])

  const getProductsToPurchase = () => {
    const purchaseProductIds =
      productStatuses.filter(productStatus => productStatus.desiredStatus === PURCHASE_STATUSES.purchased)
        .map(productStatus => productStatus.productId)
    const lostProductIds =
      productStatuses.filter(productStatus => productStatus.desiredStatus === PURCHASE_STATUSES.lost)
        .map(productStatus => productStatus.productId)
    const damagedProductIds =
      productStatuses.filter(productStatus => productStatus.desiredStatus === PURCHASE_STATUSES.damagedIrreparable)
        .map(productStatus => productStatus.productId)
    const fraudProductIds =
      productStatuses.filter(productStatus => productStatus.desiredStatus === PURCHASE_STATUSES.fraud)
        .map(productStatus => productStatus.productId)
    const stolenProductIds =
      productStatuses.filter(productStatus => productStatus.desiredStatus === PURCHASE_STATUSES.stolen)
        .map(productStatus => productStatus.productId)
    const purchasedPastDueProductIds =
      productStatuses.filter(productStatus => productStatus.desiredStatus === PURCHASE_STATUSES.purchasedPastDue)
        .map(productStatus => productStatus.productId)

    return {
      purchaseProductIds,
      lostProductIds,
      damagedProductIds,
      fraudProductIds,
      stolenProductIds,
      purchasedPastDueProductIds
    }
  }

  const handlePurchase = () => {
    setLoadingSubmit(true)
    const promoCode = promotion?.code
    ProcessShipmentApi.postPurchaseProducts(reservationShipmentId, productStatuses, promoCode, pickupOrReturnPerson)
      .then(res => res.json())
      .then(json => {
        const response = camelize(json)
        const { errorMessage } = response

        if (errorMessage) {
          errorHandler({ bannerMessage: errorMessage })
          setLoadingSubmit(false)
        } else {
          navigate(`/reservations/${reservationShipmentId}`)
        }
      })
      .catch(() => {
        setLoadingSubmit(false)
        errorHandler({ bannerMessage: 'Something went wrong, please try again' })
      })
  }

  return (
    <div className="relative -mx-1 px-4 -mt-16 z-10 bg-white h-[80vh]">
      <h2 className='mt-8 mb-16 text-3xl -mx-12'>Checkout</h2>
      {reservationInfo &&
        <div className='grid grid-cols-3 border-y -mx-20'>
          <div className='col-span-2 border-r relative'>
            <PurchaseProductsDetailColumn promotion={promotion} reservationInfo={reservationInfo} setPromotion={setPromotion} />
            <PurchaseProductsButtons handlePurchase={handlePurchase} loadingSubmit={loadingSubmit}/>
          </div>
          <div className='col-span-1'>
            <ContactInfoColumn
              contact={reservationInfo.reservationShipment.contact}
              pickupContacts={reservationInfo.reservationShipment.reservationDetail.pickupContacts}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default PurchaseProducts
