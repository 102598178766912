import React from 'react'
import { Link } from 'react-router-dom'
import { isReceiving } from '../../helpers/process-shipments'

const CancelLink = () => {
  return (
    <Link
      className="bg-white rounded p-2 outline outline-indigo-500 text-indigo-500"
      to={(isReceiving()) ? '/inbound_shipments' : '/outbound_shipments'}
    >
      Cancel
    </Link>
  )
}

export default CancelLink
