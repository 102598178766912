import { React, useState, useEffect } from 'react'
import ReservationsFilter from './ReservationsFilter'
import ReservationsTable from '../shared/ReservationsTable'
import { useGetReservations } from '../../api/useQueryHelper'

const Reservations = () => {
  const [sort, setSort] = useState('desc')
  const [sortedField, setSortField] = useState('id')
  const [appliedStatusFilters, setAppliedStatusFilters] = useState({})

  // maps key strings of filterSelections that have a truthy value
  const getFilteredSelections = () => {
    return Object.keys(appliedStatusFilters).filter(key =>
      appliedStatusFilters[[key]] === true
    )
  }

  const reservationParams = {
    reservation_statuses_filter: getFilteredSelections(),
    order: sort,
    sort: sortedField
  }
  const { data, isLoading, isError, isRefetching, refetch } = useGetReservations(reservationParams)

  useEffect(() => {
    refetch()
  }, [appliedStatusFilters, sort, sortedField])

  const handleSort = (field) => {
    setSortField(field)
    setSort(prevSort => prevSort === 'desc' ? 'asc' : 'desc')
  }

  const handleClick = (e) => {
    e.preventDefault()
    alert('Reservations can no longer be created')
  }

  const renderProgressBar = () => {
    return (
      <div>
        {isLoading && <div>Loading...</div> }
        <div className="h-1 relative w-full rounded-full overflow-hidden animate-pulse ">
          <div className="w-full h-full bg-indigo-300 absolute"></div>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return renderProgressBar()
  }

  if (isError) {
    return <p>There was an error. Please refresh the page.</p>
  }

  return (
    <div>
      <div className="flex flex-row justify-center gap-4 border-b-2 bg-white pb-6 sticky top-[70px] w-full z-10 pt-1">
        <ReservationsFilter
          appliedFilters={appliedStatusFilters}
          reservationStatuses={data?.statuses}
          setAppliedStatusFilters={setAppliedStatusFilters}
        />
        <button className="bg-indigo-500 rounded text-white p-2 w-max" onClick={handleClick}>Create new reservation</button>
      </div>
        {isRefetching && renderProgressBar()}
      <h1 className="text-indigo-600 py-4 text-xl font-bold">ALL RESERVATIONS</h1>
      <ReservationsTable reservations={data?.reservationShipments} sorting={true} handleSort={handleSort} sort={sort} sortedField={sortedField} />
    </div>
  )
}

export default Reservations
