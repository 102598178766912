import React from 'react'
import ReservationShipmentDetails from '../ReservationShipmentDetails'
import PropTypes from 'prop-types'
import ReservationShipmentProducts from '../../shared/ReservationShipmentProducts'

const ReservationDetails = ({ reservationInfo }) => {
  return (
    <div className='grid grid-cols-3 border-y -mx-20'>
      <div className='col-span-2  border-r'>
        <div className='border-b py-4 px-8'>
          <h2 className='text-xl pb-4 font-bold'>Items held</h2>
        </div>
        <ReservationShipmentProducts
          reservationShipment={reservationInfo.reservationShipment}
          reservationsBySkus={reservationInfo.reservationsBySkus}
        />
      </div>
      <div className='col-span-1'>
        <ReservationShipmentDetails
          pickupContacts={[reservationInfo.reservationShipment.contact].concat(reservationInfo.reservationShipment.reservationDetail.pickupContacts)}
          reservationShipment={reservationInfo.reservationShipment}
        />
      </div>
    </div>
  )
}

export default ReservationDetails

ReservationDetails.propTypes = {
  reservationInfo: PropTypes.object.isRequired,
  additionalFields: PropTypes.object
}
