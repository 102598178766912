import React from 'react'
import CheckInRecoveredItemReadOnly from './CheckInRecoveredItemReadOnly'
import PropTypes from 'prop-types'

const CheckInRecoveredListConfirmation = ({ items }) => {
  return (
    <>
      <p className="mt-8 font-bold">Recovered Items</p>
      <div className="mt-4 flex flex-col gap-2 align-center justify-center">
        {items.filter((item) => item.recovered).map(item => <CheckInRecoveredItemReadOnly key={item.id} item={item} lostReason={item.lostReason}/>)}
      </div>
    </>
  )
}

CheckInRecoveredListConfirmation.propTypes = {
  items: PropTypes.array.isRequired
}

export default CheckInRecoveredListConfirmation
