import camelize from 'camelize'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ContactsApi from '../../api/ContactsApi'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from '../shared/ErrorPopup'
import ContactDetails from './ContactDetails'
import ReservationHistoryTable from './ReservationHistoryTable'

const ShowContact = () => {
  const params = useParams()
  const [contact, setContact] = useState(null)
  const [reservations, setReservations] = useState([])
  const [editBilling, setEditBilling] = useState(false)
  const [updateBillingIsLoading, setUpdateBillingIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const addError = (error) => {
    ErrorHelper.addError(error, setError, document)
    setUpdateBillingIsLoading(false)
  }

  useEffect(() => {
    if (!contact) {
      const id = params.id
      ContactsApi.fetchContact(id)
        .then(res => res.json())
        .then(json => {
          setContact(camelize(json.contact))
          setReservations(camelize(json.reservation_shipments))
        })
    }
  }, [])

  const handleBillingUpdate = (token) => {
    const paramInfo = {
      id: contact.id,
      recurlyToken: token,
      contact
    }
    ContactsApi.updateContact(paramInfo)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          setContact(camelize(json.contact))
          setEditBilling(false)
          setUpdateBillingIsLoading(false)
        } else {
          addError({ bannerMessage: json.error_message })
        }
      })
      .catch(() => {
        addError({ bannerMessage: 'Something went wrong' })
      })
  }

  return (
    <div className='w-screen h-screen absolute left-0 top-0 z-10 bg-white'>
      <div className="px-4 pt-12">
        <Link to="/contacts">
          <span className='text-gray-400'>Customers</span>
        </Link>
        <span> / Customer Details</span>
      </div>
      {error && <ErrorPopup error={error}/>}
        {contact &&
          <div>
            <ContactDetails
              contact={contact}
              editBilling={editBilling}
              setEditBilling={setEditBilling}
              setUpdateBillingIsLoading={setUpdateBillingIsLoading}
              setContact={setContact}
              handleBillingUpdate={handleBillingUpdate}
              updateBillingIsLoading={updateBillingIsLoading}
            />
            <div className="bg-[#FBFAFC] py-2 px-4">Reservation History</div>
            { reservations.length > 0 ? <ReservationHistoryTable reservations={reservations}/> : 'No reservations made yet' }
          </div>
        }
    </div>
  )
}

export default ShowContact
