import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import camelize from 'camelize'

import ReservationCustomerHeader from '../common/ReservationCustomerHeader'
import SingleProduct from '../common/SingleProduct'
import ProcessShipmentApi from '../../api/ProcessShipmentApi'
import { isReceiving } from '../../helpers/process-shipments'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from '../shared/ErrorPopup'

const ProcessShipment = () => {
  const reservationShipmentId = useParams().id

  const [reservationShipment, setReservationShipment] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [error, setError] = useState({})
  const [presentReservations, setPresentReservations] = useState([])

  const handleResponse = (response) => {
    const { errorMessage } = response

    if (errorMessage) {
      // Error currently will never display if no reservation shipment
      // but in the future there should be a page to navigate to for the
      // return and receive flow if error
      ErrorHelper.addError(
        { bannerMessage: errorMessage },
        setError,
        document.querySelector('.container')
      )
    } else {
      const {
        missingStatuses,
        presentStatuses,
        reservationShipment
      } = response

      setReservationShipment(reservationShipment)
      setPresentReservations(reservationShipment.presentReservations)
      setStatuses({ ...missingStatuses, ...presentStatuses })
    }
  }

  useEffect(() => {
    if (isReceiving()) {
      ProcessShipmentApi.fetchReceiveReservationShipment(reservationShipmentId)
        .then(resp => resp.json())
        .then(json => {
          handleResponse(camelize(json))
        })
    } else {
      ProcessShipmentApi.fetchReturnReservationShipment(reservationShipmentId)
        .then(resp => resp.json())
        .then(json => {
          handleResponse(camelize(json))
        })
    }
  }, [])

  if (!reservationShipment) return null

  const { contact, reservations } = reservationShipment

  const firstReservation = presentReservations[0]

  return (
    <div className="container mx-auto">
      {error && <ErrorPopup error={error}/>}
      <ReservationCustomerHeader
        contact={contact}
        reservationShipmentId={reservationShipment.id}
        qcStep={reservationShipment.statusName}
      />
      <div className="grid grid-cols-3 gap-4 text-center">
        {presentReservations.map((reservation) => {
          const { product } = reservation

          return (
            <div
              key={`product-${product.id}}`}
              className="grid auto-rows-auto"
            >
              <SingleProduct
                reservation={reservation}
                statuses={statuses}
              />
            </div>
          )
        })}
      </div>
      <div className="flex flex-row justify-center mt-8 gap-6 pb-6">
        <Link
          className="bg-white rounded p-2 outline outline-indigo-500 text-indigo-500"
          to={(isReceiving()) ? '/inbound_shipments' : '/outbound_shipments'}
        >
          Cancel
        </Link>
        <Link
          className="bg-indigo-500 rounded text-white p-2"
          to={isReceiving()
            ? `/inbound_shipments/${reservationShipment.id}/${firstReservation.id}`
            : `/outbound_shipments/${reservationShipment.id}/${firstReservation.id}`
          }
          state={{
            reservation: reservations.find(res => res.id === firstReservation.id),
            reservationShipment
          }}
        >
          {'Start QC'}
        </Link>
      </div>
    </div>
  )
}

export default ProcessShipment
