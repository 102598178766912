import Actions from './Actions'
import camelize from 'camelize'
import InventoryApi from '../../api/InventoryApi'
import InventoryMetrics from './InventoryMetrics'
import React, { useEffect, useState } from 'react'
import SelectedSkus from './SelectedSkus'
import SkuThumbnail from './SkuThumbnail'
import { useNavigate, useOutletContext } from 'react-router-dom'
import ApiHelper from '../../helpers/api-helper'

const Inventory = (props) => {
  const [selectedSkus, setSelectedSkus] = useState([])
  const [skus, setSkus] = useState([])
  const [kinds, setKinds] = useState({})
  const [metals, setMetals] = useState({})
  const [designers, setDesigners] = useState({})
  const [inTransitToStoreCount, setInTransitToStoreCount] = useState(0)
  const [inStoreCount, setInStoreCount] = useState(0)
  const [withCustomersCount, setWithCustomersCount] = useState(0)
  const [colors, setColors] = useState(null)
  const [clarities, setClarities] = useState(null)
  const [checkedStatuses, setCheckedStatuses] = useState({})
  const navigate = useNavigate()
  const { errorHandler } = useOutletContext()

  useEffect(() => {
    queryInventory()
  }, [])

  const queryInventory = (params = {}) => {
    setCheckedStatuses(params)
    InventoryApi.fetchIndex(params)
      .then(res => res.json())
      .then(json => {
        const {
          designers,
          diamondColors,
          diamondClarities,
          inStoreCount,
          inTransitToStoreCount,
          kinds,
          metals,
          skus,
          withCustomersCount
        } = camelize(json)

        if (skus) {
          setSkus(skus)
          setDesigners(designers)
          setMetals(metals)
          setKinds(kinds)
          setInTransitToStoreCount(inTransitToStoreCount)
          setInStoreCount(inStoreCount)
          setWithCustomersCount(withCustomersCount)
          setColors(diamondColors)
          setClarities(diamondClarities)
        } else {
          errorHandler({ bannerMessage: 'Error fetching Inventory' })
        }
      })
      .catch(() => {
        errorHandler({ bannerMessage: 'Error fetching Inventory' })
      })
  }

  const selectSku = (skuValue) => {
    const selectedSku = skus.find(sku => sku.sku === skuValue)

    setSelectedSkus([...selectedSkus, selectedSku])
  }

  const handleSearchSubmit = (query) => {
    console.log('Searching for SKU: ' + query)

    queryInventory({ sku: query })
  }

  const handleCheckAvailability = (event) => {
    event.preventDefault()

    const quantities = new Array(selectedSkus.length).fill(1)
    const selectedSkuValues = selectedSkus.map(selectedSku => selectedSku.sku)

    InventoryApi.fetchAvailableSkus(selectedSkuValues, quantities)
      .then(res => res.json())
      .then(json => {
        json = camelize(json)
        const notEnough = json.info.some(el => el.enoughForRequest === false)

        if (json.error_message || notEnough) {
          errorHandler({ bannerMessage: 'One or more pieces are not available' })
        } else {
          const query = ApiHelper.parameratizeArrayForRails('skus', selectedSkuValues)
          navigate(`/reservations/new?${query}`)
        }
      })
      .catch(() => {
        errorHandler({ bannerMessage: 'Error checking availability' })
      })
  }

  const unselectSku = (sku) => {
    const updatedSkus = selectedSkus.filter(currentSku => currentSku.sku !== sku)

    setSelectedSkus(updatedSkus)
  }

  return (
    <div>
      <div className='bg-white sticky top-[70px] w-full z-10 pt-1'>
        <InventoryMetrics
          inStoreCount={inStoreCount}
          inTransitToStoreCount={inTransitToStoreCount}
          withCustomersCount={withCustomersCount}
        />
        <Actions
          designers={designers}
          kinds={kinds}
          metals={metals}
          diamondColors={colors}
          diamondClarities={clarities}
          handleSearchSubmit={handleSearchSubmit}
          checkedStatuses={checkedStatuses}
          queryInventory={queryInventory}
        />
      </div>
      <div className="p-4">
        <SelectedSkus
          handleCheckAvailability={handleCheckAvailability}
          selectedSkus={selectedSkus}
          unselectSku={unselectSku}
        />
        <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:gird-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 place-items-center">
          {
            skus.map(sku =>
              <SkuThumbnail
                checked={selectedSkus.includes(sku)}
                displayQuantity
                key={`${sku.sku}`}
                selectSku={selectSku}
                skuObject={sku}
                unselectSku={unselectSku}
              />)
          }
        </div>
      </div>
    </div>
  )
}

export default Inventory
