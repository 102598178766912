import React, { useState } from 'react'
import PromotionApi from '../../../api/PromotionApi'
import PropTypes from 'prop-types'
import LoadingSpinner from '../../shared/LoadingSpinner'
import ErrorPopup from '../../shared/ErrorPopup'
import ErrorHelper from '../../../helpers/error-helper'

const PromoCodeField = ({ contactId, rentalStep, setPromotion }) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [promoCode, setPromoCode] = useState('')

  const handleChange = (event) => {
    setPromoCode(event.target.value)
  }

  const handleApplyPromo = () => {
    setLoading(true)
    PromotionApi.validatePromotion(contactId, promoCode, rentalStep)
      .then(res => res.json())
      .then(json => {
        if (json.valid) {
          setPromotion(json.promotion)
          setPromoCode('')
        } else {
          ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document.querySelector('div'))
        }
        setLoading(false)
      })
      .catch((error) => {
        ErrorHelper.addError({ bannerMessage: error }, setError, document.querySelector('div'))
      })
  }

  return (
    <div className='border-t'>
      {error && <ErrorPopup error={error}/>}
      <div className="flex py-4 px-8 ">
        <input
          className='w-[136px] h-[36px] px-4 mr-4 text-xs font-medium border-[0.5px] border-gray-400'
          data-test="promo-code-input"
          onChange={handleChange}
          placeholder='Promo code'
          type="text"
          value={promoCode}
        />
        <button
          className='w-[66px] h-[36px] ml-4 text-[#6C727F] text-xs font-medium border-[0.5px] border-gray-400'
          disabled={loading}
          onClick={handleApplyPromo}
          type="submit"
        >
          {loading ? <LoadingSpinner/> : 'Apply'}
        </button>
      </div>
    </div>
  )
}

PromoCodeField.defaultProps = {
  rentalStep: 'deposit'
}

PromoCodeField.propTypes = {
  contactId: PropTypes.number.isRequired,
  rentalStep: PropTypes.string,
  setPromotion: PropTypes.func.isRequired
}

export default PromoCodeField
