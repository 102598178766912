import React from 'react'
import PropTypes from 'prop-types'
import DatesHelper from '../../../helpers/dates-helper'

const ReservationItems = ({ reservations, reservationShipment }) => {
  return (
    <table className="table-auto w-full text-md">
      <thead className="bg-[#FBFAFC]">
        <tr className="text-left">
          <th className="border-y p-2">ID</th>
          <th className="border-y p-2">RSID</th>
          <th className="border-y p-2">Created</th>
          <th className="border-y p-2">SKU</th>
          <th className="border-y p-2">Name</th>
          <th className="border-y p-2">Status</th>
          <th className="border-y p-2">Reason</th>
          <th className="border-y p-2">Details</th>
          <th className="border-y p-2">Product</th>
        </tr>
      </thead>
      <tbody>
        {
          reservations.map((reservation, index) => (
            <tr className="text-left" key={`${reservation.id}-${index}`}>
              <td className="border-y p-2">{reservation.id}</td>
              <td className="border-y p-2">{reservationShipment.id}</td>
              <td className="border-y p-2">{DatesHelper.formatDateWithMonthAbbr(reservationShipment.created)}</td>
              <td className="border-y p-2">
                <div key={reservation.product.productDescriptor.sku}>
                  <a href={`/product_descriptors/${reservation.product.productDescriptor.id}`} className='text-indigo-600'>{reservation.product.productDescriptor.sku}</a>
                </div>
              </td>
              <td className="border-y p-2">{reservation.product.productDescriptor.name}</td>
              <td className="border-y p-2">{reservation.product.statusName}</td>
              <td className="border-y p-2">{reservation.product.reasonName}</td>
              <td className="border-y p-2">{reservation.product.detailsName}</td>
              <td className="border-y p-2">
                <div key={`${reservation.product.id}-${index}}`}>
                  <a href={`products/${reservation.product.id}`} className='text-indigo-600'>{reservation.product.id}</a>
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

ReservationItems.propTypes = {
  reservations: PropTypes.array.isRequired,
  reservationShipment: PropTypes.object.isRequired
}

export default ReservationItems
