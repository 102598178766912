import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ShippingLabel = () => {
  const location = useLocation()
  const search = location.search
  const params = new URLSearchParams(search)
  const labelUrl = params.get('label')

  useEffect(() => {
    window.print()
  }, [labelUrl])

  return (
    <div className='w-[50%] h-[50%]'>
      <img src={labelUrl}/>
    </div>
  )
}

export default ShippingLabel
