// to be used in combo with ErrorPopup, or whatever error component you want
// should manage display of error in your component, but you can use this for helper methods

class ErrorHelper {
  // takes an error object and callback to set component error
  // error will disappear when person clicks on container
  static addError (error, setErrorCallback, container = document) {
    setTimeout(() => {
      container.addEventListener('click', function () {
        setErrorCallback({})
      }, { once: true })
    }, 10)
    setErrorCallback(error)
  }
}

export default ErrorHelper
