import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isReceiving } from '../../helpers/process-shipments'
import PopUpModal from '../shared/PopUpModal'

const StartOverButton = ({ reservationShipmentId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  const startOver = () => {
    isReceiving()
      ? window.location.href = `/inbound_shipments/${reservationShipmentId}`
      : window.location.href = `/outbound_shipments/${reservationShipmentId}`
  }

  return (
    <>
      <button
        className="bg-white rounded p-2 outline outline-indigo-500 text-indigo-500"
        onClick={() => setIsModalOpen(true)}
      >
        Start over
      </button>
      {
        isModalOpen && <PopUpModal closeModal={handleCloseModal} handleAccept={startOver} header="Start Over" mainText='Are you sure you want to start over?' />
      }
    </>
  )
}

StartOverButton.propTypes = {
  reservationShipmentId: PropTypes.number.isRequired
}

export default StartOverButton
