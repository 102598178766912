import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import camelize from 'camelize'
import ContactsApi from '../../api/ContactsApi'
import React, { useState } from 'react'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from '../shared/ErrorPopup'
import LoadingSpinner from '../shared/LoadingSpinner'
import RecurlyForm from '../shared/RecurlyForm'
import PropTypes from 'prop-types'
import { ReactComponent as MoreIcon } from '../../assets/more.svg'
import EditContactModal from './EditContactModal'
import BasicInformation from './BasicInformation'

const ContactDetails = ({ contact, editBilling, setEditBilling, setUpdateBillingIsLoading, handleBillingUpdate, setContact, updateBillingIsLoading }) => {
  const [error, setError] = useState(null)
  const [moreModal, setMoreModal] = useState(false)
  const [editContactModal, setEditContactModal] = useState(false)

  const addError = (error) => {
    ErrorHelper.addError(error, setError, document.querySelector('form'))
  }

  const closeModal = () => {
    setEditContactModal(prevState => !prevState)
  }

  const handleContactUpdate = (info) => {
    closeModal()

    const params = {
      id: contact.id,
      contact: info
    }

    ContactsApi.updateContact(params)
      .then(res => res.json())
      .then(json => {
        if (json.error_message) {
          ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document.querySelector('div'))
        } else {
          setContact(camelize(json.contact))
        }
      })
      .catch((e) => {
        console.log(e)
        ErrorHelper.addError({ bannerMessage: 'There was an error updating contact information' }, setError, document.querySelector('div'))
      })
  }

  const renderButtonSection = () => {
    return (
      <div className="mt-8 text-right">
        <button
          className="border rounded px-16 py-2.5 mr-6"
          onClick={() => setEditBilling(prev => !prev)}
        >
          Cancel
        </button>
        <button
          type='submit'
          className="submit_recurly text-white bg-blue-700 px-16 py-2.5 rounded w-max "
          disabled={updateBillingIsLoading}
        >
          {updateBillingIsLoading ? <LoadingSpinner /> : 'Save'}
        </button>
      </div>
    )
  }

  const renderMoreButton = () => {
    return (
      <div className='col-span-1 text-right relative flex items-start ml-auto mr-2'>
        <div onClick={() => setMoreModal(prev => !prev)} className="ml-2">
          <div>
            <MoreIcon className='cursor-pointer' title="more-icon"/>
            {moreModal &&
              <button
                className='absolute right-0 mt-2 border p-2 w-max rounded disabled:text-gray-300'
                onClick={() => setEditContactModal(true)}
              >
                Edit Customer
              </button>
            }
          </div>
          {editContactModal && <EditContactModal handleContactUpdate={handleContactUpdate} closeModal={closeModal} contact={contact} /> }
        </div>
      </div>
    )
  }

  const renderShowBilling = () => {
    return (
      <div className='flex'>
        <div>
          <div className="font-bold">
            {contact.billingInfo.cardType || 'Unknown card type'}
          </div>
          <div>
            **** **** **** {contact.billingInfo.lastFour}
          </div>
        </div>
        <div className="ml-12 text-[#5542F6] self-center">
          <button onClick={() => setEditBilling(prev => !prev)}>
            Edit
          </button>
        </div>
      </div>
    )
  }

  const renderEditBilling = () => {
    return (
      <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY} required={['cvv']}>
        <Elements>
          <RecurlyForm
            contact={contact}
            handleRecurlyTokenSuccess={(token) => handleBillingUpdate(token)}
            addError={addError}
            renderButtonSection={renderButtonSection}
            setIsLoading={setUpdateBillingIsLoading}
          />
        </Elements>
      </RecurlyProvider>
    )
  }

  const renderBillingInformation = () => {
    return (
      <div>
        <div className="bg-[#FBFAFC] py-2 px-4">Credit Card Information</div>
        {contact.billingInfo &&
          <div className="py-4 border-y px-4">
            {error && <ErrorPopup error={error}/>}
            {!editBilling && renderShowBilling()}
            {editBilling && renderEditBilling()}
          </div>
        }
        {!contact.billingInfo && 'No billing on file'}
      </div>
    )
  }

  return (
    <div>
      <div className='grid grid-cols-3 mt-4'>
        <div className='col-span-2'>
          <h2 className='text-2xl mt-2 py-2 px-4'>{contact.name}</h2>
        </div>
        {renderMoreButton()}
      </div>

      <BasicInformation contact={contact} />

      {renderBillingInformation()}
    </div>
  )
}

export default ContactDetails

ContactDetails.propTypes = {
  contact: PropTypes.object.isRequired,
  editBilling: PropTypes.bool.isRequired,
  setContact: PropTypes.func.isRequired,
  setEditBilling: PropTypes.func.isRequired,
  setUpdateBillingIsLoading: PropTypes.func.isRequired,
  handleBillingUpdate: PropTypes.func.isRequired,
  updateBillingIsLoading: PropTypes.bool.isRequired
}
