import React, { useEffect, useState } from 'react'
import MpcApi from '../../api/MpcApi'
import CheckInRecoveredList from './CheckInRecoveredList'
import CheckInRecoveredListConfirmation from './CheckInRecoveredListConfirmation'

const CheckInRecovered = () => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [items, setItems] = useState([])
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    getInventory()
  }, [])

  const getProducts = (data) => {
    return data.flatMap(item => item.products).filter(product => product.id)
  }

  const getInventory = async () => {
    await MpcApi.checkInventory('check_in_recovered')
      .then(res => res.json())
      .then(json => {
        setItems(getProducts(json.on_display_products))
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleConfirm = () => {
    setIsSubmitted(true)
    if (!items.some(item => item.recovered)) {
      setIsValid(false)
      return
    }

    setIsValid(true)

    items.forEach(item => {
      if (item.recovered) {
        MpcApi.enterProductToInventory(item.id)
          .then(res => {
            setHasError(false)
            res.json()
          })
          .catch((e) => {
            setHasError(true)
            console.log(e)
          })
      }
    })

    if (!hasError) {
      setIsConfirmed(true)
    }
  }

  const handleSelection = (id, isRecovered) => {
    console.log(id, isRecovered)
    setItems((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return { ...item, recovered: isRecovered }
        }
        return item
      })
    )
  }

  return (
    <div className="container mx-auto">
      <div className="border-b-2 my-6" />
      <h1 className="text-indigo-600 py-4 text-xl font-bold">CHECK IN RECOVERED ITEMS</h1>
      <div className="bg-slate-100 my-4 px-4 py-6 rounded-lg text-center">
        {!isConfirmed && <CheckInRecoveredList
          items={items}
          handleSelection={handleSelection}
          handleConfirm={handleConfirm}
          isSubmitted={isSubmitted}
          isValid={isValid}
          hasError={hasError}
        />}
        {isConfirmed && <CheckInRecoveredListConfirmation items={items} />}
      </div>
    </div>
  )
}

export default CheckInRecovered
