const qcStepStatusToHeaderTextMap = new Map([
  ['In transit to store', 'Check shipment from warehouse into store'],
  ['In store outbound', 'Check-out to customer'],
  ['With customer', 'Check-in from customer'],
  ['In store inbound', 'Check-in to warehouse']
])

const qcStepStatusToButtonTextMap = new Map([
  ['In transit to store', 'QC shipment - Check-in to store'],
  ['In store outbound', 'QC shipment - Check-out to customer'],
  ['With customer', 'QC shipment - Check-in from customer'],
  ['In store inbound', 'QC shipment - Check-in to warehouse']
])
class QcStepHelper {
  static statusForPage (qcStep) {
    return qcStepStatusToHeaderTextMap.get(qcStep)
  }

  static statusForButton (qcStep) {
    return qcStepStatusToButtonTextMap.get(qcStep)
  }
}

export default QcStepHelper
