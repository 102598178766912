import camelize from 'camelize'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReservationsApi from '../../../api/ReservationsApi'
import ContactsTable from '../../contacts/ContactsTable'
import RecurlyForm from '../../shared/RecurlyForm'
import ReservationsBySkusTable from '../ReservationsBySkuTable'
import { RecurlyProvider, Elements } from '@recurly/react-recurly'
import ErrorHelper from '../../../helpers/error-helper'
import ErrorPopup from '../../shared/ErrorPopup'
import CancelReservationCreation from '../CancelReservationCreation'
import { handleRecurlySuccess } from '../../../helpers/recurly-helper'
import LoadingSpinner from '../../shared/LoadingSpinner'
// TODO: Change the naming of this component
const NewRecurlyContact = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [reservationInfo, setReservationInfo] = useState(null)
  const [error, setError] = useState(null)
  const [createRecurlyIsLoading, setCreateRecurlyIsLoading] = useState(false)

  useEffect(() => {
    if (!reservationInfo) {
      const id = params.id
      ReservationsApi.fetchReservation(id)
        .then(res => res.json())
        .then(json => {
          if (json.error_message) {
            ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document.querySelector('div'))
          } else {
            setReservationInfo(camelize(json))
          }
        })
        .catch(() => {
          ErrorHelper.addError({ bannerMessage: 'There was an error fetching reservation shipment' }, setError, document.querySelector('div'))
        })
    }
  }, [])

  const handleRecurlyTokenSuccess = (recurlyToken, setIsFormSubmitted) => {
    const params = { recurlyToken, reservationShipmentId: reservationInfo.reservationShipment.id }
    const navigateFunction = () => {
      setIsFormSubmitted(true)
      navigate(`/reservations/${reservationInfo.reservationShipment.id}/customer_confirmation`)
    }

    handleRecurlySuccess(params, setError, setCreateRecurlyIsLoading, navigateFunction)
  }

  const renderButtonSection = () => {
    return (
      <div className="col-span-1 mt-8 flex flex-row">
        <CancelReservationCreation reservationShipment={reservationInfo.reservationShipment} />
        <button type='submit' className="submit_recurly text-white bg-blue-700 px-5 py-2.5 rounded w-64 ml-auto">
          {createRecurlyIsLoading ? <LoadingSpinner /> : 'Proceed to Checkout'}
        </button>
      </div>
    )
  }

  return (
    <div className="m-16">
      {error && <ErrorPopup error={error} />}
      {reservationInfo && <ReservationsBySkusTable reservationShipment={reservationInfo.reservationShipment} reservationsBySkus={reservationInfo.reservationsBySkus} />}
      <div className="my-8" />
      {reservationInfo && <ContactsTable contacts={[reservationInfo.reservationShipment.contact]} />}

      <div className="w-2/3 mt-8">
        {error && <ErrorPopup error={error} />}
        <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY} required={['cvv']}>
          <Elements>
            {reservationInfo &&
              <RecurlyForm
                contact={reservationInfo.reservationShipment.contact}
                handleRecurlyTokenSuccess={(tokenId) => handleRecurlyTokenSuccess(tokenId)}
                addError={(error) => ErrorHelper.addError(error, setError, document.querySelector('form'))}
                renderButtonSection={renderButtonSection}
                setIsLoading={setCreateRecurlyIsLoading}
              />
            }
          </Elements>
        </RecurlyProvider>
      </div>
    </div>
  )
}

export default NewRecurlyContact
