import PropTypes from 'prop-types'
import React from 'react'

const BasicInformation = ({ contact }) => {
  return (
    <div>
      <div className="bg-[#FBFAFC] py-2 px-4">Basic Information</div>

      <div className="py-4 border-b px-4">
        <div className="font-bold">Email</div>
        <div>{contact.email}</div>
      </div>

      <div className="py-4 border-b px-4">
        <div className="font-bold">Phone</div>
        <div>{contact.phone || 'not on file'}</div>
      </div>

      <div className="py-4 border-b px-4">
        <div className="font-bold">Address</div>
        <div>{contact.address}</div>
        <span>{contact.city}, </span>
        <span>{contact.state}</span>
        <div>{contact.zip}</div>
      </div>
    </div>
  )
}

export default BasicInformation

BasicInformation.propTypes = {
  contact: PropTypes.object.isRequired
}
