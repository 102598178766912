import PropTypes from 'prop-types'
import React from 'react'
import SkuThumbnail from './SkuThumbnail'

const SelectedSkus = ({ selectedSkus, unselectSku }) => {
  const handleClick = (e) => {
    e.preventDefault()
    alert('Reservations can no longer be created')
  }

  if (selectedSkus.length) {
    return (
      <div className="bg-slate-100 my-4 p-2 rounded-lg">
        <p className="font-bold">
          Selected SKUs
        </p>

        <div className="flex flex-row flex-wrap rounded-lg">
          {
            selectedSkus.map(selectedSku =>
              <SkuThumbnail
                checked
                classNames="h-32 w-32"
                key={`selected-${selectedSku.sku}`}
                skuObject={selectedSku}
                unselectSku={unselectSku}
              />)
          }
        </div>
        <div className="flex flex-row justify-end">
          <form
            onSubmit={handleClick}
          >
            {
              selectedSkus.map(selectedSku =>
                <input
                  className="hidden"
                  key={`selected-sku-${selectedSku.sku}`}
                  name="skus[]"
                  type="text"
                />)
            }
            <button
              className="leading-6 bg-indigo-600 rounded p-2 w-max text-white"
              type="submit"
            >
              Check availability
            </button>
          </form>
        </div>
      </div>
    )
  }

  return null
}

SelectedSkus.defaultProps = { selectedSkus: [] }

SelectedSkus.propTypes = {
  selectedSkus: PropTypes.array,
  unselectSku: PropTypes.func.isRequired
}

export default SelectedSkus
