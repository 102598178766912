import React from 'react'
import CheckInRecoveredItem from './CheckInRecoveredItem'
import PropTypes from 'prop-types'

const CheckInRecoveredList = ({ items, handleSelection, handleConfirm, isSubmitted, isValid, hasError }) => {
  return (
    <>
      <p className="mt-8 font-bold">Select items to mark as recovered.</p>
      <div className="mt-4 flex flex-col gap-2 align-center justify-center">
        {items.map(item =>
          <CheckInRecoveredItem key={item.id} item={item} handleSelection={handleSelection} />
        )}
      </div>
      {isSubmitted && hasError && <div className="mt-4 text-red-600">There was a problem submitting your form. Please try again.</div>}
      {isSubmitted && !isValid && <div className="mt-4 text-red-600">Inputs are invalid. Select the items to mark as recovered.</div>}
      <div className="mt-4 mx-auto flex justify-center gap-2">
        <button
          className="block bg-indigo-500 rounded text-white px-4 py-2 hover:bg-indigo-700 focus:bg-indigo-600 duration-150"
          onClick={ handleConfirm }
        >
          Confirm
        </button>
        <a
          className="block bg-white rounded px-4 py-2 text-indigo-500 hover:bg-indigo-200 focus:bg-indigo-300 duration-150 border border-solid border-indigo-500"
          href="/mpc"
        >Cancel</a>
      </div>
    </>
  )
}

CheckInRecoveredList.propTypes = {
  items: PropTypes.array.isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired
}

export default CheckInRecoveredList
