import camelize from 'camelize'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReservationsApi from '../../../api/ReservationsApi'
import ErrorHelper from '../../../helpers/error-helper'
import ErrorPopup from '../../shared/ErrorPopup'
import ReservationOrderDetails from '../../shared/ReservationOrderDetails'
import ConfirmationAgreementSection from '../ConfirmationAgreementSection'
import TermsOfServiceModal from '../TermsOfServiceModal'
import NewReservationHeader from './NewReservationHeader'
import PromoCodeField from './PromoCodeField'

const CustomerConfirmation = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [reservationInfo, setReservationInfo] = useState(null)
  const [marketingCommunications, setMarketingCommunications] = useState(false)
  const [transactionalCommunications, setTransactionalCommunications] = useState(false)
  const [showTos, setShowTos] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [error, setError] = useState(null)
  const [promotion, setPromotion] = useState(null)

  useEffect(() => {
    if (!reservationInfo) {
      const id = params.id
      ReservationsApi.fetchReservation(id, 'include_deposit_tax_info=true&include_pickup_fee_tax_info=true')
        .then(res => res.json())
        .then(json => {
          if (json.reservation_shipment) {
            setReservationInfo(camelize(json))
          } else {
            renderError('Reservation not found')
          }
        })
        .catch(() => {
          renderError('Something went wrong finding reservation')
        })
    }
  }, [])

  const tosToggle = () => {
    setShowTos(prev => !prev)
  }

  const additionalFields = () => {
    return (
      <>
        <PromoCodeField contactId={reservationInfo.reservationShipment.contact.id} setPromotion={setPromotion} />
        <ConfirmationAgreementSection
          marketingCommunications={marketingCommunications}
          setMarketingCommunications={setMarketingCommunications}
          transactionalCommunications={transactionalCommunications}
          setTransactionalCommunications={setTransactionalCommunications}
          tosToggle={tosToggle}
          confirmAndContinue={confirmAndContinue}
          loadingSubmit={loadingSubmit}
          reservationShipment={reservationInfo.reservationShipment}
        />
      </>
    )
  }

  const renderError = (error) => {
    ErrorHelper.addError({ bannerMessage: error }, setError, document)
  }

  const confirmAndContinue = () => {
    setLoadingSubmit(true)
    const confirmationParams = {
      reservationShipmentId: reservationInfo.reservationShipment.id,
      marketingCommunications,
      transactionalCommunications
    }
    ReservationsApi.customerConfirmation(confirmationParams)
      .then(res => res.json())
      .then(json => {
        if (json.success === true) {
          navigate(`/reservations/${reservationInfo.reservationShipment.id}/confirmation`, { state: { promotion } })
        } else {
          setLoadingSubmit(false)
          renderError('Something went wrong confirming the customer agreement, please try again')
        }
      })
      .catch(err => {
        setLoadingSubmit(false)
        renderError(err)
      })
  }

  return (
    <div className='-mx-16 pt-24'>
      <NewReservationHeader step={2} />
      <div className='mt-16 pb-16'>
        {error && <ErrorPopup error={error} />}
        {reservationInfo &&
          <ReservationOrderDetails
            additionalFields={additionalFields}
            promotion={promotion}
            reservationInfo={reservationInfo}
            setPromotion={setPromotion}
          />
        }
        {showTos && <TermsOfServiceModal tosToggle={tosToggle} />}
      </div>
    </div>
  )
}

export default CustomerConfirmation
