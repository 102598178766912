import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select from 'react-select'

import ReservationCustomerHeader from '../common/ReservationCustomerHeader'
import SingleProduct from '../common/SingleProduct'
import { isReceiving } from '../../helpers/process-shipments'
import { ProcessShipmentContext } from '../../contexts/ProcessShipmentContext'
import StartOverButton from './StartOverButton'

const ProductStatus = ({
  statusType,
  reservation,
  reservationShipment,
  statuses
}) => {
  const { product } = reservation
  const { contact, presentReservations } = reservationShipment
  const reservationShipmentId = reservationShipment.id
  const [selectedProductStatus, setSelectedProductStatus] = useState(null)
  const { addProductStatus } = useContext(ProcessShipmentContext)

  const displayLabel = key => {
    if (key === 'out') return 'Intact'

    const result = key.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  const createStatusOptions = () => {
    return Object.entries(statuses).map((status) => {
      const [key, value] = status

      return {
        value,
        label: displayLabel(key)
      }
    })
  }

  const statusOptions = {
    label: statusType === 'present'
      ? 'Status of present item:'
      : 'Status of missing item:',
    options: createStatusOptions()
  }

  const nextLinkHtml = () => {
    const presentReservationsCount = presentReservations.length
    if (presentReservations[presentReservationsCount - 1].id === reservation.id) {
      return (
        <Link
          className={classNames('bg-indigo-500 rounded text-white p-2',
            {
              'pointer-events-none': !selectedProductStatus
            }
          )}
          to={isReceiving()
            ? `/inbound_shipments/${reservationShipmentId}/confirm`
            : `/outbound_shipments/${reservationShipmentId}/confirm`}
        >
          Finished
        </Link>
      )
    }

    const nextReservationIndex = presentReservations.findIndex(res => res.id === reservation.id) + 1
    const nextReservation = presentReservations[nextReservationIndex]

    if (nextReservation) {
      return (
        <Link
          className={classNames('bg-indigo-500 rounded text-white p-2',
            {
              'pointer-events-none': !selectedProductStatus
            }
          )}
          to={isReceiving()
            ? `/inbound_shipments/${reservationShipmentId}/${nextReservation.id}`
            : `/outbound_shipments/${reservationShipmentId}/${nextReservation.id}`}
        >
          Next item
        </Link>
      )
    }

    return null
  }

  const handleProductStatusChange = (e) => {
    const status = e.value
    const productStatus = {
      productId: product.id,
      desiredStatus: status
    }

    setSelectedProductStatus(status)
    addProductStatus(productStatus)
  }

  return (
    <div className="container mx-auto">
      <ReservationCustomerHeader
        contact={contact}
        reservationShipmentId={reservationShipmentId}
        qcStep={reservationShipment.statusName}
      />
      <h3 className="text-center font-bold text-indigo-500 text-xl">
        {statusType === 'present' ? 'Present item status' : 'Missing item status'}
      </h3>
      <div className="mt-8 grid grid-cols-2 m-2 gap-x-12 mx-10">
        <SingleProduct reservation={reservation} statuses={statuses} />
        <div>
          <>
            <label htmlFor="product_status">
              {statusOptions.label}
            </label>
            <Select
              defaultValue={selectedProductStatus}
              inputId="product_status"
              isSearchable
              placeholder="Select reason"
              options={statusOptions.options}
              onChange={handleProductStatusChange}
            />
          </>
        </div>
      </div>
      <div className="flex flex-row justify-center mt-8 gap-6">
        <StartOverButton reservationShipmentId={reservationShipmentId} />
        {nextLinkHtml()}
      </div>
    </div>
  )
}

ProductStatus.propTypes = {
  reservation: PropTypes.shape({
    id: PropTypes.number,
    product: PropTypes.object
  }).isRequired,
  reservationShipment: PropTypes.shape({
    id: PropTypes.number,
    contact: PropTypes.object.isRequired,
    presentReservations: PropTypes.array.isRequired,
    reservations: PropTypes.array.isRequired,
    statusName: PropTypes.string.isRequired
  }).isRequired,
  statuses: PropTypes.object.isRequired,
  statusType: PropTypes.string.isRequired
}

export default ProductStatus
