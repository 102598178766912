import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import camelize from 'camelize'

import ProcessShipmentApi from '../../api/ProcessShipmentApi'
import { isReceiving } from '../../helpers/process-shipments'

const TrackingNumberModal = ({ isVisible, onClose, addError }) => {
  if (!isVisible) return null

  const navigate = useNavigate()
  const [trackingNumber, setTrackingNumber] = useState('')

  const handleClose = (e) => {
    if (e.target.id === 'modal_wrapper') onClose()
  }

  const handleTrackingNumberChange = (e) => {
    setTrackingNumber(e.target.value)
  }

  const handleSuccessResponse = (response) => {
    const { errorMessage } = response

    if (errorMessage) {
      addError(errorMessage)
      onClose()
    } else {
      const { reservationShipment } = response
      isReceiving()
        ? navigate(`/inbound_shipments/${reservationShipment.id}`)
        : navigate(`/outbound_shipments/${reservationShipment.id}`)
    }
  }

  const handleTrackingNumberSearch = () => {
    if (isReceiving()) {
      ProcessShipmentApi.fetchReceiveReservationShipment(trackingNumber)
        .then(resp => resp.json())
        .then(json => {
          handleSuccessResponse(camelize(json))
        })
    } else {
      ProcessShipmentApi.fetchReturnReservationShipment(trackingNumber)
        .then(resp => resp.json())
        .then(json => {
          handleSuccessResponse(camelize(json))
        })
    }
  }

  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 backdrop-blur-sm bg-black/60 transition-opacity"/>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          id="modal_wrapper"
          onClick={handleClose}
        >
          <div className="relative transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div className="mt-3 sm:mt-0 text-left">
                  <h3 className="pt-6 text-lg font-medium leading-6 text-gray-900" id="modal-title">
                    Tracking number
                  </h3>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="tracking_number"
                      onChange={handleTrackingNumberChange}
                      className="w-full border-solid border border-gray-600 rounded leading-6 p-2 h-[75px]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6 pb-12 text-center">
              <button
                type="button"
                className="bg-indigo-500 rounded text-white p-2 w-1/2"
                onClick={handleTrackingNumberSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TrackingNumberModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addError: PropTypes.func.isRequired
}

export default TrackingNumberModal
