import React from 'react'
import PropTypes from 'prop-types'

const ContactInfoColumn = ({ contact, pickupContacts }) => {
  return (
    <div className="p-4 bg-[#FBFAFC] h-full">
      <div className="border-b">
        <h2 className="pb-4 text-xl font-semibold">Customer</h2>

        <div className="pb-5 text-sm">
          <div className="text-gray-400">
            Name
          </div>
          <div className='font-medium' data-testid='contact-info-col-name'>
            {contact.name}
          </div>
        </div>

        <div className="pb-5 text-sm">
          <div className="text-gray-400">
            Phone
          </div>
          <div className='font-medium' data-testid='contact-info-col-phone'>
            {contact.phone}
          </div>
        </div>

        <div className="pb-5 text-sm">
          <div className="text-gray-400">
            Email
          </div>
          <div className='font-medium' data-testid='contact-info-col-email'>
            {contact.email}
          </div>
        </div>

        <div className="pb-5 text-sm">
          <div className="text-gray-400">
            Address
          </div>
          <div className='font-medium '>
            <div data-testid='contact-info-col-address'>{contact.address}</div>
            <div data-testid='contact-info-col-city-state'>{contact.city}, {contact.state}</div>
            <div data-testid='contact-info-col-zip'>{contact.zip}</div>
          </div>
        </div>
      </div>

      <div className="border-b py-4">
        <h2 className="text-xl mb-2 font-semibold">Alternate Assignments</h2>
        {pickupContacts.length > 0 && pickupContacts.map(contact => {
          return (
            <div className="border-b last:border-0" key={contact.id}>
              <div className="py-3">
                <div>
                  {contact.name}
                </div>
                <div className="text-xs text-gray-400">
                  {contact.phone}
                </div>
              </div>
            </div>
          )
        })}
        {!pickupContacts.length > 0 &&
          <div>
            No pickup people added
          </div>
        }
      </div>
      <div className="py-4">
        <h2 className="text-xl mb-4 font-semibold">Payment Method</h2>
        <div className='flex'>
          <div>{contact.billingInfo.cardType}</div>
          <div className='pl-3'>
            <div className="text-gray-400 text-xs">Card Number</div>
            <div>**** **** **** {contact.billingInfo.lastFour}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfoColumn

ContactInfoColumn.propTypes = {
  contact: PropTypes.object.isRequired,
  pickupContacts: PropTypes.array.isRequired
}
