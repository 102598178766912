import { Navigate, Outlet, useSearchParams } from 'react-router-dom'
import React, { useContext, useState, useEffect } from 'react'
import { ReactComponent as ThemeIcon } from '../../assets/logo.svg'
import { AddErrorContext } from '../shared/DisplayedError'
import Authorization from '../../helpers/authorization'

const LoggedOutLayout = () => {
  const errorHandler = useContext(AddErrorContext)
  const loggedIn = Authorization.isLoggedIn()
  const [infoBanner, setInfoBanner] = useState(null)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    checkAndSetBannerInfo()
  }, [searchParams])

  // maybe refactor this to a wrapper or hook that can be used in other layouts/components
  const checkAndSetBannerInfo = () => {
    if (searchParams.has('infoBanner')) {
      setInfoBanner(searchParams.get('infoBanner'))
    } else {
      setInfoBanner(null)
    }
  }

  if (loggedIn) {
    return (
      <Navigate to="/inventory" replace={true} />
    )
  } else {
    return (
      <div className='min-h-screen w-screen bg-gradient-to-r from-indigo-50'>
        <div className='w-2/3 mx-auto'>
          <div className='pt-40 pb-28 text-center relative'>
            <ThemeIcon/>
            <div className='my-16'></div>
            <h1 className='text-5xl'>Welcome to Locket</h1>
            <br/>
            <h5 >Please sign in to continue.</h5>
          </div>
          {infoBanner && <div className='p-4 bg-green-200 text-green-700 w-full mb-4 text-center'>
            {infoBanner}
          </div>}
          <div className='bg-white p-4 text-xl shadow-xl'>
            <Outlet context={{ errorHandler }}/>
          </div>
        </div>
      </div>
    )
  }
}

export default LoggedOutLayout
