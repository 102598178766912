import ApiHelper from '../helpers/api-helper'
import Cookies from 'js-cookie'

class InventoryApi {
  static fetchIndex (params) {
    console.log('fetchIndex')

    const queryParams = this.buildIndexSearchQuery(params)

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/inventory?${queryParams}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static fetchAvailableSkus (requestedSkus, requestedQuantities) {
    const skus = ApiHelper.parameratizeArrayForRails('skus', requestedSkus)
    const quantities = ApiHelper.parameratizeArrayForRails('quantity', requestedQuantities)
    let queryParams = ''
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) queryParams += `[event_rental_store_id]=${eventRentalStoreIdCookie}&`
    queryParams += `${skus}&${quantities}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservable_skus?${queryParams}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static buildIndexSearchQuery (params) {
    const { sku, kinds, metals, designers, diamondCaratWeights, diamondClarities, diamondColors, prices } = params

    // when searching
    let searchQuery = ''
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) searchQuery += `[event_rental_store_id]=${eventRentalStoreIdCookie}&`
    if (sku) {
      searchQuery += `search[sku]=${sku}`
      return searchQuery
    }

    // when filtering
    if (kinds) searchQuery += ApiHelper.nestedParameratizeArrayForRails('filters', 'kinds', kinds)
    if (metals) searchQuery += ApiHelper.nestedParameratizeArrayForRails('filters', 'metals', metals)
    if (designers) searchQuery += ApiHelper.nestedParameratizeArrayForRails('filters', 'designer_ids', designers)
    if (diamondCaratWeights) searchQuery += ApiHelper.nestedParameratizeObjectForRails('filters', 'diamond_carat_weights', diamondCaratWeights)
    if (diamondClarities) searchQuery += ApiHelper.nestedParameratizeArrayForRails('filters', 'diamond_clarties', diamondClarities)
    if (diamondColors) searchQuery += ApiHelper.nestedParameratizeArrayForRails('filters', 'diamond_colors', diamondColors)
    if (prices) searchQuery += ApiHelper.nestedParameratizeObjectForRails('filters', 'prices', prices)

    return searchQuery
  }
}

export default InventoryApi
