import PropTypes from 'prop-types'
import React from 'react'

const LoadingSpinner = ({ color }) => (
  <div className="inline-block">
    <div className={`w-4 h-4 border-b-2 border-${color} rounded-full animate-spin`} />
  </div>
)

LoadingSpinner.propTypes = { color: PropTypes.string }
LoadingSpinner.defaultProps = { color: 'white' }

export default LoadingSpinner
