import Cookies from 'js-cookie'
import ApiHelper from '../helpers/api-helper'

class ProductDescriptorsApi {
  static fetchProductDescriptor (id) {
    console.log('fetchShow')
    let params = `${id}`
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) params += `/?[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/product_descriptors/${params}`, {
      headers: ApiHelper.getHeaders()
    })
  }
}

export default ProductDescriptorsApi
