import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReservationsApi from '../../api/ReservationsApi'
import ReservationsTable from '../shared/ReservationsTable'
import camelize from 'camelize'

const InboundShipments = () => {
  const [warehouseReservations, setWarehouseReservations] = useState([])
  const [customerReturns, setCustomerReturns] = useState([])

  // fetch reservations when component mounts
  useEffect(() => {
    fetchInboundReservations()
  }, [])

  const fetchInboundReservations = () => {
    ReservationsApi.fetchInboundReservations()
      .then(resp => resp.json())
      .then(json => {
        setWarehouseReservations(camelize(json.warehouse_shipments))
        setCustomerReturns(camelize(json.customer_shipments))
      })
  }

  return (
    <div className="container mx-auto">
      <div className="border-b-2 bg-white pb-6 sticky top-[70px] w-full z-10 pt-1">
        <div className="bg-indigo-500 rounded text-white p-2 w-max mx-auto">
          <Link to='/inbound_shipments/scan'>QC inbound shipment</Link>
        </div>
      </div>

      <h1 className="text-indigo-600 py-4 text-xl font-bold">WAREHOUSE SHIPMENTS</h1>
       <ReservationsTable reservations={warehouseReservations} sorting={false}/>

      <h1 className="text-indigo-600 py-4 text-xl font-bold">CUSTOMER RETURNS</h1>
      <ReservationsTable reservations={customerReturns} sorting={false} />
    </div>
  )
}

export default InboundShipments
