import React from 'react'
import MpcItemReadOnly from './MpcItemReadOnly'
import PropTypes from 'prop-types'

const MpcCountListConfirmation = ({ items }) => {
  return (
    <section>
      <p>Count confirmed with</p>

      <div className="mt-2">
        <p>{items.filter((item) => item.product_status === 'In Store').length} item(s) in store</p>
        <p>{items.filter((item) => item.product_status !== 'In Store').length} item(s) missing</p>
      </div>

      <p className="mt-4 font-bold">MPC is complete.</p>

      <div className="mt-4 flex flex-col gap-2 align-center justify-center">
        {items.map(item => <MpcItemReadOnly
          key={item.id}
          item={item}
          reasonText={item.reason_text}
        />)}
      </div>
    </section>
  )
}

MpcCountListConfirmation.propTypes = {
  items: PropTypes.array.isRequired
}

export default MpcCountListConfirmation
