import Cookies from 'js-cookie'
import ApiHelper from '../helpers/api-helper'
import Authorization from '../helpers/authorization'

class AuthenticationApi {
  static login (email, password, handleInvalidLogin, handleValidLogin) {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password
      })
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(response.status)
        } else {
          return response.json()
        }
      })
      .then(json => handleValidLogin(json))
      .catch(() => {
        handleInvalidLogin()
      })
  }

  static requestResetPassword (email) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/send_reset_password_email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email
      })
    })
  }

  static resetPassword (email, resetToken, newPassword, confirmPassword) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reset_password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        reset_token: resetToken,
        new_password: newPassword,
        password_confirmation: confirmPassword
      })
    })
  }

  static getRefreshToken () {
    console.log('getting refresh token')
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/refresh_token`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static getStoreAndAssociateInfo () {
    console.log('getting store and associate info')

    let searchParams = ''
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) searchParams += `[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/get_store_and_associate_info?${searchParams}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(response.status)
        } else {
          return response.json()
        }
      })
      .then(json => AuthenticationApi.handleStoreAndAssociateInfo(json))
  }

  static handleStoreAndAssociateInfo (json) {
    localStorage.setItem('eventRentalStore', JSON.stringify(json.store))
    localStorage.setItem('associateEmail', JSON.stringify(json.store_associate_email))
    Authorization.identifyFullstoryUser()
  }

  static searchStore (storeNumber) {
    console.log('searching store')
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/stores/${storeNumber}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }
}

export default AuthenticationApi
