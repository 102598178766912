import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import ContactInfoColumn from './ContactInfoColumn'
import OrderDetailsColumn from './OrderDetailsColumn'

const ReservationOrderDetails = ({ paymentType, promotion, reservationInfo, setPromotion, additionalFields = null }) => {
  useEffect(() => {
    if (reservationInfo && !promotion) {
      handleUnredeemedPromotion()
    }
  }, [promotion, reservationInfo])

  const handleUnredeemedPromotion = () => {
    if (setPromotion) {
      const unredeemedPromotions = reservationInfo.reservationShipment.unredemeedPromotions
      if (unredeemedPromotions) {
        setPromotion(unredeemedPromotions.find(({ appliesTo }) => appliesTo.includes(paymentType)))
      }
    }
  }

  return (
    <div className='grid grid-cols-3 border-y'>
      <div className='col-span-2  border-r'>
        <OrderDetailsColumn paymentType={paymentType} promotion={promotion} reservationInfo={reservationInfo} setPromotion={setPromotion} />
        {/* option to add action components (buttons, etc) from parent component */}
        {additionalFields && additionalFields()}
      </div >
      <div className='col-span-1'>
        <ContactInfoColumn
          contact={reservationInfo.reservationShipment.contact}
          pickupContacts={reservationInfo.reservationShipment.reservationDetail.pickupContacts}
        />
      </div>
    </div >
  )
}

export default ReservationOrderDetails

ReservationOrderDetails.defaultProps = {
  paymentType: 'deposit',
  promotion: null,
  setPromotion: null
}

ReservationOrderDetails.propTypes = {
  paymentType: PropTypes.string,
  promotion: PropTypes.object,
  reservationInfo: PropTypes.object.isRequired,
  setPromotion: PropTypes.func,
  additionalFields: PropTypes.func
}
