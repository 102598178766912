import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg'
import DatesHelper from '../../helpers/dates-helper'

const ReservationDatesRow = ({ reservationShipment }) => {
  return (
    <div className="pb-4 text-xs text-[#84818A]">
      <span>
        <CalendarIcon className="inline mb-1 mr-2"/>
        <span className='text-[#2E2C34]'>Start</span> {DatesHelper.formatDateWithMonthAbbr(reservationShipment.pickupDate)}
      </span>
      <span>
        <CalendarIcon className="inline mb-1 mx-2"/>
        <span className='text-[#2E2C34]'>End</span> {DatesHelper.formatDateWithMonthAbbr(reservationShipment.dueBack)}
      </span>
    </div>
  )
}

ReservationDatesRow.propTypes = {
  reservationShipment: PropTypes.object.isRequired
}

export default ReservationDatesRow
