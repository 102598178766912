import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CancelReservationButton from '../CancelReservationButton'
import QcStepTextHelper from '../../../helpers/qc-step-text-helper'
import { ReactComponent as MoreIcon } from '../../../assets/more.svg'

const ReservationShipmentHeader = ({ id, reservationShipment }) => {
  const [moreModal, setMoreModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const { statusName, presentReservations } = reservationShipment;

  const displayReceiveShipmentButton = () => {
    return ['In transit to store', 'With customer'].includes(statusName) && presentReservations.length > 0
  }

  const displayReturnShipmentButton = () => {
    return ['In store inbound', 'In store outbound'].includes(statusName) && presentReservations.length > 0
  }

  return (
    <div className='-mx-14 pt-24'>
      <div>
        <Link to="/reservations">
          <span className='text-gray-400'>Reservations</span>
        </Link>
        <span> / Reservation Details</span>
      </div>
      <div className='grid grid-cols-3 mt-4'>
        <div className='col-span-2'>
          <h2 className='text-3xl font-bold text-left'>
            Reservation Shipment #{id}
          </h2>
        </div>
        <div className='col-span-1 text-right relative flex items-start ml-auto'>
          {displayReceiveShipmentButton() &&
            <div className="bg-indigo-500 rounded text-white py-2 px-8 w-max h-min">
              <Link to={`/inbound_shipments/${id}`}>{QcStepTextHelper.statusForButton(statusName)}</Link>
            </div>
          }
          {displayReturnShipmentButton() &&
            <div className="bg-indigo-500 rounded text-white py-2 px-8 w-max h-min">
              <Link to={`/outbound_shipments/${id}`}>{QcStepTextHelper.statusForButton(statusName)}</Link>
            </div>
          }
          <div onClick={() => setMoreModal(prev => !prev)} className="ml-2">
            <div>
              <MoreIcon className='cursor-pointer' title="more-icon"/>
            </div>
            {moreModal &&
              <button
                className='absolute right-0 mt-2 border p-2 w-max rounded disabled:text-gray-300'
                onClick={() => setCancelModal(true)}
                disabled={reservationShipment.cancelable === false}
              >
                Cancel reservation
              </button>
            }
          </div>
          {cancelModal && <CancelReservationButton reservation={reservationShipment} closeModal={() => setCancelModal(false)} /> }
        </div>
      </div>
    </div>
  )
}

ReservationShipmentHeader.propTypes = {
  id: PropTypes.string.isRequired,
  reservationShipment: PropTypes.object.isRequired
}

export default ReservationShipmentHeader
