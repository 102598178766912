import React from 'react'
import MpcItem from './MpcItem'
import PropTypes from 'prop-types'

const MpcCountList = ({ isSubmitted, isValid, items, itemCount, updateLostReason, handleReset, handleConfirm }) => {
  return (
    <section>
      <p className="mb-4">Number of pieces reported</p>

      <div className="mx-auto text-center w-48 border-solid border border-gray-300 rounded leading-6 p-2">{itemCount}</div>
      <p className="mt-8 font-bold">Select the correct status for each item.</p>
      <div className="mt-4 flex flex-col gap-2 align-center justify-center">
        {items.map(item => <MpcItem
          key={item.id}
          item={item}
          updateLostReason={updateLostReason}
          lostReason={item.reason_name}
        />)}
      </div>
      {isSubmitted && !isValid && <div className="mt-4 text-red-600">Inputs are invalid. Please select a status for each item.</div>}
      <div className="mt-4 mx-auto flex justify-center gap-2">
        <button
          className="block bg-indigo-500 rounded text-white px-4 py-2 hover:bg-indigo-700 focus:bg-indigo-600 duration-150"
          onClick={handleConfirm}
        >
          Confirm
        </button>
        <button
          className="block bg-white rounded px-4 py-2 text-indigo-500 hover:bg-indigo-200 focus:bg-indigo-300 duration-150 border border-solid border-indigo-500"
          onClick={handleReset}
        >Cancel</button>
      </div>
    </section>
  )
}

MpcCountList.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  itemCount: PropTypes.string.isRequired,
  updateLostReason: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired
}

export default MpcCountList
