import React from 'react'
import PropTypes from 'prop-types'

const ItemCounts = ({ itemCounts }) => {
  return (
    <div>
      <h2 className="font-bold text-lg">Item Counts</h2>
      <h3 className="text-[#B6B4BA] text-sm mt-4">Total</h3>
      <p>{itemCounts.total}</p>
      <h3 className="text-[#B6B4BA] text-sm mt-4">Available</h3>
      <p>{itemCounts.available}</p>
      <h3 className="text-[#B6B4BA] text-sm mt-4">Reserved</h3>
      <p>{itemCounts.reserved}</p>
      <h3 className="text-[#B6B4BA] text-sm mt-4">Receiving</h3>
      <p>{itemCounts.receiving}</p>
      <h3 className="text-[#B6B4BA] text-sm mt-4">Out</h3>
      <p>{itemCounts.out}</p>
    </div>
  )
}

ItemCounts.propTypes = {
  itemCounts: PropTypes.object.isRequired
}

export default ItemCounts
