import React, { useLayoutEffect, useState } from 'react'
import { Navigate, useOutletContext } from 'react-router-dom'
import AuthenticationApi from '../../../api/AuthenticationApi'
import Authorization from '../../../helpers/authorization'
import LoginForm from './LoginForm'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loggedIn, setLoggedIn] = useState(Authorization.isLoggedIn())
  const [loading, setLoading] = useState(false)
  const { errorHandler } = useOutletContext()

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const errorInParams = params.get('error');

  useLayoutEffect(() => {
    checkLoginStatus()
    if (errorInParams) {
      errorHandler({ bannerMessage: errorInParams }, document.querySelector('form'))
    }
  }, [])

  const checkLoginStatus = () => {
    const checkingInterval = setInterval(async () => {
      const loggedIn = Authorization.isLoggedIn()

      if (loggedIn) {
        clearInterval(checkingInterval)
        await AuthenticationApi.getStoreAndAssociateInfo()
        setLoggedIn(true)
      }
    })
  }

  const sendLoginInfo = () => {
    setLoading(true)
    AuthenticationApi.login(email, password, handleInvalidLogin, handleValidLogin)
  }

  const handleValidLogin = async (json) => {
    if (json.jwt) {
      Authorization.setLogin(json.jwt)
      await AuthenticationApi.getStoreAndAssociateInfo()
      setLoggedIn(Authorization.isLoggedIn())
    } else {
      handleInvalidLogin()
    }
  }

  const handleInvalidLogin = () => {
    setLoading(false)
    errorHandler({ bannerMessage: "User doesn't have access to the portal." }, document.querySelector('form'))
  }

  if (loggedIn) {
    return (
      <Navigate to="/choose_store" replace={true} />
    )
  } else {
    return (
      <div>
        <LoginForm
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          sendLoginInfo={sendLoginInfo}
          loading={loading}
        />
      </div>
    )
  }
}

export default Login
