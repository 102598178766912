import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import ReservationsApi from '../../../api/ReservationsApi'
import camelize from 'camelize'
import ReservationDetails from './ReservationDetails'
import ReservationHistory from './ReservationHistory'
import ReservationItems from './ReservationItems'
import ReservationShipmentHeader from './ReservationShipmentHeader'
import PickupPersonContainer from '../create_reservation_shipments/pickup_person/PickUpPersonContainer'

const ShowReservation = () => {
  const params = useParams()
  const [reservationInfo, setReservationInfo] = useState(null)
  const [selectedTab, setSelectedTab] = useState('details-tab')
  const { errorHandler } = useOutletContext()

  // disable back button if the associate previously came from the reservation confirmation page
  useEffect(() => {
    if (location.hash === '#reservation_confirmed') {
      history.pushState({}, '', '#disable_back_button_press')
    }

    const handleBackButtonPress = () => {
      alert('Warning! Do not use the back button. ' + '\n\n' +
        'This customer has already been charged for their reservation.' +
        'Pressing "back" in your browser may have unintended consequences.')
      history.pushState({}, '', '#back_button_pressed')
    }

    window.addEventListener('popstate', handleBackButtonPress)
    return () => window.removeEventListener('popstate', handleBackButtonPress)
  }, [])

  useEffect(() => {
    if (!reservationInfo) {
      const id = params.id
      ReservationsApi.fetchReservation(id)
        .then(res => res.json())
        .then(json => {
          if (json.reservation_shipment) {
            setReservationInfo(camelize(json))
          } else {
            errorHandler({ bannerMessage: 'Reservation not found' })
          }
        })
        .catch(() => {
          errorHandler({ bannerMessage: 'Something went wrong finding reservation' })
        })
    }
  }, [])

  const setTabStylingClass = (tabClass) => {
    if (selectedTab === tabClass) {
      return 'border-b-2 border-b-indigo-600'
    }
  }

  const renderReservationShipmentTabs = () => {
    return (
      <div className='-mx-14'>
        <ul className='flex flex-row mt-10'>
          <li className={`mr-10 pb-2 details-tab cursor-pointer ${setTabStylingClass('details-tab')}`}
            onClick={() => setSelectedTab('details-tab')}
          >
            Details
          </li>
          <li className={`mr-10 pb-2 items-tab cursor-pointer ${setTabStylingClass('items-tab')}`}
            onClick={() => setSelectedTab('items-tab')}
          >
            Items reserved
          </li>
          <li className={`mr-10 pb-2 history-tab cursor-pointer ${setTabStylingClass('history-tab')}`}
            onClick={() => setSelectedTab('history-tab')}
          >
            History
          </li>
          <li className={`pb-2 pickup-person-tab cursor-pointer ${setTabStylingClass('pickup-person-tab')}`}
            onClick={() => setSelectedTab('pickup-person-tab')}
          >
            Alternate Assignments
          </li>
        </ul>
      </div>
    )
  }

  return (
    <div className='relative -mx-1 px-4 -mt-14 z-10 bg-white h-[100vh]'>
      {reservationInfo &&
        <div>
          <ReservationShipmentHeader
            id={params.id}
            reservationShipment={reservationInfo.reservationShipment}
          />
          {renderReservationShipmentTabs()}
          <div className={`-mx-20  bg-${selectedTab === 'pickup-person-tab' ? '[#FBFAFC]' : 'white'} h-[100vh]`}>
            {selectedTab === 'details-tab' && <div className='mx-20'>
              <ReservationDetails
                reservationInfo={reservationInfo}
              />
            </div>}
            {selectedTab === 'items-tab' &&
              <div>
                <ReservationItems
                  reservations={reservationInfo.reservationShipment.reservations}
                  reservationShipment={reservationInfo.reservationShipment}
                />
              </div>
            }
            {selectedTab === 'history-tab' &&
              <div>
                <ReservationHistory
                  changeHistories={reservationInfo.reservationShipment.changeHistories}
                />
              </div>
            }
            {selectedTab === 'pickup-person-tab' &&
              <div>
                <PickupPersonContainer
                  reservationInfo={reservationInfo}
                  isVisible={true}
                  setReservationInfo={setReservationInfo}
                />
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default ShowReservation
