import React, { useState } from 'react'
import ErrorHelper from '../../helpers/error-helper'
import FormValidations from '../../helpers/form-validations'
import ErrorPopup from '../shared/ErrorPopup'
import PropTypes from 'prop-types'
import FormInputFieldWithLabel from '../shared/FormInputFieldWithLabel'

const EditContactModal = ({ handleContactUpdate, closeModal, contact }) => {
  const [formValues, setFormValues] = useState({
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
    streetAddress1: contact.streetAddress1,
    streetAddress2: contact.streetAddress2,
    city: contact.city,
    state: contact.state,
    zip: contact.zip,
    phone: contact.phone
  })
  const [error, setError] = useState(null)

  const handleFormChange = (event) => {
    const suffixStart = event.target.name.indexOf('-')
    const key = event.target.name.slice(0, suffixStart)
    const value = event.target.value

    if (key === 'phone') {
      FormValidations.formatPhoneNumberWithCallback(value, updateFormValue)
      return
    }

    updateFormValue(key, value)
  }

  const updateFormValue = (key, value) => {
    setFormValues(prevValues => (
      { ...prevValues, [key]: value }
    ))
  }

  const handleFormSubmit = () => {
    const fieldNameSuffix = '-edit-contact-form'
    const invalidFields = FormValidations.checkFieldValidity(Object.keys(formValues), fieldNameSuffix)

    if (invalidFields.length > 0) {
      invalidFields.forEach(field => {
        FormValidations.addErrorBorderToField(field)
      })
      ErrorHelper.addError({ bannerMessage: 'Please fill out all required fields' }, setError, document.querySelector('form'))
      FormValidations.addErrorMessageToField(invalidFields[0])
    } else {
      handleContactUpdate(formValues)
      setError(null)
    }
  }

  return (
    <div id='edit-contact-modal' tabIndex="-1" className='fixed flex inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-x-hidden overflow-y-auto h-modal'>
      <div className="bg-white p-8 inset-0 text-left border border-solid border-solid-gray border-4 w-[80%] my-auto mx-auto py-10">
        <h1 className="text-3xl font-bold pb-8">
          Edit Customer Information
        </h1>
        {error && <ErrorPopup error={error} />}
        <form>
          <div className='md:col-span-4 bg-neutral-100 border-t-2 border-gray-200 outline-none'>
            <div className='px-8 pt-6'>
              <h3 className='text-md font-medium leading-6 text-gray-900'>Contact Information</h3>
            </div>
            <div className='grid grid-cols-4 gap-4'>
              <FormInputFieldWithLabel
                label='First Name*'
                className='col-span-2 pl-8 pt-3'
                onChange={handleFormChange}
                value={formValues.firstName}
                inputName='firstName-edit-contact-form'
                required
                ariaLabel='edit-contact-form-firstName-input'
              />
              <FormInputFieldWithLabel
                label='Last Name*'
                className='col-span-2 pt-3 pr-8'
                onChange={handleFormChange}
                value={formValues.lastName}
                inputName='lastName-edit-contact-form'
                required
                ariaLabel='edit-contact-form-lastName-input'
              />
              <FormInputFieldWithLabel
                label='Email Address*'
                className='col-span-2 pl-8'
                onChange={handleFormChange}
                value={formValues.email}
                inputName='email-edit-contact-form'
                type='email'
                required
                ariaLabel='edit-contact-form-email-input'
              />
              <FormInputFieldWithLabel
                label='Phone*'
                className='col-span-2 pr-8'
                onChange={handleFormChange}
                value={FormValidations.formatPhoneNumber(formValues.phone)}
                inputName='phone-edit-contact-form'
                type='telephone'
                required
                pattern=".{0}|.{14,14}"
                title=" (Format is 1-xxx-xxx-xxxx)"
                ariaLabel='edit-contact-form-phone-input'
              />
              <FormInputFieldWithLabel
                label='Street Address 1*'
                className='col-span-4 px-8'
                onChange={handleFormChange}
                value={formValues.streetAddress1}
                inputName='streetAddress1-edit-contact-form'
                ariaLabel='edit-contact-form-streetAddress1-input'
                required
              />
              <FormInputFieldWithLabel
                label='Street Address 2'
                className='col-span-4 px-8'
                onChange={handleFormChange}
                value={formValues.streetAddress2}
                inputName='streetAddress2-edit-contact-form'
                ariaLabel='edit-contact-form-streetAddress2-input'
              />
              <FormInputFieldWithLabel
                label='City*'
                className='col-span-2 pl-8'
                onChange={handleFormChange}
                value={formValues.city}
                inputName='city-edit-contact-form'
                ariaLabel='edit-contact-form-city-input'
                required
              />
              <FormInputFieldWithLabel
                label='State*'
                className='col-span-1 pl-8'
                onChange={handleFormChange}
                value={formValues.state}
                inputName='state-edit-contact-form'
                ariaLabel='edit-contact-form-state-input'
                required
              />
              <FormInputFieldWithLabel
                label='Zip Code*'
                className='col-span-1 pr-8'
                onChange={handleFormChange}
                value={formValues.zip}
                inputName='zip-edit-contact-form'
                ariaLabel='edit-contact-form-zip-input'
                required
              />
            </div>
            <div className='col-span-2 flex flex-row space-x-8 pl-8 pb-8'>
              <button
                className='text-zinc-800 border border-solid border-gray-200 inline-flex justify-center py-2 px-4 text-sm font-medium text-white shadow-sm rounded w-max h-10' type='button'
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className='inline-flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 h-10'

                type='button'
                onClick={handleFormSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

EditContactModal.propTypes = {
  handleContactUpdate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired
}

export default EditContactModal
