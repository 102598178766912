import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReservationsApi from '../../api/ReservationsApi'
import ReservationsTable from '../shared/ReservationsTable'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from '../shared/ErrorPopup'
import camelize from 'camelize'

const OutboundShipments = () => {
  const [error, setError] = useState({})
  const [warehouseReservations, setWarehouseReservations] = useState([])
  const [customerPickups, setcustomerPickups] = useState([])

  // fetch reservations when component mounts
  useEffect(() => {
    ReservationsApi.fetchOutboundShipments()
      .then(resp => resp.json())
      .then(json => {
        const response = camelize(json)
        const {
          customerPickups,
          errorMessage,
          warehouseReturns
        } = response

        if (errorMessage) {
          renderError(errorMessage)
        } else {
          setWarehouseReservations(warehouseReturns)
          setcustomerPickups(customerPickups)
        }
      })
      .catch(() => {
        renderError('Something went wrong when fetching outbound shipments')
      })
  }, [])

  const renderError = (error) => {
    ErrorHelper.addError(
      { bannerMessage: error },
      setError,
      document.querySelector('.container')
    )
  }

  return (
    <div className="container mx-auto">
      <div className='border-b-2 bg-white pb-6 sticky top-[70px] w-full z-10 pt-1'>
        {error && <ErrorPopup error={error}/>}
        <div className="bg-indigo-500 rounded text-white p-2 w-max mx-auto">
          <Link to='/outbound_shipments/scan'>QC outbound shipment</Link>
        </div>
      </div>

      <h1 className="text-indigo-600 py-4 text-xl font-bold">WAREHOUSE RETURNS</h1>
        <ReservationsTable reservations={warehouseReservations} sorting={false}/>

      <h1 className="text-indigo-600 py-4 text-xl font-bold">CUSTOMER PICKUPS</h1>
        <ReservationsTable reservations={customerPickups} sorting={false}/>
    </div>
  )
}

export default OutboundShipments
