import React, { useRef, useState } from 'react'
import { useRecurly, CardNumberElement, CardMonthElement, CardYearElement, CardCvvElement } from '@recurly/react-recurly'
import FormValidations from '../../helpers/form-validations'
import PropTypes from 'prop-types'

const RecurlyForm = ({ addError, contact, handleRecurlyTokenSuccess, renderButtonSection, setIsLoading }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const formRef = useRef()
  const recurly = useRecurly()

  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault()
    recurly.token(formRef.current, (err, token) => {
      if (err) {
        setIsLoading(false)
        handleRecurlyError(err)
      } else {
        handleRecurlyTokenSuccess(token.id, setIsFormSubmitted)
      }
    })
  }

  const handleRecurlyError = (err) => {
    const errorDetailsArray = err.details
    let errorBanner = err.message
    const errorInputFields = []
    errorDetailsArray.forEach(error => {
      const field = document.querySelector(`#${error.field}`)
      if (field) {
        errorInputFields.push(field)
      }
    })
    if (errorInputFields.length > 0) {
      const fieldName = errorDetailsArray[0].field.replace(/_/g, ' ')
      errorBanner = `Please ensure credit card ${fieldName} details are correct and try again. Prepaid cards not accepted.`
      FormValidations.addErrorBorderToField(errorInputFields[0])
    }
    addError({ bannerMessage: errorBanner })
  }

  return (
    <form id='recurly-form-credit-card' onSubmit={handleSubmit} ref={formRef}>
      <input type="hidden" data-recurly="first_name" value={contact.firstName} />
      <input type="hidden" data-recurly="last_name" value={contact.lastName} />
      <input type="hidden" data-recurly="country" value="US" />
      <label className='block text-sm font-medium text-gray-700 pb-6'>
        Card Number
        <div className='mt-1 block text-sm w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 h-12 md:text-md'>
          <CardNumberElement
            id="number"
            className="flex pl-4 border bg-white border-gray-200 shadow-sm h-12 md:text-md rounded"
            style={{
              placeholder: {
                content: '0000 0000 0000 0000',
                fontSize: '14px',
                color: '#a3a9b5',
                fontWeight: '300'
              }
            }}
            disabled={isFormSubmitted}
          />
        </div>
      </label>
      <div className="grid grid-cols-4 gap-4">
        <div className='col-span-1'>
          <label className='block text-sm font-medium text-gray-700 pb-2'>
            Valid Until
            <CardMonthElement
              id="month"
              className="flex pl-4 border bg-white border-gray-200 shadow-sm h-12 md:text-md rounded"
              style={{
                placeholder: {
                  content: 'MM',
                  color: '#a3a9b5',
                  fontSize: '14px',
                  fontWeight: '300'
                }
              }}
            />
          </label>
        </div>
        <div className='col-span-1 pt-5'>
          <CardYearElement
            id="year"
            className="flex pl-4 border bg-white border-gray-200 shadow-sm h-12 md:text-md rounded"
            style={{
              placeholder: {
                content: 'YY',
                color: '#a3a9b5',
                fontSize: '14px',
                fontWeight: '300'
              }
            }}
          />
        </div>
        <div className='col-span-1 pt-5'>
          <CardCvvElement
            id="cvv"
            className="flex pl-4 border bg-white border-gray-200 shadow-sm h-12 md:text-md rounded"
            style={{
              placeholder: {
                content: 'CVV',
                color: '#a3a9b5',
                fontSize: '14px',
                fontWeight: '300'
              }
            }}
          />
        </div>
        <div className='col-span-1 pt-5'>
          <input
            id="postal_code"
            className="flex pl-4 border bg-white border-gray-200 shadow-sm h-12 md:text-md rounded w-[100%]"
            data-recurly="postal_code"
            placeholder="ZIP Code"
          />
        </div>
      </div>
      {!isFormSubmitted && renderButtonSection && renderButtonSection()}
    </form>
  )
}

RecurlyForm.propTypes = {
  addError: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  handleRecurlyTokenSuccess: PropTypes.func.isRequired,
  renderButtonSection: PropTypes.func,
  setIsLoading: PropTypes.func.isRequired
}

export default RecurlyForm
