class MoneyHelper {
  static centsToDollars (cents) {
    return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }

  static formatTaxRate (taxRate) {
    return taxRate?.toFixed(2)
  }
}

export default MoneyHelper
