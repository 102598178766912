import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const ContactSearch = ({ formValues, handleFormChange, handleSearch, setFormValues }) => {
  const [showForm, setShowForm] = useState(false)
  const [clearSearchCTA, setClearSearchCTA] = useState(false)

  const toggleForm = () => setShowForm(prevToggle => !prevToggle)

  const applySearch = () => {
    toggleForm()
    handleSearch()
    if (!blankFormValues()) {
      setClearSearchCTA(true)
    }
  }

  const clearSearch = () => {
    const blankVals = {}
    Object.keys(formValues).forEach(key => {
      blankVals[[key]] = ''
    })
    setFormValues(blankVals)
  }

  useEffect(() => {
    if (blankFormValues()) {
      setClearSearchCTA(false)
      handleSearch()
    }
  }, [formValues])

  const blankFormValues = () => {
    return Object.values(formValues).every(val => val === '')
  }

  const renderSearchButtonCTA = () => {
    if (clearSearchCTA) {
      return (
        <button
          type="button"
          className="leading-6 bg-indigo-600 rounded p-2 w-max text-white justify-self-end"
          onClick={clearSearch}
        >
          Clear Search
        </button>
      )
    } else {
      return (
        <button className="p-2 bg-indigo-600 rounded text-white" onClick={toggleForm}>
          Search
        </button>
      )
    }
  }

  return (
    <div className="text-center">
      {renderSearchButtonCTA()}
      { showForm &&
        <div className="bg-white fixed z-10 pt-36 inset-0 text-left">
          <form>
            <div className="mt-8 mx-auto w-1/2">
              <h1 className="text-3xl font-bold text-center">Customer Search</h1>
              <div className="grid grid-cols-2">
                <div className="m-2">
                  <label htmlFor="firstName" className="block">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formValues.firstName}
                    onChange={(event) => handleFormChange(event)}
                    className="w-full border-solid border border-gray-600 rounded leading-6 p-2"
                  />
                </div>
                <div className="m-2">
                  <label htmlFor="lastName" className="block">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formValues.lastName}
                    onChange={(event) => handleFormChange(event)}
                    className="w-full border-solid border border-gray-600 rounded leading-6 p-2"
                  />
                </div>
              </div>
              <div className="grid">
                <div className="m-2">
                  <label htmlFor="email" className="block">Email address</label>
                  <input
                    type="text"
                    name="email"
                    value={formValues.email}
                    onChange={(event) => handleFormChange(event)}
                    className="w-full border-solid border border-gray-600 rounded leading-6 p-2"
                  />
                </div>
              </div>
              <div className="grid">
                <div className="m-2">
                  <label htmlFor="streetAddress1" className="block">Street Address</label>
                  <input
                    type="text"
                    name="streetAddress1"
                    value={formValues.streetAddress1}
                    onChange={(event) => handleFormChange(event)}
                    className="w-full border-solid border border-gray-600 rounded leading-6 p-2"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="m-2">
                  <label htmlFor="city" className="block">City</label>
                  <input
                    type="text"
                    name="city"
                    value={formValues.city}
                    onChange={(event) => handleFormChange(event)}
                    className="w-full border-solid border border-gray-600 rounded leading-6 p-2"
                  />
                </div>
                <div className="m-2">
                  <label htmlFor="State" className="block">State</label>
                  <input
                    type="text"
                    name="state"
                    value={formValues.state}
                    onChange={(event) => handleFormChange(event)}
                    className="w-full border-solid border border-gray-600 rounded leading-6 p-2"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="m-2">
                  <label htmlFor="zipCode" className="block">Zip</label>
                  <input
                    type="text"
                    name="zipCode"
                    value={formValues.zipCode}
                    onChange={(event) => handleFormChange(event)}
                    className="w-full border-solid border border-gray-600 rounded leading-6 p-2"
                  />
                </div>
                <div className="m-2">
                  <label htmlFor="phone" className="block">Phone Number</label>
                  <input
                    type="text"
                    name="phone"
                    value={formValues.phone}
                    onChange={(event) => handleFormChange(event)}
                    className="w-full border-solid border border-gray-600 rounded leading-6 p-2"
                  />
                </div>
              </div>
              <div className="mt-8 grid grid-cols-2 m-2">
                <button type="button" className="button leading-6 border border-blue-600 rounded p-2 w-max text-blue-600" onClick={toggleForm}>Go Back</button>
                <button
                  type="button"
                  className="leading-6 bg-indigo-600 rounded p-2 w-max text-white justify-self-end"
                  onClick={applySearch}
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      }
    </div>
  )
}

ContactSearch.propTypes = {
  formValues: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired
}

export default ContactSearch
