import camelize from 'camelize'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReservationsApi from '../../../api/ReservationsApi'
import ErrorHelper from '../../../helpers/error-helper'
import ErrorPopup from '../../shared/ErrorPopup'
import CancelReservationCreation from '../CancelReservationCreation'
import CreditCardInformationForm from './CreditCardInformationForm'
import CustomerInformationForm from './CustomerInformationForm'
import NewReservationHeader from './NewReservationHeader'
import PickupPersonContainer from './pickup_person/PickUpPersonContainer'

const CreateReservationForm = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [error, setError] = useState(null)
  const [reservationInfo, setReservationInfo] = useState(null)
  const [isCustomerFormSubmitted, setIsCustomerFormSubmitted] = useState(false)
  const [isCreditCardFormSubmitted, setIsCreditCardFormSubmitted] = useState(false)
  const fetchReservationInfo = useCallback((id) => {
    ReservationsApi.fetchReservation(id)
      .then(res => res.json())
      .then(json => {
        if (json.error_message) {
          ErrorHelper.addError({ bannerMessage: json.error_message }, setError)
        } else {
          setReservationInfo(camelize(json))
        }
      })
      .catch(() => {
        ErrorHelper.addError({ bannerMessage: 'There was an error fetching reservation shipment' }, setError, document.querySelector('div'))
      })
  }, [])

  useEffect(() => {
    if (!reservationInfo) {
      fetchReservationInfo(params.id)
    }
  }, [])

  useEffect(() => {
    if (!isCustomerFormSubmitted) return
    fetchReservationInfo(params.id)
  }, [isCustomerFormSubmitted])

  useEffect(() => {
    if (!isCreditCardFormSubmitted) return
    fetchReservationInfo(params.id)
  }, [isCreditCardFormSubmitted])

  const handleFormSubmit = () => {
    if (isFormSubmittable()) {
      navigate(`/reservations/${reservationInfo.reservationShipment.id}/customer_confirmation`)
    }
  }

  const isFormSubmittable = () => {
    return isCustomerFormSubmitted && isCreditCardFormSubmitted
  }

  const checkButtonDisabled = () => {
    return !isFormSubmittable()
  }

  const renderButtonSection = () => {
    return (
      <div className='col-span-2 outline-none mt-8 flex flex-row space-x-8 pl-8'>
        <CancelReservationCreation reservationShipment={reservationInfo.reservationShipment} />
        {<button
          className='inline-flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-[#FBFAFC] disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none h-10'
          id='next-button'
          type='button'
          onClick={handleFormSubmit}
          disabled={checkButtonDisabled()}
        >
          Next
        </button>}
      </div>
    )
  }

  return (
    <div className='-mx-16'>
      <NewReservationHeader step={1} />
      <div className='mt-16 pb-16 bg-[#FBFAFC] w-full'>
        {error && <ErrorPopup error={error} className='fixed pl-8 top-32 bg-red-300 p-2 my-4 rounded text-center w-full' />}
        {reservationInfo &&
          <div>
            <CustomerInformationForm
              isCustomerFormSubmitted={isCustomerFormSubmitted}
              reservationInfo={reservationInfo}
              setIsCustomerFormSubmitted={setIsCustomerFormSubmitted}
              setError={setError}
            />
            <CreditCardInformationForm
              isCustomerFormSubmitted={isCustomerFormSubmitted}
              isCreditCardFormSubmitted={isCreditCardFormSubmitted}
              reservationInfo={reservationInfo}
              setError={setError}
              setIsCreditCardFormSubmitted={setIsCreditCardFormSubmitted}
            />
            <PickupPersonContainer
              isVisible={isCreditCardFormSubmitted}
              reservationInfo={reservationInfo}
              setReservationInfo={setReservationInfo}
            />
          </div>
        }
        {reservationInfo && renderButtonSection()}
      </div>
    </div>
  )
}

export default CreateReservationForm
