import Authorization from '../helpers/authorization'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import React, { useContext, useLayoutEffect, useState } from 'react'
import Cookies from 'js-cookie'

import { AddErrorContext } from './shared/DisplayedError'
import Header from './Header'
import Nav from './shared/Nav'
import SessionAboutToExpireModal from './modals/SessionAboutToExpireModal'
// Layout is a parent component of all pages that will be rendered
// Gives us a shared layout for the pages rendered
// Uses Outlet to be able to render children inside the router https://reactrouter.com/en/main/components/outlet
// Outlet is sorta like yield in rails views

const Layout = () => {
  const [loggedIn, setLoggedIn] = useState(Authorization.isLoggedIn())
  const [showSessionModal, setShowSessionModal] = useState(false)
  const errorHandler = useContext(AddErrorContext)
  const location = useLocation()

  // Hide Nav during process shipment
  const processingShipment =
    location.pathname.includes('/outbound_shipments/') ||
    location.pathname.includes('/inbound_shipments/') ||
    location.pathname.includes('/reservations/')

  useLayoutEffect(() => {
    checkLoginStatus()
  }, [])

  const checkLoginStatus = () => {
    setInterval(() => {
      sessionAboutToExpire()
      setLoggedIn(Authorization.isLoggedIn())
    }, 10000)
  }

  const sessionAboutToExpire = () => {
    const timeLeft = new Date(Cookies.get('loggedInTime')) - Date.now()
    const timeLeftInMins = Math.floor(timeLeft / 1000 / 60)
    if (timeLeftInMins <= 5) {
      setShowSessionModal(true)
    } else if (timeLeftInMins <= 0) {
      setShowSessionModal(false)
      Authorization.logout()
      setLoggedIn(false)
    }
  }

  if (Authorization.storeIdCookie() && loggedIn) {
    return (
      <div className="mx-2">
        {showSessionModal && <SessionAboutToExpireModal closeModal={() => setShowSessionModal(false)} setLoggedIn={setLoggedIn}/>}
        <Header isProcessingShipment={processingShipment} setLoggedIn={setLoggedIn} />
        {!processingShipment && <Nav />}
        <div className="mx-16">
          <Outlet context={{ errorHandler }} />
        </div>
      </div>
    )
  } else if (!Authorization.storeIdCookie() && loggedIn) {
    return (
      <Navigate to="/choose_store" replace={true} />
    )
  } else {
    return (
      <Navigate to="/login" replace={true} />
    )
  }
}

export default Layout
