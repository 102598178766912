import ApiHelper from '../helpers/api-helper'
import Cookies from 'js-cookie'

class ReservationsApi {
  static fetchIndex (params = {}) {
    console.log('fetchIndex')

    const searchQuery = ReservationsApi.buildIndexSearchQuery(params)

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations?${searchQuery}`, {
      headers: ApiHelper.getHeaders()
    })
  }

  static fetchReservation (id, queryString = '') {
    console.log('fetchReservation', id, queryString)

    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) queryString += `${queryString ? '&' : ''}[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/${id}?${queryString}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static fetchReservationCounts () {
    console.log('fetchReservationCounts')
    let queryParams = ''
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) queryParams += `[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/reservation_counts?${queryParams}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static createReservation (params) {
    console.log('createReservation', params)

    const skuQuantities = params.productDescriptors.map(p => {
      return {
        sku: p.sku,
        quantity: p.quantity
      }
    })
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        event_type: params.eventType,
        event_date: params.eventDate,
        pickup_date: params.pickupDate,
        skus_quantities: skuQuantities,
        event_rental_store_id: eventRentalStoreIdCookie ?? ''
      })
    })
  }

  static createReservationContact (params) {
    console.log('createReservationContact', params)
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/${params.reservationShipmentId}/contacts`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        contact: {
          first_name: params.firstName,
          last_name: params.lastName,
          email: params.email,
          street_address_1: params.streetAddress1,
          street_address_2: params.streetAddress2,
          city: params.city,
          state: params.state,
          zip: params.zip,
          phone: params.phone
        },
        event_rental_store_id: eventRentalStoreIdCookie ?? ''
      })
    })
  }

  static createReservationRecurlyContact (params) {
    console.log('createReservationRecurlyContact', params)

    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/${params.reservationShipmentId}/create_recurly_contact`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        recurly_token: params.recurlyToken,
        event_rental_store_id: eventRentalStoreIdCookie ?? ''
      })
    })
  }

  static addPickupPersonToReservation (params) {
    console.log('addPickupPersonToReservation', params)

    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts/pickup_contacts`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        contact: {
          first_name: params.firstName,
          last_name: params.lastName,
          email: params.email,
          street_address_1: params.streetAddress1,
          street_address_2: params.streetAddress2,
          city: params.city,
          state: params.state,
          zip: params.zip,
          phone: params.phone
        },
        reservation_detail_id: params.reservationDetailId,
        event_rental_store_id: eventRentalStoreIdCookie ?? ''
      })
    })
  }

  static removePickupPersonFromReservation (params) {
    console.log('removePickupPersonFromReservation', params)

    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts/pickup_contacts/${params.id}`, {
      method: 'DELETE',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        reservation_detail_id: params.reservationDetailId,
        event_rental_store_id: eventRentalStoreIdCookie ?? ''
      })
    })
  }

  static editPickupPersonFromReservation (params) {
    console.log('editPickupPersonFromReservation', params)
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/contacts/pickup_contacts/${params.id}`, {
      method: 'PUT',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        reservation_detail_id: params.reservationDetailId,
        event_rental_store_id: eventRentalStoreIdCookie ?? '',
        contact: {
          first_name: params.firstName,
          last_name: params.lastName,
          email: params.email,
          street_address_1: params.streetAddress1,
          street_address_2: params.streetAddress2,
          city: params.city,
          state: params.state,
          zip: params.zip,
          phone: params.phone
        }
      })
    })
  }

  static customerConfirmation (params) {
    console.log('customerConfirmation', params)
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/${params.reservationShipmentId}/contact_confirmation_agreement`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        communication_ops: {
          transactional_communications: params.transactionalCommunications,
          marketing_communications: params.marketingCommunications
        },
        event_rental_store_id: eventRentalStoreIdCookie ?? ''
      })
    })
  }

  static confirmReservation (params) {
    console.log('confirmReservation', params)

    let queryParams = ''
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) queryParams += `[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/${params.reservationId}/checkout${queryParams ? `?${queryParams}` : ''}`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        promo_code: params.promoCode
      })
    })
  }

  static fetchOutboundShipments () {
    console.log('fetchOutboundShipments')
    let queryParams = ''
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) queryParams += `[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/outbound_shipments?${queryParams}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static fetchInboundReservations () {
    console.log('fetchInbound')
    let queryParams = ''
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) queryParams += `[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/inbound_shipments?${queryParams}`, {
      headers: ApiHelper.getHeaders()
    })
  }

  static fetchPastDueReservations (params) {
    console.log('fetchPastDueReservations')

    const searchQuery = ReservationsApi.buildIndexSearchQuery(params)

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations?past_due=true&${searchQuery}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static cancelReservation (reservationId) {
    console.log('cancelReservation', reservationId)
    let params = ''
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) params += `/?[event_rental_store_id]=${eventRentalStoreIdCookie}`
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/${reservationId}/cancel${params}`, {
      method: 'PATCH',
      headers: ApiHelper.getHeaders()
    })
  }

  static editDates (params) {
    console.log('editDates', params)
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/reservations/${params.reservationId}/edit_dates`, {
      method: 'PATCH',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        id: params.reservationId,
        pickup_date: params.pickupDate,
        event_rental_store_id: eventRentalStoreIdCookie ?? ''
      })
    })
  }

  static buildIndexSearchQuery (params) {
    let searchQuery = ''
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) searchQuery += `[event_rental_store_id]=${eventRentalStoreIdCookie}&`

    if (params.reservation_statuses_filter) {
      searchQuery += ApiHelper.parameratizeArrayForRails('reservation_statuses_filter', params.reservation_statuses_filter)
    }

    if (params.sort) {
      searchQuery += `&sort=${params.sort}`
    }

    if (params.order) {
      searchQuery += `&order=${params.order}`
    }

    return searchQuery
  }
}

export default ReservationsApi
