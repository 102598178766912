import ErrorHelper from './error-helper'
import ReservationsApi from '../api/ReservationsApi'

export const removePickupPerson = (reservationInfo, setError, setReservationInfo, personId) => {
  const params = { id: personId, reservationDetailId: reservationInfo.reservationShipment.reservationDetail.id }
  ReservationsApi.removePickupPersonFromReservation(params)
    .then(res => res.json())
    .then(json => {
      if (json.error_message) {
        ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document.querySelector('div'))
      } else {
        const updatedReservationShipment = Object.assign({}, reservationInfo)
        const updatedContacts = updatedReservationShipment.reservationShipment.reservationDetail.pickupContacts.filter(person => person.id !== personId)
        updatedReservationShipment.reservationShipment.reservationDetail.pickupContacts = updatedContacts
        setReservationInfo(updatedReservationShipment)
      }
    })
    .catch(() => {
      ErrorHelper.addError({ bannerMessage: 'There was an error removing alternate assignment' }, setError, document.querySelector('div'))
    })
}
