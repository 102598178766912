import ApiHelper from '../helpers/api-helper'

class PromotionApi {
  static validatePromotion (contactId, promoCode, appliedTo) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/guest/validate`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        validate: {
          applied_to: appliedTo,
          contact_id: contactId,
          promo_code: promoCode
        }
      })
    })
  }
}

export default PromotionApi
