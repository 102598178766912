import React from 'react'
import PropTypes from 'prop-types'

const ReservationHistoryTable = ({ reservations }) => {
  return (
    <table className="table-auto w-full text-md">
      <thead>
        <tr className="text-left">
          <th className="border-y p-2 px-4">ID</th>
          <th className="border-y p-2 px-4">Created</th>
          <th className="border-y p-2 px-4">Status</th>
        </tr>
      </thead>
      <tbody>
        {
        reservations.map((res) => (
          <tr className="text-left" key={res.id}>
            <td className="border-y p-2 text-indigo-600 px-4">
              <a href={`/reservations/${res.id}`}>{res.id}</a>
            </td>
            <td className="border-y p-2 px-4">{new Date(res.created).toDateString()}</td>
            <td className="border-y p-2 px-4">{res.statusName}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

ReservationHistoryTable.propTypes = {
  reservations: PropTypes.array.isRequired
}

export default ReservationHistoryTable
