import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AuthenticationApi from "../../../api/AuthenticationApi";
import LoadingSpinner from "../../shared/LoadingSpinner";
import EmailField from "../shared/EmailField";
import PasswordField from "../shared/PasswordField";

const PasswordReset = () => {
  const navigate = useNavigate()
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const [email, setEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const { errorHandler } = useOutletContext()

  const resetPassword = () => {
    setLoading(true)
    AuthenticationApi.resetPassword(email, token, newPassword, confirmPassword)
      .then(res =>  {
          if (res.status !== 200) {
            res.json().then(json => {
              setLoading(false)
              errorHandler({ bannerMessage: json.message }, document)
            })
          } else {
            navigate("/login?infoBanner=Password reset successfully. Please login with your new password.")
          }
        }
      )
      .catch(() => {
        setLoading(false)
        errorHandler({ bannerMessage: 'Something went wrong. Please try again.' }, document)
      })
  }

  return (
    <div>
      <EmailField email={email} setEmail={setEmail} />
      <PasswordField name='New Password' value={newPassword} setValue={setNewPassword}/>
      <PasswordField name='Confirm Password' value={confirmPassword} setValue={setConfirmPassword}/>
      <div className='p-4 flex w-full'>
        <button type='button' className='border rounded py-4 w-32' onClick={() => navigate('/login')}>Cancel</button>
        <button
          disabled={loading}
          type='button'
          className='w-52 ml-auto py-4 bg-indigo-500 text-white rounded disabled:bg-gray-300'
          onClick={resetPassword}
        >
            {loading ? <LoadingSpinner/> : 'Reset Password'}
        </button>
      </div>
    </div>
  )
}

export default PasswordReset
