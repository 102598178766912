import React, { useEffect, useState } from 'react'
import { useSortableTable } from "./useSortableTable";
import TableHead from "./TableHead";
import TableBody from "./TableBody";

const SortableTable = ({ data, columns }) => {
  const [tableData, handleSorting] = useSortableTable(data);

  return (
    <>
      <table className="table-auto w-full text-md">
        <TableHead {...{ columns, handleSorting }} />
        <TableBody {...{ columns, tableData }} />
      </table>
    </>
  );
};

export default SortableTable
