import React, { useState } from 'react'
import TrackingNumberModal from '../modals/TrackingNumberModal'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from '../shared/ErrorPopup'
import CancelLink from '../process_shipments/CancelLink'

const ScanShipment = () => {
  const [showTrackingNumberModal, setShowTrackingNumberModal] = useState(false)
  const [error, setError] = useState({})

  const addError = (errorMessage) => {
    ErrorHelper.addError({ bannerMessage: errorMessage }, setError, document.querySelector('.container'))
  }

  const handleToggleTrackingNumberModal = () => {
    setShowTrackingNumberModal(showTrackingNumberModal => !showTrackingNumberModal)
  }

  return (
    <div className="container mx-auto">
      {error && <ErrorPopup error={error}/>}
      <div className="text-center">
        <h2 className="font-semibold text-lg mt-4">
          Get started by scanning the package label.
        </h2>
        <button
          className="text-indigo-600 background-transparent underline"
          onClick={handleToggleTrackingNumberModal}
        >
          Type in tracking number or reservation ID
        </button>
        <div className="my-8">
          <CancelLink />
        </div>
      </div>
      <TrackingNumberModal
        isVisible={showTrackingNumberModal}
        onClose={handleToggleTrackingNumberModal}
        addError={addError}
      />
    </div>
  )
}

export default ScanShipment
