import InventoryFilter from './InventoryFilter'
import PropTypes from 'prop-types'
import React from 'react'

export default class Actions extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      displayFilter: false,
      displaySearch: false,
      searchQuery: ''
    }

    this.handleCloseFilter = this.handleCloseFilter.bind(this)
  }

  handleSearchQuery = (event) => {
    this.setState({ searchQuery: event.target.value })
  }

  handleClick = (event) => {
    const modal = event.target.value
    const {
      displayFilter,
      displaySearch
    } = this.state

    if (modal === 'filter') {
      this.setState({ displayFilter: !displayFilter })
    } else {
      this.setState({ displaySearch: !displaySearch })
    }
  }

  handleSearchSubmitAndClose = (event) => {
    const { handleSearchSubmit } = this.props

    event.preventDefault()

    handleSearchSubmit(this.state.searchQuery)
    this.setState({ displaySearch: false })
  }

  handleCloseFilter = () => {
    this.setState({ displayFilter: false })
  }

  render () {
    const { displayFilter, displaySearch } = this.state
    const { checkedStatuses, diamondClarities, diamondColors, designers, kinds, metals, queryInventory } = this.props
    return (
      <div className="flex justify-end gap-4">
        <div className="flex">
          {
            displayFilter &&
            <div id='display-filter-modal' className='fixed flex inset-0 backdrop-blur-sm bg-black/60 transition-opacity overflow-x-hidden overflow-y-auto z-10'>
              <div className='bg-white p-8 inset-0 text-left rounded-md sm:w-[50%] lg:w-[30%] my-auto mx-auto py-10'>
                <InventoryFilter
                  handleCloseFilter={this.handleCloseFilter}
                  queryInventory={queryInventory}
                  designers={designers}
                  kinds={kinds}
                  metals={metals}
                  diamondClarities={diamondClarities}
                  diamondColors={diamondColors}
                  checkedStatuses={checkedStatuses}
                />
              </div>
            </div>
          }
          <button
            className="bg-indigo-500 text-white text-sm px-6 py-2 rounded my-2"
            onClick={this.handleClick}
            type="button"
            value="filter"
          >
            Filter
          </button>
        </div>

        <div className="flex">
          {
            displaySearch &&
            <div id='sku-search-modal' className='fixed flex inset-0 backdrop-blur-sm bg-black/60 transition-opacity overflow-x-hidden overflow-y-auto z-10'>
              <div className='bg-white p-8 inset-0 text-left rounded-md sm:w-[50%] lg:w-[30%] my-auto mx-auto py-10'>
              <form onSubmit={this.handleSearchSubmitAndClose} >
                <div className="flex justify-end ">
                  <button
                    className="font-bold text-sm mb-2"
                    onClick={this.handleClick}
                    type="button"
                    value="search"
                  >
                    X
                  </button>
                </div>
                <div className='grid grid-cols-1 place-items-center'>
                  <div className="font-bold text-base mb-2">
                    SKU Search
                  </div>
                  <input
                    className="mt-2 pl-4 block w-full rounded-md border-[1px] border-gray-300 drop-shadow-md focus:border-indigo-600 focus:ring-indigo-500 h-12 md:text-md"
                    name="search[sku]"
                    type="text"
                    onChange={this.handleSearchQuery}
                  />
                  <br />
                  <button
                    className="mt-16 bg-indigo-500 text-white text-sm px-6 py-2 rounded m-auto"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </form>
              </div>
            </div>
          }
          <button
            className="bg-indigo-500 text-white text-sm px-6 py-2 rounded my-2"
            onClick={this.handleClick}
            type="button"
            value="search"
          >
            Search
          </button>
        </div>
      </div>
    )
  }
}

Actions.propTypes = {
  checkedStatuses: PropTypes.object.isRequired,
  designers: PropTypes.object,
  diamondClarities: PropTypes.object,
  diamondColors: PropTypes.object,
  handleSearchSubmit: PropTypes.func.isRequired,
  metals: PropTypes.object,
  kinds: PropTypes.object,
  queryInventory: PropTypes.func.isRequired
}
