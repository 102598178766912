import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import camelize from 'camelize'

import ReservationCustomerHeader from '../common/ReservationCustomerHeader'
import SingleProduct from '../common/SingleProduct'
import ProcessShipmentApi from '../../api/ProcessShipmentApi'
import { isReceiving } from '../../helpers/process-shipments'
import LoadingSpinner from '../shared/LoadingSpinner'
import { ProcessShipmentContext } from '../../contexts/ProcessShipmentContext'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from '../shared/ErrorPopup'
import StartOverButton from './StartOverButton'

const ConfirmShipment = () => {
  const [reservationShipment, setReservationShipment] = useState(null)
  const [presentReservations, setPresentReservations] = useState([])
  const [statuses, setStatuses] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const { productStatuses } = useContext(ProcessShipmentContext)

  const reservationShipmentId = parseInt(useParams().id)
  const navigate = useNavigate()

  const handleSuccessResponse = (response) => {
    const { errorMessage } = response

    if (errorMessage) {
      console.log('Error: ', errorMessage)
    } else {
      const {
        missingStatuses,
        presentStatuses,
        reservationShipment
      } = response

      setReservationShipment(reservationShipment)
      setPresentReservations(reservationShipment.presentReservations)
      setStatuses({ ...missingStatuses, ...presentStatuses })
    }
  }

  useEffect(() => {
    if (isReceiving()) {
      ProcessShipmentApi.fetchReceiveReservationShipment(reservationShipmentId)
        .then(resp => resp.json())
        .then(json => {
          handleSuccessResponse(camelize(json))
        })
    } else {
      ProcessShipmentApi.fetchReturnReservationShipment(reservationShipmentId)
        .then(resp => resp.json())
        .then(json => {
          handleSuccessResponse(camelize(json))
        })
    }
  }, [])

  if (reservationShipment === null) return null

  const { contact } = reservationShipment

  const updateProductsAndShipment = (navigateTo) => {
    ProcessShipmentApi.updateProductsAndShipment(reservationShipmentId, productStatuses)
      .then(resp => resp.json())
      .then(json => {
        const response = camelize(json)
        const { errorMessage } = response

        if (errorMessage) {
          ErrorHelper.addError(
            { bannerMessage: errorMessage },
            setError,
            document.querySelector('.container')
          )
        } else {
          confirm('Product statuses have been saved successfully!')
          navigate(navigateTo)
        }
      })
  }

  // TODO: clean this up - for now helpful to see each possible option
  const handleMarkComplete = () => {
    setLoading(false)

    const nextReservationStatusName = reservationShipment.nextStatusName
    // API call will include:
    // products: productStatuses,
    // reservation_shipment_id: reservationShipmentId

    // warehouse-to-store
    // in_store_outbound
    // Warehouse sending to store
    if (nextReservationStatusName === 'in_store_outbound') {
      // Make API call here to update shipment and product statuses
      updateProductsAndShipment('/inbound_shipments')
      return
    }

    // store-to-customer
    // with_customer
    // Customer pickup from store
    if (nextReservationStatusName === 'with_customer') {
      // navigate to /pickup_person page
      return navigate(`/outbound_shipments/${reservationShipment.id}/pickup_person`)
    }

    // customer-to-store
    // in_store_inbound
    // Customer is returning to store
    // Products to be purchased include product statuses -> damaged_irreparable, lost, purchased
    if (nextReservationStatusName === 'in_store_inbound') {
      // navigate to /return_person page
      return navigate(`/inbound_shipments/${reservationShipment.id}/return_person`)
    }

    // store-to-warehouse
    // in_transit_from_store
    // Store sending to warehouse
    if (nextReservationStatusName === 'in_transit_from_store') {
      // Make API call here to update shipment and product statuses
      updateProductsAndShipment('/outbound_shipments')
    }
  }

  const statusName = (productId) => {
    const productStatus = productStatuses.find(
      status => status.productId === productId
    )

    if (productStatus) {
      const { desiredStatus } = productStatus
      return Object.keys(statuses).find(key => parseInt(statuses[key]) === desiredStatus)
    }

    return null
  }

  return (
    <div className="container mx-auto">
      {error && <ErrorPopup error={error}/>}
      <ReservationCustomerHeader
        contact={contact}
        reservationShipmentId={reservationShipmentId}
        qcStep={reservationShipment.statusName}
      />
      <div className="grid grid-cols-3 gap-4 text-center">
        {presentReservations.map((reservation) => {
          const { product } = reservation

          return (
            <div
              key={`product-${product.id}}`}
              className="grid auto-rows-auto"
            >
              <SingleProduct
                finishedCheckIn
                reservation={reservation}
                statusName={statusName(product.id)}
              />
            </div>
          )
        })}
      </div>
      <div className="flex flex-row justify-center mt-8 gap-6 pb-6">
        <StartOverButton reservationShipmentId={reservationShipmentId} />
        <button
          className="bg-indigo-500 rounded text-white p-2 w-[250px]"
          onClick={handleMarkComplete}
        >
          {loading ? <LoadingSpinner /> : 'Confirm product statuses'}
        </button>
      </div>
    </div>
  )
}

export default ConfirmShipment
