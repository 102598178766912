import camelize from 'camelize'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ReservationsApi from '../../../api/ReservationsApi'
import ErrorHelper from '../../../helpers/error-helper'
import ErrorPopup from '../../shared/ErrorPopup'
import LoadingSpinner from '../../shared/LoadingSpinner'
import ReservationOrderDetails from '../../shared/ReservationOrderDetails'
import CancelReservationCreation from '../CancelReservationCreation'
import NewReservationHeader from './NewReservationHeader'

const ReservationConfirmation = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { state: { promotion } } = useLocation()
  const [reservationInfo, setReservationInfo] = useState(null)
  const [error, setError] = useState(null)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    if (!reservationInfo) {
      const id = params.id

      ReservationsApi.fetchReservation(id, 'include_deposit_tax_info=true&include_pickup_fee_tax_info=true')
        .then(res => res.json())
        .then(json => {
          if (json.error_message) {
            ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document.querySelector('div'))
          } else {
            setReservationInfo(camelize(json))
          }
        })
        .catch(() => {
          ErrorHelper.addError({ bannerMessage: 'Error fetching Reservation' }, setError, document.querySelector('div'))
        })
    }
  }, [])

  const confirmReservation = () => {
    setLoadingSubmit(true)
    const params = {
      promoCode: promotion?.code,
      reservationId: reservationInfo.reservationShipment.id
    }

    ReservationsApi.confirmReservation(params)
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          navigate(`/reservations/${json.id}#reservation_confirmed`)
        } else {
          setLoadingSubmit(false)
          ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document)
        }
      })
      .catch(() => {
        setLoadingSubmit(false)
        ErrorHelper.addError({ bannerMessage: 'There was an error confirming the reservation' }, setError, document)
      })
  }

  const renderButtonSection = () => {
    return (
      <div className='mt-4 flex h-11 w-max gap-x-8'>
        <CancelReservationCreation reservationShipment={reservationInfo.reservationShipment} />
        <button
          className='flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-neutral-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none h-10 confirm-agreement'
          id="agree-and-continue-button"
          onClick={confirmReservation}
        > {
            loadingSubmit ? <LoadingSpinner /> : 'Process fee & confirm reservation →'
          }
        </button>
      </div>
    )
  }

  const reservationDetailAdditionalFields = () => {
    return (
      <div className='border-b py-4 px-8 grid grid-cols-3 pb-4'>
        {renderButtonSection()}
      </div>
    )
  }

  return (
    <div className='-mx-16 pt-24'>
      <NewReservationHeader step={3} />
      <div className='mt-16'>
        {error && <ErrorPopup error={error} />}
        {reservationInfo &&
          <div>
            <ReservationOrderDetails
              promotion={promotion}
              reservationInfo={reservationInfo}
              additionalFields={reservationDetailAdditionalFields}
            />
          </div>
        }
      </div>
    </div>
  )
}

export default ReservationConfirmation
