class ReservationsHelper {
  // returns a hash of unique product descriptors from a list of reservations
  // useful for quick lookups for pd information
  static hashedPdsByReservations (reservations) {
    const pds = {}
    reservations.forEach(res => {
      const { product } = res
      pds[product.productDescriptor.sku] = product.productDescriptor
    })
    return pds
  }

  // Returns an array hash of unique reservations by sku adding a reservation
  // count attribute to the product descriptor.
  static uniqReservationsBySkus (reservations) {
    const uniqueReservations = new Map()
    for (const reservation of reservations) {
      const { sku } = reservation.product.productDescriptor
      if (uniqueReservations.has(sku)) {
        uniqueReservations.get(sku).product.productDescriptor.reservationCount += 1
      } else {
        reservation.product.productDescriptor.reservationCount = 1
        uniqueReservations.set(sku, reservation)
      }
    }
    return [...uniqueReservations.values()]
  }

  static getPromotionDiscount = (promotion, amountBeforeTax) => {
    let discountAmount = 0
    if (!promotion) return discountAmount
    if (promotion.kind === 'dollars_off') {
      discountAmount = promotion.amount
    } else {
      discountAmount = (amountBeforeTax * promotion.amount) / 100
    }
    return discountAmount
  }
}

export default ReservationsHelper
