import React from 'react'
import PropTypes from 'prop-types'

const ErrorPopup = ({ error, className = undefined }) => {
  const renderBanner = () => {
    return (
      <div className={className ?? 'bg-red-300 p-2 my-4 rounded text-center'}>
        {error.bannerMessage}
      </div>
    )
  }

  return (
    <div>
      {error.bannerMessage && renderBanner()}
    </div>
  )
}

ErrorPopup.propTypes = {
  error: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default ErrorPopup
