import React from 'react'
import PropTypes from 'prop-types'
import DatesHelper from '../../../helpers/dates-helper'

const ReservationHistory = ({ changeHistories }) => {
  return (
    <table className="table-auto w-full text-md">
      <thead className="bg-[#FBFAFC]">
      <tr className="text-left">
        <th className="border-y p-2">ID</th>
        <th className="border-y p-2">Created</th>
        <th className="border-y p-2">Status</th>
        <th className="border-y p-2">Admin</th>
      </tr>
      </thead>
      <tbody>
      {
        changeHistories.map((changeHistory) => (
          <tr className="text-left" key={changeHistory.id}>
            <td className="border-y p-2">{changeHistory.reservationShipmentId}</td>
            <td className="border-y p-2">{DatesHelper.formatDateWithMonthAbbr(changeHistory.createdAt)}</td>
            <td className="border-y p-2">{changeHistory.statusName}</td>
            <td className="border-y p-2">{changeHistory.administratorName}</td>
          </tr>
        ))
      }
      </tbody>
    </table>
  )
}

ReservationHistory.propTypes = {
  changeHistories: PropTypes.array.isRequired
}

export default ReservationHistory
