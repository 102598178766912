import React, { useEffect, useState } from 'react'
import { RecurlyProvider, Elements } from '@recurly/react-recurly'
import PropTypes from 'prop-types'
import ErrorHelper from '../../../helpers/error-helper'
import RecurlyForm from '../../shared/RecurlyForm'
import { handleRecurlySuccess } from '../../../helpers/recurly-helper'
import LoadingSpinner from '../../shared/LoadingSpinner'

const CreditCardInformationForm = ({ reservationInfo, setError, isCustomerFormSubmitted, isCreditCardFormSubmitted, setIsCreditCardFormSubmitted }) => {
  const [contact, setContact] = useState(null)
  const [billingInfo, setBillingInfo] = useState(null)
  const [createRecurlyIsLoading, setCreateRecurlyIsLoading] = useState(false)

  useEffect(() => {
    if (isCustomerFormSubmitted) {
      setContact(reservationInfo.reservationShipment.contact)
    }
  }, [isCustomerFormSubmitted, reservationInfo?.reservationShipment?.contact])

  useEffect(() => {
    if (isCreditCardFormSubmitted) {
      setBillingInfo(reservationInfo.reservationShipment.contact.billingInfo)
    }
  }, [isCustomerFormSubmitted, reservationInfo?.reservationShipment?.contact?.billingInfo])

  const handleRecurlyTokenSuccess = (recurlyToken, setIsFormSubmitted) => {
    const params = { recurlyToken, reservationShipmentId: reservationInfo.reservationShipment.id }
    const onSuccess = () => {
      setIsCreditCardFormSubmitted(true)
      setIsFormSubmitted(true)
    }

    handleRecurlySuccess(params, setError, setCreateRecurlyIsLoading, onSuccess)
  }

  const renderButtonSection = () => (
    <div className='pt-7'>
      <button
        className='inline-flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 h-10'
        type='submit'
      >
        {createRecurlyIsLoading ? <LoadingSpinner /> : 'Continue →'}
      </button>
    </div>
  )

  const renderCreditCardInfo = () => {
    return (
      <div className='flex pl-8 pb-7 pr-8'>
        <div>{billingInfo.cardType}</div>
        <div className='pl-3'>
          <div className="text-gray-400 text-xs">Card Number</div>
          <div>**** **** **** {billingInfo.lastFour}</div>
        </div>
      </div>
    )
  }

  return (
    <div className='md:col-span-4 bg-[#FBFAFC] border-t-2 border-gray-200 outline-none'>
      <div className='px-8 pt-6'>
        <h3 className='text-md font-medium leading-6 text-gray-900 pb-7'>Credit Card Information</h3>
      </div>
      {!isCreditCardFormSubmitted && contact && <div className='grid grid-cols-4 gap-4'>
        <div className='col-span-4 pl-8 pb-7 pr-8'>
          <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY} required={['cvv']}>
            <Elements>
              <RecurlyForm
                contact={contact}
                handleRecurlyTokenSuccess={handleRecurlyTokenSuccess}
                addError={(error) => ErrorHelper.addError(error, setError, document.querySelector('form'))}
                renderButtonSection={renderButtonSection}
                setIsLoading={setCreateRecurlyIsLoading}
              />
            </Elements>
          </RecurlyProvider>
        </div>
      </div>}
      {billingInfo && renderCreditCardInfo()}
    </div>
  )
}

CreditCardInformationForm.propTypes = {
  reservationInfo: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  isCustomerFormSubmitted: PropTypes.bool.isRequired,
  isCreditCardFormSubmitted: PropTypes.bool.isRequired,
  setIsCreditCardFormSubmitted: PropTypes.func.isRequired
}

export default CreditCardInformationForm
