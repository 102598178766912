import React from 'react'
import PropTypes from 'prop-types'
import PickupPersonRow from './PickUpPersonRow'
import DropdownDots from '../../../shared/DropdownDots'

const PickupPersonList = ({ pickupContacts, handleRemovePickupPerson, handleEditPickupPerson }) => {
  if (!pickupContacts) return

  const getDropDownOptions = (pickupPersonId) => {
    const options = [{
      label: 'Remove',
      value: '',
      handleClick: () => handleRemovePickupPerson(pickupPersonId)
    },
    {
      label: 'Edit',
      value: '',
      handleClick: () => handleEditPickupPerson(pickupPersonId)
    }]
    return options
  }

  return (
    <div id='pickup-person-list' className='pt-7'>
      {pickupContacts.map((pickupPerson, index) =>
        <div className='flex content-center items-center bg-white border-t-2 border-gray-200 first:border-t-0 last:mb-7 h-12' key={index}>
          <PickupPersonRow
            key={pickupPerson.id}
            pickupPerson={pickupPerson}
            rowNumber={index + 1}
          />
          <DropdownDots className={'flex w-[10%] pr-7'} options={getDropDownOptions(pickupPerson.id)} />
        </div>
      )}
    </div>
  )
}

PickupPersonList.propTypes = {
  handleEditPickupPerson: PropTypes.func.isRequired,
  handleRemovePickupPerson: PropTypes.func.isRequired,
  pickupContacts: PropTypes.array.isRequired
}

export default PickupPersonList
