import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AuthenticationApi from "../../../api/AuthenticationApi";
import LoadingSpinner from "../../shared/LoadingSpinner";
import EmailField from "../shared/EmailField";

const RequestPasswordReset = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)

  const { errorHandler } = useOutletContext()
  const navigate = useNavigate()

  const requestReset = () => {
    setLoading(true)
    AuthenticationApi.requestResetPassword(email)
      .then(res =>  {
        if (res.status !== 200) {
          res.json().then(json => {
            setLoading(false)
            errorHandler({ bannerMessage: json.message })
          })
        } else {
          navigate('/login?infoBanner=Succesfully requested password reset. Please check your email for further instructions.')
        }
      }
    )
    .catch(() => {
      setLoading(false)
      errorHandler({ bannerMessage: 'Something went wrong. Please try again.' })
    })
  }

  return (
    <div>
      <h2 className="text-2xl p-4">Please provide your Signet email in order to reset your password.</h2>
      <EmailField email={email} setEmail={setEmail} />
      <div className='p-4 flex w-full'>
        <button type='button' className='border rounded py-4 w-32' onClick={() => navigate('/login')}>Back</button>
        <button
          disabled={loading}
          type='button'
          className='w-52 ml-auto py-4 bg-indigo-500 text-white rounded disabled:bg-gray-300'
          onClick={requestReset}
        >
            {loading ? <LoadingSpinner/> : 'Reset Password'}
        </button>
      </div>
    </div>
  )
}

export default RequestPasswordReset
