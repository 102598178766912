import React, { useState } from 'react'
import PropTypes from 'prop-types'

const CheckInRecoveredItem = ({ item, handleSelection }) => {
  /* eslint-disable no-unused-vars */
  const [isSelected, setIsSelected] = useState(false)

  const handleChange = (event) => {
    setIsSelected(event.target.checked)
    handleSelection(item.id, event.target.checked)
  }

  return (
    <div className="bg-white rounded-md shadow px-8">
      <div className="flex gap-10 items-stretch justify-between">
        <div className="flex">
          <label htmlFor={item.id} className="flex items-center justifiy-center p-8">
            <input type="checkbox" name={item.id} id={item.id} data-testid={item.id} onChange={handleChange} />
          </label>

          <div className="flex items-center w-32">
            <img
              className="w-32"
              src={item.product_descriptor.image_url}
            />
          </div>
        </div>

        <div className="flex flex-col items-start justify-center text-left w-48">
          <p>Rocksbox SKU</p>
          <p>{item.product_descriptor.sku}</p>
        </div>

        <div className="flex flex-col items-start justify-center text-left w-48">
          <p>Zales SKU</p>
          <p>{item.product_descriptor.designer_sku}</p>
        </div>
      </div>
    </div>
  )
}

CheckInRecoveredItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleSelection: PropTypes.func.isRequired
}

export default CheckInRecoveredItem
