class ApiHelper {
  static getHeaders () {
    return { 'Content-Type': 'application/json', Authorization: `bearer ${localStorage.jwtToken}` }
  }

  static getStoreAssociateEmail = () => {
    return JSON.parse(localStorage.getItem('associateEmail'))
  }

  static getStoreId = () => {
    return JSON.parse(localStorage.getItem('eventRentalStore'))?.id
  }

  static parameratizeArrayForRails (name, array) {
    return array.map(value => {
      return `${name}[]=${value}&`
    }).join('')
  }

  static nestedParameratizeArrayForRails (parent, name, array) {
    return array.map(value => {
      return `${parent}[${name}][]=${value}&`
    }).join('')
  }

  static nestedParameratizeObjectForRails (parent, name, object) {
    return Object.keys(object).map(key => {
      return `${parent}[${name}_${key}=${object[key]}&`
    }).join('')
  }
}

export default ApiHelper
