import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as DropdownIcon } from '../../assets/threedot-dropdown.svg'
import useComponentVisible from './hooks/useComponentVisible'

const DropdownDots = ({ options, className }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  return (
    <div id="relative dropdown" className={className} ref={ref}>
      <button
        className='divide-y divide-gray-100 rounded-lg w-6'
        id='dropdown-button'
        onClick={() => setIsComponentVisible(true)}>
        <DropdownIcon />
      </button>
      <div className='absolute right-[2vw] lg:right-[5vw] z-10 mt-6 w-[147px] rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none'>
        <div>
          {isComponentVisible && options.map((option, index) => (
            <div
              className='text-gray-700 px-4 py-2 text-sm cursor-pointer hover:border hover:border-indigo-600'
              id="dropdown-option"
              key={index}
              value={option.value}
              onClick={() => {
                option.handleClick()
                setIsComponentVisible(false)
              }}>
              {option.label}
            </div>)
          )}
        </div>
      </div>
    </div>
  )
}

DropdownDots.propTypes = {
  options: PropTypes.array.isRequired,
  className: PropTypes.string
}

export default DropdownDots
