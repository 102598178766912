import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ShowPasswordIcon } from '../../../assets/open-eye.svg'
import { ReactComponent as HidePasswordIcon } from '../../../assets/closed-eye.svg'

const PasswordField = ({ name, value, setValue }) => {
  const [showValue, setShowValue] = useState(false)

  return (
    <div className='p-4'>
      <label className='text-gray-400 relative'>
        {name}
        <br />
        <input
          className='text-black w-full py-2 border-b-2 focus:border-indigo-300 focus:outline-none'
          type={showValue ? 'text' : 'password'}
          value={value}
          onChange={e => setValue(e.target.value)}
          aria-label="Password"
        />
        <div className='absolute right-0 -bottom-2 p-2' onClick={() => setShowValue(prev => !prev)}>
          {showValue ? <HidePasswordIcon /> : <ShowPasswordIcon />}
        </div>
      </label>
    </div>
  )
}

export default PasswordField

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
}
