import React from 'react'
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import Authorization from '../../../helpers/authorization';

// This component is used to consume the JWT from the url params
// When it registers a jwt, it logs the associate in and closes the window
// If there is an error, it displays the error message

const TokenConsumption = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const jwt = params.get('jwt');

  useEffect(() => {
    if (jwt) {
      Authorization.setLogin(jwt)
      window.close()
    }
  }, [jwt])

  return (
    <div></div>
  )
}

export default TokenConsumption