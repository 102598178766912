import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import ConfirmShipment from './ConfirmShipment'
import PickupOrReturnPerson from './PickupOrReturnPerson'
import ProcessShipment from './ProcessShipment'
import ProductConfirmation from './ProductConfirmation'
import PurchaseProducts from './PurchaseProducts'
import ScanShipment from './ScanShipment'
import { ProcessShipmentContext } from '../../contexts/ProcessShipmentContext'

const ProcessInboundShipmentContainer = () => {
  const [productStatuses, setProductStatuses] = useState([])
  const [pickupOrReturnPerson, setPickupOrReturnPerson] = useState(null)

  const addProductStatus = productStatus => {
    setProductStatuses((prevProductStatuses) => {
      const newProductStatuses = prevProductStatuses
      const { productId } = productStatus
      const productIndex = newProductStatuses.findIndex(
        status => status.productId === productId
      )

      if (productIndex >= 0) {
        newProductStatuses[productIndex] = productStatus
      } else {
        newProductStatuses.push(productStatus)
      }

      return newProductStatuses
    })
  }

  return (
    <ProcessShipmentContext.Provider value={{ productStatuses, addProductStatus, pickupOrReturnPerson, setPickupOrReturnPerson }}>
      <Routes>
        <Route path="scan" element={<ScanShipment />} />
        <Route path=":id" element={<ProcessShipment />} />
        <Route path=":id/:reservationId" element={<ProductConfirmation />} />
        <Route path=":id/confirm" element={<ConfirmShipment />} />
        <Route path=":id/return_person" element={<PickupOrReturnPerson />} />
        <Route path=":id/purchase" element={<PurchaseProducts />} />
      </Routes>
    </ProcessShipmentContext.Provider>
  )
}

export default ProcessInboundShipmentContainer
