import React, { useEffect, useState } from 'react'
import ContactsApi from '../../api/ContactsApi'
import ContactSearch from './ContactSearch'
import ContactsTable from './ContactsTable'
import camelize from 'camelize'

const Contacts = () => {
  const [contacts, setContacts] = useState([])
  const [orderSort, setOrderSort] = useState({
    order: 'desc',
    sort: 'id'
  })

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    streetAddress1: '',
    city: '',
    state: '',
    zipCode: '',
    phone: ''
  })

  const handleOrderSort = (sortingColumnName) => {
    setOrderSort(prevOrderSort => {
      const newOrder = prevOrderSort.order === 'desc' && prevOrderSort.sort === sortingColumnName ? 'asc' : 'desc'
      return {
        order: newOrder,
        sort: sortingColumnName
      }
    })
  }

  useEffect(() => {
    searchContacts()
  }, [orderSort])

  // uses input name as a key to set formValues with interpolation
  const handleFormChange = (event) => {
    const key = event.target.name
    const value = event.target.value
    setFormValues(prevForm => ({ ...prevForm, [key]: value }))
  }

  const searchContacts = () => {
    const snakedFormValues = {
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      email: formValues.email,
      street_address_1: formValues.streetAddress1,
      city: formValues.city,
      state: formValues.state,
      zip_code: formValues.zipCode,
      phone: formValues.phone
    }
    const searchParams = Object.keys(snakedFormValues).map((key) => {
      return `${key}=${snakedFormValues[[key]]}&`
    }).join('')

    ContactsApi.fetchIndex(orderSort.order, orderSort.sort, searchParams)
      .then(res => res.json())
      .then(json => {
        setContacts(camelize(json.contacts))
      })
  }

  return (
    <div className="container mx-auto">
        <div className="flex flex-row justify-center gap-4 border-b-2 bg-white pb-6 sticky top-[70px] w-full z-10 pt-1">
          <ContactSearch handleFormChange={handleFormChange} formValues={formValues} handleSearch={searchContacts} setFormValues={setFormValues}/>
        </div>
        <ContactsTable contacts={contacts} handleOrderSort={handleOrderSort} orderSort={orderSort}/>
    </div>
  )
}

export default Contacts
