import React from 'react'
import TermsOfService from '../shared/TermsOfService'
import PropTypes from 'prop-types'

const TermsOfServiceModal = ({ tosToggle }) => {
  return (
    <div className='fixed inset-0 z-10 h-screen w-screen bg-gray-700/50'>
      <div className="bg-white mt-4 p-2 w-max mx-auto mt-8">
        <div className="mx-auto w-max max-h-[80vh] overflow-y-scroll">
          <TermsOfService/>
        </div>
        <div className="mx-auto text-right pt-2">
          <button onClick={tosToggle} className="bg-indigo-500 text-white px-16 py-2 rounded">Close</button>
        </div>
      </div>
    </div>
  )
}

export default TermsOfServiceModal

TermsOfServiceModal.propTypes = {
  tosToggle: PropTypes.func.isRequired
}
