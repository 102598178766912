import React from 'react'
import LoadingSpinner from '../shared/LoadingSpinner'
import PropTypes from 'prop-types'
import BackButton from '../shared/BackButton'

const PurchaseProductsButtons = ({ handlePurchase, loadingSubmit }) => {
  return (
    <div className='bottom-0 py-4 px-8 pt-24'>
      <BackButton />
      <button
        className='text-white bg-indigo-500 disabled:opacity-50 w-64 px-5 py-2.5 rounded w-max ml-auto confirm-agreement'
        onClick={handlePurchase}
        disabled={loadingSubmit}
      >
        {
          loadingSubmit ? <LoadingSpinner/> : 'Confirm purchase & continue'
        }
      </button>
    </div>
  )
}

PurchaseProductsButtons.propTypes = {
  handlePurchase: PropTypes.func.isRequired,
  loadingSubmit: PropTypes.bool.isRequired
}

export default PurchaseProductsButtons
