import PropTypes from 'prop-types'
import React from 'react'

const InventoryMetrics = ({ inStoreCount, inTransitToStoreCount, withCustomersCount }) => {
  return (
    <div className='flex justify-center mb-3 gap-8'>
      <div className="flex flex-col">
        <p className="text-3xl place-self-center" id="in-transit-to-store-count">{inTransitToStoreCount}</p>
        <span className="text-xs">Items in transit to store</span>
      </div>

      <div className="flex flex-col">
        <p className="text-3xl place-self-center" id="in-store-count">{inStoreCount}</p>
        <span className="text-xs">Items in store</span>
      </div>

      <div className="flex flex-col">
        <p className="text-3xl place-self-center" id="with-customers-count">{withCustomersCount}</p>
        <span className="text-xs">Items with customer</span>
      </div>
    </div>
  )
}

export default InventoryMetrics

InventoryMetrics.propTypes = {
  inStoreCount: PropTypes.number.isRequired,
  inTransitToStoreCount: PropTypes.number.isRequired,
  withCustomersCount: PropTypes.number.isRequired
}
