import React from 'react'
import Layout from './components/Layout'
import Inventory from './components/inventory/Inventory'

import LoggedOutLayout from './components/logged_out/LoggedOutLayout'
import Login from './components/logged_out/login/Login'
import RequestPasswordReset from './components/logged_out//password_reset/RequestPasswordReset'
import PasswordReset from './components/logged_out//password_reset/PasswordReset'
import TokenConsumption from './components/logged_out/login/TokenConsumption'

import ErrorBoundary from './components/airbrake/ErrorBoundary'
import PastDue from './components/past_due/PastDue'

// Contacts
import Contacts from './components/contacts/Contacts'
import ShowContact from './components/contacts/ShowContact'

// Inbound Shipments
import InboundShipments from './components/inbound_shipments/InboundShipments'

// Outbound Shipments
import OutboundShipments from './components/outbound_shipments/OutboundShipments'

// Process Shipments (Inbound / Outbound)
import ProcessInboundShipmentContainer from './components/process_shipments/ProcessInboundShipmentContainer'
import ProcessOutboundShipmentContainer from './components/process_shipments/ProcessOutboundShipmentContainer'

// Reservations
import CreateReservationForm from './components/reservations/create_reservation_shipments/CreateReservationForm'
import NewRecurlyContact from './components/reservations/create_reservation_shipments/NewRecurlyContact'
import Reservations from './components/reservations/Reservations'
import ReservationConfirmation from './components/reservations/create_reservation_shipments/ReservationConfirmation'
import ShowReservation from './components/reservations/show_reservations/ShowReservation'
import CustomerConfirmation from './components/reservations/create_reservation_shipments/CustomerConfirmation'
import DisplayedError from './components/shared/DisplayedError'

// Product Descriptors
import ShowProductDescriptor from './components/product_descriptors/ShowProductDescriptor'
import ShippingLabel from './components/reservations/ShippingLabel'
import EditReservationPickup from './components/reservations/EditReservationPickup'
import ChooseStore from './components/logged_out/login/ChooseStore'

// MPC
import MpcMenu from './components/mpc/MpcMenu'
import MpcCount from './components/mpc/MpcCount'
import ReportLost from './components/mpc/ReportLost'
import CheckInRecovered from './components/mpc/CheckInRecovered'

// https://reactrouter.com/en/main/routers/create-browser-router
// wrap parent elements in error boundary to get airbrake errors

const routes = [
  {
    path: '/',
    element:
      <ErrorBoundary>
        <DisplayedError>
          <Layout />
        </DisplayedError>
      </ErrorBoundary>,
    children: [
      {
        path: '/inventory',
        element: <Inventory />
      },
      {
        path: '/inbound_shipments',
        element: <InboundShipments />
      },
      {
        path: '/outbound_shipments',
        element: <OutboundShipments />
      },
      {
        path: '/reservations',
        element: <Reservations />
      },
      {
        path: '/reservations/new',
        element: <Reservations />
      },
      {
        path: '/reservations/:id/new_contact',
        element: <CreateReservationForm />
      },
      {
        path: '/reservations/:id/new_recurly_contact',
        element: <NewRecurlyContact />
      },
      {
        path: '/reservations/:id/customer_confirmation',
        element: <CustomerConfirmation />
      },
      {
        path: '/reservations/:id/confirmation',
        element: <ReservationConfirmation />
      },
      {
        path: '/reservations/:id',
        element: <ShowReservation />
      },
      {
        path: '/reservations/:id/edit_pickup',
        element: <EditReservationPickup />
      },
      {
        path: '/reservations/shipping_label',
        element: <ShippingLabel />
      },
      {
        path: '/contacts',
        element: <Contacts />
      },
      {
        path: '/contacts/:id',
        element: <ShowContact />
      },
      {
        path: '/past_due',
        element: <PastDue />
      },
      {
        path: '/inbound_shipments/*',
        element: <ProcessInboundShipmentContainer />
      },
      {
        path: '/outbound_shipments/*',
        element: <ProcessOutboundShipmentContainer />
      },
      {
        path: '/product_descriptors/:id',
        element: <ShowProductDescriptor />
      },
      {
        path: '/mpc',
        element: <MpcMenu />
      },
      {
        path: '/mpc/mpc_count',
        element: <MpcCount />
      },
      {
        path: '/mpc/report_lost',
        element: <ReportLost />
      },
      {
        path: '/mpc/check_in_recovered',
        element: <CheckInRecovered />
      }
    ]
  },
  {
    path: '/',
    element:
      <ErrorBoundary>
        <DisplayedError>
          <LoggedOutLayout/>
        </DisplayedError>
      </ErrorBoundary>,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/reset_password_request',
        element: <RequestPasswordReset />
      },
      {
        path: '/reset_password',
        element: <PasswordReset />
      }
    ]
  },
  {
    path: '/token_consumption',
    element:
      <ErrorBoundary>
        <TokenConsumption />
      </ErrorBoundary>
  },
  {
    path: '/choose_store',
    element:
      <ErrorBoundary>
        <ChooseStore />
      </ErrorBoundary>
  }
]

export default routes
