import React from 'react'

const EmailField = ({email, setEmail}) => {
  return (
    <div className='p-4'>
      <label className='text-gray-400'>
        Email
        <br/>
        <input
          className='text-black w-full py-2 border-b-2 focus:border-indigo-300 focus:outline-none'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </label>
    </div>
  )
}

export default EmailField
