import { useState } from 'react'

const TableHead = ({ columns, handleSorting }) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
    <thead>
      <tr className="text-left">
        {columns.map(({ label, accessor, sortable }) => {
          const arrow = sortable
           ? sortField === accessor && order === "asc"
            ? <button><p className="text-indigo-600">↑</p></button>
            : sortField === accessor && order === "desc"
            ? <button><p className="text-indigo-600">↓</p></button>
            : <button><p>⇅</p></button>
           : "";
          return <th className="border p-2" key={accessor} onClick={() => handleSortingChange(accessor)}>
            {label} {arrow}
          </th>
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
