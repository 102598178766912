import React from 'react'
import PropTypes from 'prop-types'

const PickupPersonRow = ({ pickupPerson, rowNumber }) => {
  return (
    <div className='flex content-center items-center  w-[90%]'>
      <div className='flex flex-col pl-4' id={`pickup-person-row-${rowNumber}`}>
        <div id='pickup-person-name' className='text-sm font-medium'>
          {pickupPerson.name}
        </div>
        <div id='pickup-person-phone' className='text-sm text-zinc-500'>
          {pickupPerson.phone}
        </div>
      </div>
      <div id='pickup-person-email' className='pl-20 text-sm'>
        {pickupPerson.email}
      </div>
    </div>
  )
}

PickupPersonRow.propTypes = {
  pickupPerson: PropTypes.object.isRequired,
  rowNumber: PropTypes.number.isRequired
}

export default PickupPersonRow
