import React, { useState } from 'react'
import PropTypes from 'prop-types'

const MpcCountSuccess = ({ itemCount }) => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  return (
    <>
      {!isConfirmed && <section>
        <p className="mb-4">How many pieces do you have in store?</p>

        <div className="mx-auto text-center w-48 border-solid border border-gray-300 rounded leading-6 p-2">{itemCount}</div>

        <div className='mt-8 text-green-500'>Count is correct. Confirm to continue.</div>

        <button
          className="mt-4 block bg-indigo-500 rounded text-white p-2 mx-auto hover:bg-indigo-700 focus:bg-indigo-600 duration-150"
          onClick={() => setIsConfirmed(true)}
        >Confirm</button>
      </section>}

      {isConfirmed && <section>
        <p className="mb-4">Number of pieces reported</p>

        <div className="mx-auto text-center w-48 border-solid border border-gray-300 rounded leading-6 p-2">{itemCount}</div>

        <p className="mt-8 font-bold">Confirmed. MPC is complete.</p>
      </section>}
    </>
  )
}

MpcCountSuccess.propTypes = {
  itemCount: PropTypes.string.isRequired
}

export default MpcCountSuccess
