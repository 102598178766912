import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Accordion from '../shared/Accordion'
import FilteringHelper from '../../helpers/filtering-helper'
import FilterRange from '../shared/filters/FilterRange'
import FilterSelect from '../shared/filters/FilterSelect'

const InventoryFilter = ({
  checkedStatuses,
  designers,
  diamondClarities,
  diamondColors,
  handleCloseFilter,
  kinds,
  metals,
  queryInventory
}) => {
  const [checkedKinds, setCheckedKinds] = useState({})
  const [checkedMetals, setCheckedMetals] = useState({})
  const [checkedDesigners, setCheckedDesigners] = useState({})
  const [checkedDiamondClarities, setCheckedDiamondClarities] = useState({})
  const [checkedDiamondColors, setCheckedDiamondColors] = useState({})
  const [diamondCaratWeights, setDiamondCaratWeights] = useState({})
  const [prices, setPrices] = useState({})

  useEffect(() => {
    for (const filterName in checkedStatuses) {
      let initialCheckedStatuses = {}
      if (FilteringHelper.getFilterType(filterName) === 'range') {
        initialCheckedStatuses = checkedStatuses[filterName]
      } else if (filterName === 'sku') {
        continue
      } else {
        checkedStatuses[filterName].forEach((status) => {
          initialCheckedStatuses[status] = true
        })
      }
      const setChecked = setCheckedStateActionMap.get(filterName)
      setChecked(initialCheckedStatuses)
    }
  }, [])

  const setCheckedStateActionMap = new Map([
    ['kinds', setCheckedKinds],
    ['metals', setCheckedMetals],
    ['designers', setCheckedDesigners],
    ['diamondCaratWeights', setDiamondCaratWeights],
    ['diamondClarities', setCheckedDiamondClarities],
    ['diamondColors', setCheckedDiamondColors],
    ['prices', setPrices]
  ])

  const checkedStatusesMap = new Map([
    ['kinds', checkedKinds],
    ['metals', checkedMetals],
    ['designers', checkedDesigners],
    ['diamondCaratWeights', diamondCaratWeights],
    ['diamondClarities', checkedDiamondClarities],
    ['diamondColors', checkedDiamondColors],
    ['prices', prices]
  ])

  const filterOptionsMap = new Map([
    ['kinds', kinds],
    ['metals', metals],
    ['designers', designers],
    ['diamondCaratWeights', diamondCaratWeights],
    ['diamondClarities', diamondClarities],
    ['diamondColors', diamondColors],
    ['prices', prices]
  ])

  const allFilterOptions = [...setCheckedStateActionMap.keys()]

  const handleInputCheck = (event) => {
    const item = event.target.value
    const filterName = FilteringHelper.getFilterNameFromInput(event.target.name)
    const isChecked = event.target.checked
    const setChecked = setCheckedStateActionMap.get(filterName)
    setChecked(prevState => ({ ...prevState, [item]: isChecked }))
  }

  const clearCheckedFilters = () => {
    for (const setCheckedFilters of setCheckedStateActionMap.values()) {
      setCheckedFilters({})
    }
    queryInventory()
  }

  const submitFilters = (event) => {
    event.preventDefault()
    handleFilterSubmit()
    handleCloseFilter()
  }

  const getFilterParams = () => {
    const filterParams = {}
    for (const [filterName, checkedFilterValues] of checkedStatusesMap) {
      let currentParam
      if (FilteringHelper.getFilterType(filterName) === 'range') {
        currentParam = filterOptionsMap.get(filterName)
      } else {
        currentParam = Object.keys(checkedFilterValues).filter(key => checkedFilterValues[key] === true)
      }
      filterParams[filterName] = currentParam
    }
    return filterParams
  }

  const getFilterContent = (filterName) => {
    const checkedStatuses = checkedStatusesMap.get(filterName)
    const filterOptions = filterOptionsMap.get(filterName)

    if (FilteringHelper.getFilterType(filterName) === 'range') {
      const handleRangeCheck = setCheckedStateActionMap.get(filterName)
      return (
        <FilterRange
          filterName={filterName}
          filterValues={filterOptions}
          handleInputChange={handleRangeCheck}
        />
      )
    } else {
      return (
        <FilterSelect
          checkedStatuses={checkedStatuses}
          filterName={filterName}
          handleFilterCheck={handleInputCheck}
          filterOptions={filterOptions}
        />
      )
    }
  }

  const getFormattedFilterName = (filterName) => {
    return FilteringHelper.formatFilterName(filterName)
  }

  const handleFilterSubmit = () => {
    const filterParams = getFilterParams()
    queryInventory(filterParams)
  }

  return (
    <form onSubmit={submitFilters}>
      <div className="flex justify-end">
        <button
          className="font-bold text-xs mb-2"
          onClick={handleCloseFilter}
          type="button"
          value="filter"
        >
          X
        </button>
      </div>

      <div className="accordion">
        <div className='accordion-filter text-2xl font-medium text-gray-900 border-b-2'>Filter</div>
        {
          allFilterOptions.map((filterName) => (
            <Accordion key={filterName} title={getFormattedFilterName(filterName)} content={getFilterContent(filterName)} />
          ))
        }
      </div>
      <div className="flex pt-4">
        <button

          className="text-indigo-500 text-lg px-6 py-2 rounded my-2"
          onClick={clearCheckedFilters}
          type="button"
        >
          Reset
        </button>
        <button
          className="bg-indigo-500 text-white text-lg px-6 py-2 rounded my-2 ml-auto"
          type="submit"
        >
          Apply
        </button>
      </div>
    </form>
  )
}

InventoryFilter.propTypes = {
  checkedStatuses: PropTypes.object.isRequired,
  designers: PropTypes.object.isRequired,
  diamondClarities: PropTypes.object,
  diamondColors: PropTypes.object,
  handleCloseFilter: PropTypes.func.isRequired,
  kinds: PropTypes.object.isRequired,
  metals: PropTypes.object.isRequired,
  queryInventory: PropTypes.func.isRequired
}

export default InventoryFilter
