import React from 'react'
import PopUpModal from '../shared/PopUpModal'
import PropTypes from 'prop-types'
import Authorization from '../../helpers/authorization'

const SessionAboutToExpireModal = ({ closeModal, setLoggedIn }) => {
  const handleAccept = () => {
    if (Authorization.passwordAuthEnabled()) {
      handlePasswordAuth()
    } else {
      handleSsoAuth()
    }
  }

  const handlePasswordAuth = () => {
    Authorization.setRefreshToken()
    closeModal()
  }

  const handleSsoAuth = () => {
    listenForNewToken()
    window.open(`${process.env.REACT_APP_BACKEND_URL}/sso/init`, '_blank')
  }

  const listenForNewToken = () => {
    const oldToken = localStorage.getItem('jwtToken')
    const interval = setInterval(() => {
      const newToken = localStorage.getItem('jwtToken')
      if (newToken && newToken !== oldToken) {
        clearInterval(interval)
        closeModal()
        setLoggedIn(true)
      }
    })
  }

  const handleDecline = () => {
    closeModal()
    Authorization.logout()
    setLoggedIn(false)
  }

  return (
    <PopUpModal
      header={'Your session is about to expire'}
      mainText={ 'Would you like to stay logged in?'}
      closeModal={handleDecline}
      handleAccept={handleAccept}
      leftButtonLabel={'Dismiss'}
      rightButtonLabel={'Yes, Stay logged in'}
    />
  )
}
SessionAboutToExpireModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setLoggedIn: PropTypes.func.isRequired
}
export default SessionAboutToExpireModal
