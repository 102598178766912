import React from 'react'

const formattedFilterNameMap = new Map([
  ['kinds', 'Kind'],
  ['metals', 'Metal'],
  ['designers', 'Designer'],
  ['diamondCaratWeights', 'Carat Weight'],
  ['diamondClarities', 'Clarity'],
  ['diamondColors', 'Color'],
  ['prices', 'Price']
])

const filterTypeMap = new Map([
  ['kinds', 'select'],
  ['metals', 'select'],
  ['designers', 'select'],
  ['diamondCaratWeights', 'range'],
  ['diamondClarities', 'select'],
  ['diamondColors', 'select'],
  ['prices', 'range']
])
class FilteringHelper {
  static renderCheckboxes (filterSelections, checkedStatuses, callback) {
    return Object.keys(filterSelections).map((key) => {
      const value = filterSelections[key]
      return (
        <>
          <label>
            <input key={key} type="checkbox" name={key} value={value} checked={checkedStatuses[value]} onChange={callback} className="mr-2 "/>
            {key}
          </label>
          <br/>
        </>
      )
    })
  }

  static humanize (string) {
    return string.replace(/_/g, ' ')
  }

  static getFilterNameFromInput (inputName) {
    const indexOfDash = inputName.indexOf('-')
    return inputName.slice(0, indexOfDash)
  }

  static formatFilterName (filterName) {
    return formattedFilterNameMap.get(filterName)
  }

  static getFilterType (filterName) {
    return filterTypeMap.get(filterName)
  }
}

export default FilteringHelper
