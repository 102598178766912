import React from 'react'
import PropTypes from 'prop-types'
import DatesHelper from '../../helpers/dates-helper'
import { Link } from 'react-router-dom'

const ReservationShipmentDetails = ({ pickupContacts, reservationShipment }) => {
  const { contact, timeSent } = reservationShipment

  return (
    <div className="p-4 bg-[#FBFAFC] h-full">
      <div>
        <h2 className="text-xl font-bold">Details</h2>

        { contact && (
          <div className="py-2">
            <div className="text-gray-400">
              Customer
            </div>
            <a href={`/contacts/${contact.id}`} className="text-indigo-600">
              {contact.name}
            </a>
          </div>
        )}

        <div className="py-2">
          <div className="text-gray-400">
            Status
          </div>
          <div>
            {reservationShipment.statusName}
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            Requested on
          </div>
          <div>
            {DatesHelper.formatDateWithMonthAbbr(reservationShipment.created)}
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            <span>Pickup date</span>
            <span>
              <Link to={`/reservations/${reservationShipment.id}/edit_pickup`} state={{ reservationShipment }}>
                <span className="ml-2 text-indigo-600">
                  Edit
                </span>
              </Link>
            </span>
          </div>
          <div>
            {DatesHelper.formatDateWithMonthAbbr(reservationShipment.pickupDate)}
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            Return date
          </div>
          <div>
            {DatesHelper.formatDateWithMonthAbbr(reservationShipment.dueBack)}
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            Picked up by
          </div>
          <div>
            {reservationShipment.reservationDetail.pickedUpByContact?.name}
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            Returned by
          </div>
          <div>
            {reservationShipment.reservationDetail.returnedByContact?.name}
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            Kind
          </div>
          <div>
            Event rental
          </div>
        </div>

        <div className="py-4">
          <div className="text-gray-400">
            {'Shipping Tracking Number '}
            <span>
              <a href={`/reservations/shipping_label?label=${reservationShipment.shippingLabel}`} target="_blank" rel="noreferrer" className='text-indigo-600'>
                View Label
              </a>
            </span>
          </div>
          <div>
            {reservationShipment.shippingTrackingNum}
          </div>
        </div>

        <div className="py-4">
          <div className="text-gray-400">
            {'Return Tracking Number '}
            <span>
              <a href={`/reservations/shipping_label?label=${reservationShipment.returnLabel}`} target="_blank" rel="noreferrer" className='text-indigo-600'>
                View Label
              </a>
            </span>
          </div>
          <div>
            {reservationShipment.returnTrackingNum}
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            Time Sent
          </div>
          <div>
            {timeSent ? DatesHelper.formatDateWithMonthAbbr(timeSent) : ''}
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            Admin
          </div>
          <div>
            {reservationShipment.reservationDetail.createdBy}
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            Pick Up Person
          </div>
          <div>
            {pickupContacts.length > 0 && pickupContacts.map((contact, index) => {
              return (
                <div key={`${contact.id}-${index}`}>
                  {contact.name}
                </div>
              )
            })}
            {!pickupContacts.length > 0 &&
              <div>
                No pickup people added
              </div>
            }
          </div>
        </div>

        <div className="py-2">
          <div className="text-gray-400">
            Pickup Date
          </div>
          <div>
            {DatesHelper.formatDateWithMonthAbbr(reservationShipment.pickupDate)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReservationShipmentDetails

ReservationShipmentDetails.propTypes = {
  pickupContacts: PropTypes.array.isRequired,
  reservationShipment: PropTypes.object.isRequired
}
