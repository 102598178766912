import { NavLink } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from '../shared/ErrorPopup'
import ReservationsApi from '../../api/ReservationsApi'
import camelize from 'camelize'

const navLinks = [
  {
    link: '/inventory',
    copy: 'Inventory'
  },
  {
    link: '/inbound_shipments',
    copy: 'Inbound'
  },
  {
    link: '/outbound_shipments',
    copy: 'Outbound'
  },
  {
    link: '/reservations',
    copy: 'Reservations'
  },
  {
    link: '/contacts',
    copy: 'Customers'
  },
  {
    link: '/past_due',
    copy: 'Past Due'
  },
  {
    link: '/mpc',
    copy: 'MPC'
  }
]

const Nav = () => {
  const [counts, setCounts] = useState({ pastDueCount: 0 })
  const [error, setError] = useState(null)

  const setLinkStyle = (isActive, copy) => {
    if (copy === 'Past Due' && counts.pastDueCount > 0 && isActive) {
      return 'text-[#F54D4D] border-b-4 border-[#F54D4D]'
    } else if (copy === 'Past Due' && counts.pastDueCount > 0) {
      return 'text-[#F54D4D]'
    } else if (isActive) {
      return 'text-indigo-600 border-b-4'
    } else {
      return ''
    }
  }

  const renderLinks = () => {
    return (
      navLinks.map((linkObj, index) => (
        <li className='mr-5 lg:px-4 last:whitespace-nowrap' key={index}>
          <NavLink
            to={`${linkObj.link}`}
            className={({ isActive }) =>
              setLinkStyle(isActive, linkObj.copy)
            }
            key={index}
          >
            {`${linkObj.copy}`} {linkObj.copy === 'Past Due' && counts.pastDueCount > 0 && `(${counts.pastDueCount})`}
          </NavLink>
        </li>
      ))
    )
  }

  useEffect(() => {
    ReservationsApi.fetchReservationCounts()
      .then(response => response.json())
      .then(json => {
        if (json.error_message) {
          const error = { bannerMessage: json.error_message }
          ErrorHelper.addError(error, setError, document.querySelector('div'))
        } else {
          setCounts(camelize(json))
        }
      })
      .catch((e) => {
        const error = { bannerMessage: 'Something went wrong fetching reservation counts' }
        ErrorHelper.addError(error, setError, document.querySelector('div'))
      })
  }, [])

  return (
    <nav className='bg-white sticky top-0 z-10'>
      {error && <ErrorPopup error={error}/>}
      <ul className="flex justify-center pb-4 pt-8 text-xs sm:text-sm md:text-base lg:text-xl">
        {renderLinks()}
      </ul>
    </nav>
  )
}

export default Nav
