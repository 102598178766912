import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useOutletContext } from 'react-router-dom'
import FormValidations from '../../../helpers/form-validations'
import ReservationsApi from '../../../api/ReservationsApi'
import FormInputFieldWithLabel from '../../shared/FormInputFieldWithLabel'
import FormInputFieldWithoutLabel from '../../shared/FormInputFieldWithoutLabel'

const CustomerInformationForm = ({ isCustomerFormSubmitted, reservationInfo, setIsCustomerFormSubmitted }) => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
  })
  const { errorHandler } = useOutletContext()

  const handleFormChange = (e) => {
    const suffixStart = e.target.name.indexOf('-')
    const key = e.target.name.slice(0, suffixStart)
    const value = e.target.value

    if (key === 'phone') {
      FormValidations.formatPhoneNumberWithCallback(value, updateFormValue)
      return
    }
    updateFormValue(key, value)
  }

  const updateFormValue = (key, value) => {
    setFormValues(prevValues => (
      { ...prevValues, [key]: value }
    ))
  }

  const handleFormSubmit = () => {
    const fieldNameSuffix = '-customer-info-form'
    const invalidFields = FormValidations.checkFieldValidity(Object.keys(formValues), fieldNameSuffix)

    if (invalidFields.length > 0) {
      invalidFields.forEach(field => {
        FormValidations.addErrorBorderToField(field)
      })
      errorHandler({ bannerMessage: 'Please fill out all required fields' })
      FormValidations.addErrorMessageToField(invalidFields[0])
    } else {
      submitCustomerForm()
    }
  }

  const submitCustomerForm = async () => {
    const formParams = formValues
    const reservationShipmentId = { reservationShipmentId: reservationInfo.reservationShipment.id }
    const params = { ...formParams, ...reservationShipmentId }

    await ReservationsApi.createReservationContact(params)
      .then(res => res.json())
      .then(json => {
        if (json.error_message) {
          const error = { bannerMessage: json.error_message }
          errorHandler(error, document.querySelector('form'))
        } else {
          setIsCustomerFormSubmitted(true)
        }
      })
      .catch(() => {
        errorHandler({ bannerMessage: 'Something went wrong creating the contact' })
      })
  }

  return (
    <div className='md:col-span-4 bg-[#FBFAFC] border-t-2 border-gray-200 outline-none'>
      <div className='px-8 pt-24'>
        <h3 className='text-md font-medium leading-6 text-gray-900'>Customer Information</h3>
      </div>
      <div className='grid grid-cols-4 gap-4'>
        <FormInputFieldWithLabel
          label='First Name*'
          className='col-span-2 pl-8 pt-7'
          onChange={handleFormChange}
          value={formValues.firstName}
          placeHolder='First Name'
          inputName='firstName-customer-info-form'
          disabled={isCustomerFormSubmitted}
          required
        />
        <FormInputFieldWithLabel
          label='Last Name*'
          className='col-span-2 pl-8 pt-7'
          onChange={handleFormChange}
          value={formValues.lastName}
          placeHolder='Last Name'
          inputName='lastName-customer-info-form'
          disabled={isCustomerFormSubmitted}
          required
        />
        <FormInputFieldWithLabel
          label='Email Address*'
          className='col-span-2 pl-8'
          onChange={handleFormChange}
          value={formValues.email}
          placeHolder='Your Email'
          inputName='email-customer-info-form'
          type='email'
          disabled={isCustomerFormSubmitted}
          required
        />
        <FormInputFieldWithLabel
          label='Phone*'
          className='col-span-2 pr-8'
          onChange={handleFormChange}
          value={formValues.phone}
          placeHolder='0-000-000-000'
          inputName='phone-customer-info-form'
          type='telephone'
          disabled={isCustomerFormSubmitted}
          required
          pattern=".{0}|.{14,14}"
          title=" (Format is 1-xxx-xxx-xxxx)"
          data-testid='phone-customer-info-form'
        />
        <FormInputFieldWithLabel
          label='Address 1*'
          className='col-span-4 px-8'
          onChange={handleFormChange}
          value={formValues.streetAddress1}
          placeHolder='Address'
          inputName='streetAddress1-customer-info-form'
          disabled={isCustomerFormSubmitted}
          required
          data-testid='streetAddress1-customer-info-form'
        />
        <FormInputFieldWithLabel
          label='Address 2'
          className='col-span-4 px-8'
          onChange={handleFormChange}
          value={formValues.streetAddress2}
          placeHolder='Address'
          inputName='streetAddress2-customer-info-form'
          disabled={isCustomerFormSubmitted}
          data-testid='streetAddress2-customer-info-form'
        />
        <FormInputFieldWithLabel
          label='Location*'
          className='col-span-2 pl-8'
          onChange={handleFormChange}
          value={formValues.city}
          placeHolder='City'
          inputName='city-customer-info-form'
          disabled={isCustomerFormSubmitted}
          required
        />
        <FormInputFieldWithoutLabel
          className='col-span-1 pt-5 pr-8'
          onChange={handleFormChange}
          value={formValues.state}
          placeHolder='State'
          inputName='state-customer-info-form'
          disabled={isCustomerFormSubmitted}
          required
        />
        <FormInputFieldWithoutLabel
          className='col-span-1 pt-5 pr-8'
          onChange={handleFormChange}
          value={formValues.zip}
          placeHolder='ZIP Code'
          inputName='zip-customer-info-form'
          disabled={isCustomerFormSubmitted}
          required
          type='zip'
          pattern=".{0}|.{5,5}"
          title="5 digits only"
        />
        {!isCustomerFormSubmitted && <div className='pl-8 pb-7'> <button
          className='inline-flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 h-10'
          type='button'
          onClick={handleFormSubmit}
        >
          Continue →
        </button> </div>
        }
      </div>
    </div>
  )
}

CustomerInformationForm.propTypes = {
  isCustomerFormSubmitted: PropTypes.bool.isRequired,
  reservationInfo: PropTypes.object.isRequired,
  setIsCustomerFormSubmitted: PropTypes.func.isRequired
}

export default CustomerInformationForm
