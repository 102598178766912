import React from 'react'
import PropTypes from 'prop-types'
import MoneyHelper from '../../helpers/money-helper'

const ReservationsBySkusTable = ({ reservationShipment, reservationsBySkus }) => {
  return (
    <>
      <h1 className='text-3xl font-bold pb-4'>Reservation Details</h1>
      <div className="font-bold py-2">
        Pickup date: {reservationShipment.pickupDate}
      </div>
      <div className="font-bold py-2">
        Return date: {reservationShipment.dueBack}
      </div>

      <table className="table-auto w-full text-md">
        <thead>
          <tr className="text-left">
            <th className="border p-2">SKU</th>
            <th className="border p-2">Quantity</th>
            <th className="border p-2">Retail Price</th>
            <th className="border p-2">Rental Fee</th>
            <th className="border p-2">Total due today</th>
            <th className="border p-2">Total due at pickup</th>
          </tr>
        </thead>
        <tbody>
          {reservationsBySkus.map(r => {
            return (
              <tr className="text-left" key={r.sku}>
                <td className="border p-2">{r.sku}</td>
                <td className="border p-2">{r.quantity}</td>
                <td className="border p-2">{MoneyHelper.centsToDollars(r.retailPrice)}</td>
                <td className="border p-2">{MoneyHelper.centsToDollars(r.rentalFee)}</td>
                <td className="border p-2">{MoneyHelper.centsToDollars(r.dueToday)}</td>
                <td className="border p-2">{MoneyHelper.centsToDollars(r.duePickup)}</td>
              </tr>
            )
          })}
          </tbody>
      </table>
    </>
  )
}

ReservationsBySkusTable.propTypes = {
  reservationShipment: PropTypes.object.isRequired,
  reservationsBySkus: PropTypes.array.isRequired
}

export default ReservationsBySkusTable
