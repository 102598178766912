import MpcApi from '../../api/MpcApi'
import MpcCountList from './MpcCountList'
import MpcCountListConfirmation from './MpcCountListConfirmation'
import MpcCountStart from './MpcCountStart'
import MpcCountSuccess from './MpcCountSuccess'
import PopUpModal from '../shared/PopUpModal'
import React, { useEffect, useRef, useState } from 'react'

const MpcCount = () => {
  const inputRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [attemptNumber, setAttemptNumber] = useState(1)
  const [hideSubmit, setHideSubmit] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [itemCount, setItemCount] = useState('')
  const [items, setItems] = useState([])
  const [showList, setShowList] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const updateLostReason = ({ id, lostReason, reasonText = '' }) => {
    setItems((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            product_status: reasonText,
            reason_name: lostReason,
            reason_text: reasonText
          }
        }
        return item
      })
    )
  }

  const focusChildInput = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleConfirm = () => {
    let hasLostItems = false
    setIsSubmitted(true)

    if (items.some(item => !item.product_status)) {
      setIsValid(false)
      return
    }

    setIsValid(true)

    items.forEach(item => {
      if (item.product_status !== 'In Store') {
        hasLostItems = true
        MpcApi.exitProductFromInventory(
          item.id,
          item.reason_name
        )
          .then(res => res.json())
          .catch((e) => {
            console.log(e)
          })
      } else {
        MpcApi.enterProductToInventory(item.id)
          .then(res => res.json())
          .catch((e) => {
            console.log(e)
          })
      }
    })

    if (hasLostItems) {
      setShowModal(true)
    }
  }

  const handleFormChange = (event) => {
    setItemCount(event.target.value)
  }

  const handleSubmit = () => {
    if (!itemCount) {
      return
    }

    setAttemptNumber((prevState) => prevState + 1)

    MpcApi.submitCycleCount(itemCount, attemptNumber)
      .then(res => res.json())
      .then(json => {
        /* eslint-disable camelcase */
        const { attempt_number, success } = json
        if (success) {
          setIsSuccess(true)
          setHideSubmit(true)
        } else if (attempt_number === 1) {
          setIsError(true)
          focusChildInput()
        } else {
          setShowList(true)
          setHideSubmit(true)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleReset = () => {
    setIsLoading(false)
    setIsSuccess(false)
    setIsError(false)
    setShowList(false)
    setItemCount('')
    setHideSubmit(false)
    focusChildInput()
  }

  useEffect(() => {
    focusChildInput()
    getInventory()
    newCycleCount()
    setIsLoading(false)
  }, [])

  const getInventory = async () => {
    await MpcApi.checkInventory('mpc_count')
      .then(res => res.json())
      .then(json => {
        const items = json.on_display_products.map(item => item.products.filter(product => product.id)).flat(Infinity)
        setItems(items)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const newCycleCount = async () => {
    await MpcApi.newCycleCount()
      .then(res => res.json())
      .then(json => {
        console.log(json)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div className="container mx-auto">
      <div className="border-b-2 my-6"/>
      <h1 className="text-indigo-600 py-4 text-xl font-bold">MPC (MERCHANDISE PIECE COUNT)</h1>
      <div className="bg-slate-100 my-4 px-4 py-6 rounded-lg text-center">
        {isLoading && <h1>Loading...</h1>}
        {showModal && <PopUpModal
          closeModal={() => setShowModal(false)}
          handleAccept={() => setShowModal(false)}
          header=""
          leftButtonLabel=""
          mainText='Report this to your Regional Loss Prevention Manager'
          rightButtonLabel="Ok"
        />}
        {(!isSuccess && !showList) && <MpcCountStart
          forwardedRef={inputRef}
          itemCount={itemCount}
          isError={isError}
          handleFormChange={handleFormChange}
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          hideSubmit={hideSubmit}
        />}
        {isSuccess && <MpcCountSuccess itemCount={itemCount}/>}
        {(showList && !isValid) && <MpcCountList
          isSubmitted={isSubmitted}
          isValid={isValid}
          items={items}
          itemCount={itemCount}
          updateLostReason={updateLostReason}
          handleReset={handleReset}
          handleConfirm={handleConfirm}
        />}
        {(showList && isValid) && <MpcCountListConfirmation items={items} />}
      </div>
    </div>
  )
}

export default MpcCount
