import React from 'react'
import PropTypes from 'prop-types'

const NewReservationHeader = ({ step }) => {
  return (
    <div className='fixed pl-8 top-0 bg-white font-semibold h-36 w-full'>
      <div className='pt-10 text-3xl'>
        Checkout
      </div>
      <div className='grid grid-cols-3 pt-6'>
        <div className={`col-span-1 flex items-center text-sm ${step === 1 ? 'text-indigo-600' : ''}`} data-testid='header-step-reservation-details'>
          <div className={`flex justify-center items-center rounded-full h-7 w-7 ${step === 1 ? 'bg-indigo-600 bg-opacity-10' : 'bg-gray-100'}`}>
            1
          </div>
          <div className='pl-4'>
            Reservation Details
          </div>
        </div>
        <div className={`col-span-1 flex items-center text-sm ${step === 2 ? 'text-indigo-600' : ''}`} data-testid='header-step-customer-confirmation'>
          <div className={`flex justify-center items-center rounded-full h-7 w-7 ${step === 2 ? 'bg-indigo-600 bg-opacity-10' : 'bg-gray-100'}`}>
            2
          </div>
          <div className='pl-4'>
            Customer Confirmation
          </div>
        </div>
        <div className={`col-span-1 flex items-center text-sm ${step === 3 ? 'text-indigo-600' : ''}`} data-testid='header-step-confirm-reservation'>
          <div className={`flex justify-center items-center rounded-full h-7 w-7 ${step === 3 ? 'bg-indigo-600 bg-opacity-10' : 'bg-gray-100'}`}>
            3
          </div>
          <div className='pl-4'>
            Confirm Reservation
          </div>
        </div>
      </div>
    </div>
  )
}

NewReservationHeader.propTypes = {
  step: PropTypes.number.isRequired
}

export default NewReservationHeader
