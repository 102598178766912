export const isReceiving = () => {
  if (window.location.pathname.includes('inbound_shipments')) return true
  return false
}

export const setProductsFromProductStatuses = (productStatuses) => {
  return productStatuses.map((productStatus) => {
    const { productId, desiredStatus } = productStatus
    return {
      id: productId,
      desired_status: desiredStatus
    }
  })
}
