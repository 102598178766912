class StatusHelper {
  // Used to show valid check in and out statuses based on product status
  static filterStatuses (statuses, product) {
    const statusName = product.statusName

    return Object.fromEntries(
      Object.entries(statuses).filter(([key, value]) => {
        // Associates should not be able to say something is intact if it was previously marked damaged
        if (statusName === 'Damaged Reparable' && key === 'out') {
          return false
        }
        return true
      })
    )
  }
}

export default StatusHelper
