import React from 'react'
import PropTypes from 'prop-types'

const FormInputFieldWithoutLabel = (props) => {
  const { className, onChange, value, type, placeHolder, disabled, required, inputName, ariaLabel, ...extraProps } = props
  return (
    <div className={className}>
      <div className='validationMessage absolute bg-white mt-5 ml-2 italic text-xs'></div>
      <input
        className='mt-2 pl-4 block w-full rounded-md border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 h-12 md:text-md'
        name={inputName || 'form-input-field-with-label'}
        type={type || 'text'}
        required={required}
        onChange={onChange}
        value={value}
        placeholder={placeHolder}
        disabled={disabled}
        {...extraProps}
      />
    </div>)
}

export default FormInputFieldWithoutLabel

FormInputFieldWithoutLabel.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  inputName: PropTypes.string,
  ariaLabel: PropTypes.string
}
