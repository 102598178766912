import React, { useState, createContext } from 'react'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from './ErrorPopup'
import PropTypes from 'prop-types'

export const AddErrorContext = createContext(null)

const DisplayedError = ({ children }) => {
  const [error, setError] = useState(null)

  const addError = (errorObj, container = document) => {
    ErrorHelper.addError(errorObj, setError, container)
  }

  return (
    <AddErrorContext.Provider value={addError}>
      {error &&
        <div className="fixed top-36 z-[1000] w-full">
          <div className="w-max mx-auto">
            <ErrorPopup error={error}/>
          </div>
        </div>
      }
      {children}
    </AddErrorContext.Provider>

  )
}

DisplayedError.propTypes = {
  children: PropTypes.object.isRequired
}

export default DisplayedError
