/* eslint-disable react/jsx-no-bind */

import PropTypes from 'prop-types'
import React from 'react'

const SkuThumbnail = ({
  checked,
  classNames,
  displayQuantity,
  selectSku,
  skuObject,
  unselectSku
}) => {
  const handleChange = (event) => {
    const selectedSku = event.target.value

    if (event.target.checked) {
      selectSku(selectedSku)
    } else {
      unselectSku(selectedSku)
    }
  }

  const { designerSku, id, imageUrl, name, reservableCount, sku } = skuObject

  const productDescriptorLink = <a href={`/product_descriptors/${id}`} className='text-indigo-600'>{sku}</a>

  return (
    <div className="sku-thumbnail">
      <div className={`border-slate-200 bg-white m-4 p-2 ${classNames || 'h-44 w-44'} border rounded-lg `}>
        <input
          checked={checked}
          onChange={handleChange}
          type="checkbox"
          value={sku}
        />
        <img
          className="m-auto h-5/6 w-5/6"
          src={imageUrl}
        />
      </div>
      <div className={`text-center mt-2 mx-4 flex flex-col items-center ${classNames || 'w-44'}`}>
        <p className="text-xs mb-1 font-semibold">
          {name}
        </p>
        <p className="text-xs">
          Rocksbox SKU: {productDescriptorLink}
        </p>
        <p className="text-xs">
          Zales SKU: {designerSku}
        </p>
        {
          displayQuantity &&
          <p className="text-xs">
            {`Active items: ${reservableCount}`}
          </p>
        }
      </div>
    </div>
  )
}

export default SkuThumbnail

SkuThumbnail.defaultProps = {
  checked: false,
  classNames: ''
}

SkuThumbnail.propTypes = {
  checked: PropTypes.bool,
  classNames: PropTypes.string,
  displayQuantity: PropTypes.bool,
  selectSku: PropTypes.func,
  skuObject: PropTypes.object.isRequired,
  unselectSku: PropTypes.func.isRequired
}
