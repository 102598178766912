import React, { useEffect, useState } from 'react'
import ProductDescriptorsApi from '../../api/ProductDescriptorsApi'
import { Link, useParams } from 'react-router-dom'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from '../shared/ErrorPopup'
import ProductDescriptorDetail from './ProductDescriptorDetail'
import ProductDescriptorHeader from './ProductDescriptorHeader'
import ProductsTable from './ProductsTable'
import camelize from 'camelize'

const ShowProductDescriptor = () => {
  const params = useParams()
  const [error, setError] = useState({})
  const [productDescriptor, setProductDescriptor] = useState({})
  const [products, setProducts] = useState([])
  const [tab, setTab] = useState('details')
  const [itemCounts, setItemCounts] = useState({})

  useEffect(() => {
    ProductDescriptorsApi.fetchProductDescriptor(params.id)
      .then(res => res.json())
      .then(json => {
        if (json.error_message) {
          ErrorHelper.addError({ bannerMessage: json.error_message }, setError, document.querySelector('div'))
        } else {
          setProductDescriptor(camelize(json.products[0].product_descriptor))
          setItemCounts(camelize(json.item_counts))
          setProducts(camelize(json.products))
        }
      })
      .catch((e) => {
        console.log(e)
        ErrorHelper.addError({ bannerMessage: 'Error Fetching Product Descriptor' }, setError, document.querySelector('div'))
      })
  }, [])

  const breadCrumbDisplay = tab === 'details' ? ' / Details' : ' / Items'

  return (
    <div className='mb-4 w-screen h-screen absolute left-0 top-0 z-10 bg-white'>
      <div className="border-b py-4 px-4">
        <Link to="/inventory">
          <span className='text-gray-400'>Product Descriptors</span>
        </Link>
        <span>{ breadCrumbDisplay }</span>
      </div>
      <div className="px-4">
        {error && <ErrorPopup error={error}/>}
        {productDescriptor && <ProductDescriptorHeader productDescriptor={productDescriptor} tab={tab} setTab={setTab} />}
        {tab === 'details' && <ProductDescriptorDetail productDescriptor={productDescriptor} itemCounts={itemCounts} />}
        {tab === 'items' && <ProductsTable products={products} />}
      </div>
    </div>
  )
}

export default ShowProductDescriptor
