import React, { useEffect, useState } from 'react'
import MpcApi from '../../api/MpcApi'
import PopUpModal from '../shared/PopUpModal'
import ReportLostList from './ReportLostList'
import ReportLostListConfirmation from './ReportLostListConfirmation'

const ReportLost = () => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [items, setItems] = useState([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getInventory()
  }, [])

  const getProducts = (data) => {
    return data.flatMap(item => item.products).filter(product => product.id)
  }

  const getInventory = async () => {
    await MpcApi.checkInventory('report_lost')
      .then(res => res.json())
      .then(json => {
        setItems(getProducts(json.on_display_products))
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleConfirm = () => {
    setIsSubmitted(true)

    if (items.some(item => item.product_status)) {
      setIsValid(true)
    } else {
      setIsValid(false)
      return
    }

    items.forEach(item => {
      if (item.reason_name) {
        MpcApi.exitProductFromInventory(
          item.id,
          item.reason_name
        ).catch((e) => {
          console.log(e)
        })
      }
    })
    setIsConfirmed(true)

    if (items.some(item => item.reason_name)) {
      setShowModal(true)
    }
  }

  const updateLostReason = ({ id, lostReason, reasonText = '' }) => {
    setItems((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            product_status: reasonText === 'Select a status' ? '' : reasonText,
            reason_name: lostReason,
            reason_text: reasonText
          }
        }
        return item
      })
    )
  }

  return (
    <div className="container mx-auto">
      <div className="border-b-2 my-6" />
      <h1 className="text-indigo-600 py-4 text-xl font-bold">REPORT LOST ITEMS</h1>
      <div className="bg-slate-100 my-4 px-4 py-6 rounded-lg text-center">
        {showModal && <PopUpModal
          closeModal={() => setShowModal(false)}
          handleAccept={() => setShowModal(false)}
          header=""
          leftButtonLabel=""
          mainText='Report this to your Regional Loss Prevention Manager'
          rightButtonLabel="Ok"
        />}
        {!isConfirmed && <ReportLostList
          items={items}
          updateLostReason={updateLostReason}
          handleConfirm={handleConfirm}
          isSubmitted={isSubmitted}
          isValid={isValid}
        />}
        {isConfirmed && <ReportLostListConfirmation items={items.filter(items => items.reason_text)} />}
      </div>
    </div>
  )
}

export default ReportLost
