import React from 'react'
import PropTypes from 'prop-types'
import DatesHelper from '../../helpers/dates-helper'
import MoneyHelper from '../../helpers/money-helper'
import PromoCodeField from '../reservations/create_reservation_shipments/PromoCodeField'
import ReservationsHelper from '../../helpers/reservations-helper'

const PickupFeeProductDetailsColumn = ({ promotion, reservationShipment, setPromotion }) => {
  const { contact: { id: contactId }, deposit, pickupFeeTaxInfo, totalDepositAmount } = reservationShipment

  return (
    <>
      <div className="border-b py-4 px-8 grid grid-cols-3">
        <div className="text-gray-500 col-span-2">
          Subtotal
        </div>
        <div className="justify-self-end pb-4" data-testid='subtotal-amount'>
          {MoneyHelper.centsToDollars(reservationShipment.totalRetailPrice)}
        </div>
        <div className="text-gray-500 pb-4 col-span-2">Total Rental Fee</div>
        <div className="justify-self-end">
          {MoneyHelper.centsToDollars(reservationShipment.rentalFee)}
        </div>
        {deposit && (
          <>
            <div className="text-gray-500 col-span-2">
              Rental fee paid on {DatesHelper.formatDateWithMonthAbbr(deposit.createdAt)}
            </div>
            <div className="justify-self-end text-gray-500 col-span-1">
              - {MoneyHelper.centsToDollars(totalDepositAmount)}
            </div>
          </>
        )}
      </div>

      <div className="border-b py-4 px-8 grid grid-cols-3">
        <div className="font-bold col-span-2">
          Amount to be paid today
        </div>
        <div className="justify-self-end font-bold col-span-1" data-testid='amount-to-be-paid-today'>
          {MoneyHelper.centsToDollars(pickupFeeTaxInfo.totalBeforeTax)}
        </div>
        <div className="text-gray-500 col-span-2" data-testid='tax-rate'>
          Tax ({MoneyHelper.formatTaxRate(pickupFeeTaxInfo.taxRate * 100)}%)
        </div>
        <div className="justify-self-end text-gray-500 col-span-1" data-testid='tax-due-amount'>
          {MoneyHelper.centsToDollars(pickupFeeTaxInfo.taxInCents)}
        </div>
       {promotion &&
          <>
            <div className="text-gray-500 col-span-2" data-testid='promotion'>
              {promotion.name}
            </div>
            <div className="justify-self-end text-gray-500 col-span-1" data-testid='promotion-amount'>
              - {MoneyHelper.centsToDollars(ReservationsHelper.getPromotionDiscount(promotion, pickupFeeTaxInfo.totalBeforeTax))}
            </div>
          </>
        }
      </div>

      <div className="border-b py-4 px-8 grid grid-cols-3 pb-4">
        <div className="font-bold col-span-2">
          Due now
        </div>
        <div className="justify-self-end pb-4 font-bold col-span-1" data-testid='due-now-first-day-amount'>
          {MoneyHelper.centsToDollars(pickupFeeTaxInfo.totalAfterTax - ReservationsHelper.getPromotionDiscount(promotion, pickupFeeTaxInfo.totalBeforeTax))}
        </div>
      </div>
      <PromoCodeField contactId={contactId} rentalStep='pickup' setPromotion={setPromotion} />
    </>
  )
}

PickupFeeProductDetailsColumn.defaultProps = {
  promotion: null
}

PickupFeeProductDetailsColumn.propTypes = {
  promotion: PropTypes.object,
  setPromotion: PropTypes.func.isRequired,
  reservationShipment: PropTypes.object.isRequired
}

export default PickupFeeProductDetailsColumn
