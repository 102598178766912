import React from 'react'
import { Notifier } from '@airbrake/browser'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = { hasError: false }

    // uses REACT_ENV set in npm script ran by node server (based on NODE_ENV of node server)
    this.airbrake = new Notifier({
      environment: process.env.REACT_APP_REACT_ENV,
      projectId: 457859,
      projectKey: '935fcca4f67f76073fcceb525899c788'
    })
  }

  componentDidCatch (error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // Send error to Airbrake
    this.airbrake.notify({
      error,
      params: { info }
    })
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong. Replace me with a real error component</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary
