import React from 'react'
import PropTypes from 'prop-types'

const MpcItem = ({ item, updateLostReason, lostReason }) => {
  const handleOnChange = (event) => {
    const reasonText = event.target.options[event.target.selectedIndex].textContent
    updateLostReason({ id: item.id, lostReason: event.target.value, reasonText })
  }

  return (
    <div className="bg-white rounded-md shadow px-8">
      <div className="flex gap-10 items-stretch justify-between">
        <div className="flex">
          <div className="flex items-center">
            <img
              className="w-32"
              src={item.product_descriptor.image_url}
            />
          </div>
        </div>

        <div className="flex flex-col items-start justify-center text-left w-48">
          <p>Rocksbox SKU</p>
          <p>{item.product_descriptor.sku}</p>
        </div>

        <div className="flex flex-col items-start justify-center text-left w-48">
          <p>Zales SKU</p>
          <p>{item.product_descriptor.designer_sku}</p>
        </div>

        <div className="flex flex-col gap-1 items-end justify-center w-48">
          <select name="status" data-testid={`status${item.id}`} className="border rounded" onChange={handleOnChange} value={lostReason}>
            <option value="" defaultValue>Select a status</option>
            <option value="not_lost">In Store</option>
            <option value="16">Lost</option>
            <option value="1">Stolen</option>
          </select>
        </div>
      </div>
    </div>
  )
}

MpcItem.propTypes = {
  item: PropTypes.object.isRequired,
  lostReason: PropTypes.string.isRequired,
  updateLostReason: PropTypes.func.isRequired
}

export default MpcItem
