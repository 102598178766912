import React from 'react'
import PropTypes from 'prop-types'

const MpcCountStart = ({ isError, hideSubmit, itemCount, handleFormChange, handleSubmit, handleReset, forwardedRef }) => {
  return (
    <section>
      <p className="mb-4">How many pieces do you have in store?</p>
      <input
        className="text-center w-48 border-solid border border-gray-300 rounded leading-6 p-2"
        name="itemCount"
        onChange={handleFormChange}
        placeholder='Enter number of items'
        ref={forwardedRef}
        type="text"
        value={itemCount}
      />

      {(isError && !hideSubmit) && <div className='mt-8 text-red-600'>Count is incorrect. Please try again.</div>}

      <div className="mt-4 mx-auto flex justify-center gap-2">
        {!hideSubmit && <button
          className='block bg-indigo-500 rounded text-white px-4 py-2 hover:bg-indigo-700 focus:bg-indigo-600 duration-150'
          onClick={handleSubmit}
        >
          Submit
        </button>}
        {isError && <button
          className="block bg-white rounded px-4 py-2 text-indigo-500 hover:bg-indigo-200 focus:bg-indigo-300 duration-150 border border-solid border-indigo-500"
          onClick={handleReset}
        >Cancel</button>}
      </div>
    </section>
  )
}

MpcCountStart.propTypes = {
  isError: PropTypes.bool.isRequired,
  hideSubmit: PropTypes.bool.isRequired,
  itemCount: PropTypes.string.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  forwardedRef: PropTypes.any.isRequired
}

export default MpcCountStart
