import React from 'react'
import PropTypes from 'prop-types'

const SingleProduct = ({ finishedCheckIn, reservation, statusName }) => {
  const { product } = reservation
  const { productDescriptor } = product

  return (
    <div className="text-center">
      <p className="w-full">
        {productDescriptor.sku}
      </p>
      <img
        className="w-75 h-75 p-2 my-0 bg-gray-200 mx-auto"
        src={productDescriptor.imageUrl}
      />
      <p className="w-full">
        {productDescriptor.name}
      </p>
      <p className="w-full">
        {`Product ID: ${product.id}`}
      </p>
      {finishedCheckIn && statusName && (
        <p className="w-full">
          {`Status: ${statusName}`}
        </p>
      )}
    </div>
  )
}

SingleProduct.propTypes = {
  finishedCheckIn: PropTypes.bool,
  reservation: PropTypes.shape({
    id: PropTypes.number,
    product: PropTypes.shape({
      id: PropTypes.number,
      productDescriptor: PropTypes.shape({
        name: PropTypes.string,
        imageUrl: PropTypes.string,
        sku: PropTypes.string
      }),
      status: PropTypes.number
    })
  }).isRequired,
  statusName: PropTypes.string
}

SingleProduct.defaultProps = {
  finishedCheckIn: false,
  statusName: ''
}

export default SingleProduct
