import React from 'react'
import ReportLostItemReadOnly from './ReportLostItemReadOnly'
import PropTypes from 'prop-types'

const ReportLostListConfirmation = ({ items }) => {
  return (
    <div>
      <p className="mt-8">You reported the following items as lost.</p>
      <p className="font-bold">Reporting lost item is complete.</p>
      <div>
        <p className="mt-8 font-bold">Items Reported Lost</p>
        <div className="mt-4 flex flex-col gap-2 align-center justify-center">
          {items.map(item => <ReportLostItemReadOnly
            key={item.id}
            item={item}
            lostReason={item.reason_name}
            reasonText={item.reason_text}
          />)}
        </div>
      </div>
    </div>
  )
}

ReportLostListConfirmation.propTypes = {
  items: PropTypes.array.isRequired
}

export default ReportLostListConfirmation
