import PropTypes from 'prop-types'
import React from 'react'
import FilteringHelper from '../../../helpers/filtering-helper'

const FilterSelect = ({ checkedStatuses, filterOptions, filterName, handleFilterCheck }) => {
  if (!filterOptions) return

  return Object.keys(filterOptions).sort().map((key) => {
    return (
      <React.Fragment key={key}>
        <label>
          <input
            checked={!!checkedStatuses[filterOptions[key]]}
            className="mr-2 h-10 w-10 scale-100 mt-1 mr-2 accent-indigo-600"
            name={`${filterName}-${key}`}
            onChange={handleFilterCheck}
            type="checkbox"
            value={filterOptions[key]}
          />
          {FilteringHelper.humanize(key)}
        </label>
        <br />
      </React.Fragment>
    )
  })
}

FilterSelect.propTypes = {
  filterOptions: PropTypes.object,
  filterName: PropTypes.string.isRequired,
  handleFilterCheck: PropTypes.func.isRequired,
  checkedStatuses: PropTypes.object.isRequired
}

export default FilterSelect
