import React, { useState } from 'react'
import CancelReservationButton from './CancelReservationButton'
import PropTypes from 'prop-types'

const CancelReservationCreation = ({ reservationShipment }) => {
  const [cancelModal, setCancelModal] = useState(false)

  return (
    <div>
      <button type="button" onClick={() => setCancelModal(true)} className='text-zinc-800 border border-solid border-gray-200 inline-flex justify-center py-2 px-4 text-sm font-medium text-white shadow-sm rounded w-max h-10'>
        Cancel & Restart
      </button>
      {cancelModal && <CancelReservationButton reservation={reservationShipment} closeModal={() => setCancelModal(false)} redirectTo='/reservations'/>}
    </div>
  )
}

CancelReservationCreation.propTypes = {
  reservationShipment: PropTypes.object.isRequired
}

export default CancelReservationCreation
