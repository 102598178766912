import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowIcon } from '../../assets/arrow-btn-right-black.svg'

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)

  return (
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div className='flex align-middle mb-5'>
            <div className='border-b-2 justify-start grow text-xl font-medium mt-10'>{title}</div>
            <div className='border-b-2 mt-10'>{isActive ? <ArrowIcon className='-rotate-90'/> : <ArrowIcon className='rotate-90'/>}</div>
          </div>
        </div>
        {isActive && <div className="accordion-content overflow-y-auto mb-2">{content}</div>}
      </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object.isRequired
}
export default Accordion
