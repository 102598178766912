import React from 'react'
import DatesHelper from '../../helpers/dates-helper'
import PropTypes from 'prop-types'

const PickupDateInput = ({ pickupDate, setPickupDate }) => {
  return (
    <label htmlFor='pickup_date' className='block font-bold text-left'>Pickup date
      <input
        type='date'
        name='pickup_date'
        aria-label="pickup_date"
        className='pl-4 sm:w-[75vw] w-full block rounded-md shadow-lg border-solid border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 h-12 font-normal md:text-md'
        required
        min={DatesHelper.formatForDateInput(DatesHelper.addDaysToDate(new Date(), 10))}
        value={pickupDate}
        onChange={e => setPickupDate(e.target.value)}
        onKeyDown={(e) => e.preventDefault()}
      />
    </label>
  )
}

PickupDateInput.propTypes = {
  pickupDate: PropTypes.string.isRequired,
  setPickupDate: PropTypes.func.isRequired
}

export default PickupDateInput
