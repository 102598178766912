import PropTypes from 'prop-types'
import React from 'react'

const FilterRange = ({ filterName, filterValues, handleInputChange }) => {
  if (!handleInputChange || !filterValues) return

  const handleMinChange = (e) => {
    handleInputChange({ ...filterValues, min: e.target.value })
  }

  const handleMaxChange = (e) => {
    handleInputChange({ ...filterValues, max: e.target.value })
  }

  return (
    <div className='grid grid-cols-2 gap-4'>
      <label className='block text-sm font-medium text-gray-700 pb-2 pl-2'>
        Min
        <input
          className='mt-2 pl-4 block w-full rounded-md border-gray-900 shadow-md focus:border-indigo-600 focus:ring-indigo-600 h-12 md:text-md'
          name={`${filterName}-min-range-input`}
          type='number'
          onChange={handleMinChange}
          value={filterValues.min || ''}
          placeholder='min'
          min="0"
          step={filterName === 'diamondCaratWeights' ? '0.5' : '0.1'}
        />
      </label>
      <label className='block text-sm font-medium text-gray-700 pb-2'>
        Max
        <input
          className='mt-2 pl-4 block w-full rounded-md border-gray-900 shadow-md focus:border-indigo-600 focus:ring-indigo-600 h-12 md:text-md'
          name={`${filterName}-max-range-input`}
          type='number'
          onChange={handleMaxChange}
          value={filterValues.max || ''}
          placeholder='max'
          min="0"
          step={filterName === 'diamondCaratWeights' ? '0.5' : '0.1'}
        />
      </label>
    </div>
  )
}

FilterRange.propTypes = {
  filterName: PropTypes.string.isRequired,
  filterValues: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired
}

export default FilterRange
