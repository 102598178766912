import React from 'react'
import MoneyHelper from '../../helpers/money-helper'
import ItemCounts from './ItemCounts'
import PropTypes from 'prop-types'

const ProductDescriptorDetail = ({ productDescriptor, itemCounts }) => {
  return (
    <div className="flex mb-20">
      <div className="basis-3/5 pt-6">
        <h2 className="font-bold">Retail Price</h2>
        { <p>{MoneyHelper.centsToDollars(productDescriptor.retailCents)}</p> }
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Wholesale Price</h2>
        <p>{MoneyHelper.centsToDollars(productDescriptor.wholesaleCents)}</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Margin</h2>
        <p>{productDescriptor.margin}</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Weight</h2>
        <p>{productDescriptor.weight} oz</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">SKU</h2>
        <p>{productDescriptor.sku}</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Kind</h2>
        <p>{productDescriptor.kindName}</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Metal</h2>
        <p>{productDescriptor.metalName}</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Earring disc back</h2>
        <p>{productDescriptor.earringDiscBack ? 'True' : 'False'}</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Earring card</h2>
        <p>{productDescriptor.earringCard ? 'True' : 'False'}</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Replenish type</h2>
        <p>{productDescriptor.replenType}</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Discontinued</h2>
        <p>{productDescriptor.discontinued ? 'Yes' : 'No'}</p>
        <hr className="w-11/12 my-4"/>

        <h2 className="font-bold">Retired</h2>
        <p>{productDescriptor.retired ? 'Yes' : 'No'}</p>
        <hr className="w-11/12"/>
      </div>
      <div className="basis-2/5 bg-[#FBFAFC] pl-6 pt-6">
        <div>
          <h2 className="font-bold text-lg">Description Properties</h2>
          <h3 className="text-[#B6B4BA] text-sm mt-4">Carat weight</h3>
          <p data-testid="diamond-carat-weight">
            {productDescriptor.diamondCaratWeight ?? '-'}
          </p>
          <h3 className="text-[#B6B4BA] text-sm mt-4">Center Stone Total Weight</h3>
          <p data-testid="diamond-center-stone-total-weight">
            {productDescriptor.diamondCenterStoneTotalWeight ?? '-'}
          </p>
          <h3 className="text-[#B6B4BA] text-sm mt-4">Color</h3>
          <p data-testid="diamond-color">
            {productDescriptor.diamondColor ?? '-'}
          </p>
          <h3 className="text-[#B6B4BA] text-sm mt-4">Clarity</h3>
          <p data-testid="diamond-clarity">
            {productDescriptor.diamondClarity ?? '-'}
          </p>
          <h3 className="text-[#B6B4BA] text-sm mt-4">Metal detail</h3>
          <p>{productDescriptor.metalDetailName ? productDescriptor.metalDetailName : '-'}</p>
          <h3 className="text-[#B6B4BA] text-sm mt-4">Metal finish</h3>
          <p>{productDescriptor.metalFinishName}</p>
          <h3 className="text-[#B6B4BA] text-sm mt-4">Base metal</h3>
          <p>{productDescriptor.baseMetalName}</p>
          <h3 className="text-[#B6B4BA] text-sm mt-4">Other materials</h3>
          <p>{productDescriptor.otherMaterials ? productDescriptor.otherMaterials : '-'}</p>
          <h3 className="text-[#B6B4BA] text-sm mt-4">Length, height, width</h3>
          <p>{productDescriptor.lengthHeightWidth ? productDescriptor.lengthHeightWidth : '-'}</p>
        </div>
        <hr className="my-6"/>
        {<ItemCounts itemCounts={itemCounts}/>}
      </div>
    </div>
  )
}

ProductDescriptorDetail.propTypes = {
  productDescriptor: PropTypes.object.isRequired,
  itemCounts: PropTypes.object.isRequired
}

export default ProductDescriptorDetail
