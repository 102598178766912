import React from 'react'
import PropTypes from 'prop-types'

import ReservationShipmentProducts from './ReservationShipmentProducts'
import ReservationDatesRow from './ReservationDatesRow'
import PickupFeeProductDetailsColumn from '../process_shipments/PickupFeeProductDetailsColumn'
import DepositProductDetailsColumn from '../process_shipments/DepositProductDetailsColumn'

const OrderDetailsColumn = ({ reservationInfo, paymentType, promotion, setPromotion }) => {
  const { reservationShipment } = reservationInfo

  return (
    <div>
      <div className="border-b py-4 px-8">
        <h2 className="text-xl pb-4 font-semibold">Order Details</h2>
        <ReservationDatesRow reservationShipment={reservationShipment} />
      </div>
      <ReservationShipmentProducts
        reservationShipment={reservationShipment}
        reservationsBySkus={reservationInfo.reservationsBySkus}
      />
      {paymentType === 'pickup'
        ? (
          <PickupFeeProductDetailsColumn promotion={promotion} reservationShipment={reservationShipment} setPromotion={setPromotion} />
          )
        : (
          <DepositProductDetailsColumn promotion={promotion} reservationShipment={reservationShipment} />
          )
      }
    </div>
  )
}

OrderDetailsColumn.defaultProps = {
  promotion: null,
  setPromotion: null
}

OrderDetailsColumn.propTypes = {
  paymentType: PropTypes.string.isRequired,
  promotion: PropTypes.object,
  reservationInfo: PropTypes.object.isRequired,
  setPromotion: PropTypes.func
}

export default OrderDetailsColumn
