import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import camelize from 'camelize'

import ReservationCustomerHeader from '../common/ReservationCustomerHeader'
import ProcessShipmentApi from '../../api/ProcessShipmentApi'
import { isReceiving } from '../../helpers/process-shipments'
import Select from 'react-select'
import { PURCHASE_STATUSES } from '../../constants'
import { ProcessShipmentContext } from '../../contexts/ProcessShipmentContext'
import LoadingSpinner from '../shared/LoadingSpinner'

const PickupOrReturnPerson = () => {
  const [reservationShipment, setReservationShipment] = useState(null)
  const [selectedContact, setSelectedContact] = useState(null)
  const [loading, setLoading] = useState(false)
  const { productStatuses, setPickupOrReturnPerson } = useContext(ProcessShipmentContext)
  const { errorHandler } = useOutletContext()

  const reservationShipmentId = parseInt(useParams().id)
  const navigate = useNavigate()

  const handleSuccessResponse = (response) => {
    const { errorMessage } = response

    if (errorMessage) {
      console.log('Error: ', errorMessage)
      errorHandler({ bannerMessage: errorMessage })
    } else {
      const {
        reservationShipment
      } = response

      setReservationShipment(reservationShipment)
    }
  }

  useEffect(() => {
    if (isReceiving()) {
      ProcessShipmentApi.fetchReceiveReservationShipment(reservationShipmentId)
        .then(resp => resp.json())
        .then(json => {
          handleSuccessResponse(camelize(json))
        })
        .catch(() => {
          errorHandler({ bannerMessage: 'Error Reservation Shipment' })
        })
    } else {
      ProcessShipmentApi.fetchReturnReservationShipment(reservationShipmentId)
        .then(resp => resp.json())
        .then(json => {
          handleSuccessResponse(camelize(json))
        })
        .catch(() => {
          errorHandler({ bannerMessage: 'Error Reservation Shipment' })
        })
    }
  }, [])

  if (reservationShipment === null) return null

  const { contact, reservationDetail } = reservationShipment
  const outboundShipment = reservationShipment.statusName === 'In store outbound'

  const contactSelectOptions = {
    label: 'Alternate assignment',
    options: [contact].concat(reservationDetail.pickupContacts).map((contact) => {
      return {
        value: contact.id,
        label: contact.name
      }
    })
  }

  const handleContactSelectChange = (e) => {
    const selectedContact = e.value

    setSelectedContact(selectedContact)
  }

  const updatePickupPerson = () => {
    setPickupOrReturnPerson(selectedContact)
    navigate(`/outbound_shipments/${reservationShipment.id}/pickup`)
  }

  const updateReturnPerson = () => {
    setLoading(true)
    setPickupOrReturnPerson(selectedContact)
    const productsToBePurchased = productStatuses.filter((productStatus) => {
      return Object.values(PURCHASE_STATUSES).includes(productStatus.desiredStatus)
    })

    // Navigate to purchase if customer is purchasing products
    if (productsToBePurchased.length > 0) {
      return navigate(`/inbound_shipments/${reservationShipment.id}/purchase`)
    } else {
      updateProductsAndReturnPerson()
    }
  }

  const updateProductsAndReturnPerson = () => {
    ProcessShipmentApi.updateProductsAndShipment(reservationShipmentId, productStatuses, selectedContact)
      .then(resp => resp.json())
      .then(json => {
        const response = camelize(json)
        const { errorMessage } = response
        setLoading(false)

        if (errorMessage) {
          errorHandler({ bannerMessage: errorMessage })
        } else {
          confirm('Product statuses and pickup person have been saved successfully!')
          navigate(`/reservations/${reservationShipment.id}`)
        }
      })
  }

  const handleBackClick = () => {
    isReceiving()
      ? navigate(`/inbound_shipments/${reservationShipment.id}/confirm`)
      : navigate(`/outbound_shipments/${reservationShipment.id}/confirm`)
  }

  return (
    <div className="container mx-auto">
      <ReservationCustomerHeader
        contact={contact}
        reservationShipmentId={reservationShipmentId}
        qcStep={reservationShipment.statusName}
      />
      <div>
        <h3 className="text-lg font-semibold my-6">
          {outboundShipment ? 'Who is picking up this reservation?' : 'Who is returning this reservation?'}
        </h3>
        <>
          <label htmlFor="pickup_person">
            {contactSelectOptions.label}
          </label>
          <Select
            inputId="pickup_person"
            isSearchable
            placeholder="Choose one"
            options={contactSelectOptions.options}
            onChange={handleContactSelectChange}
            className="max-w-[65%] mt-2"
          />
        </>
      </div>
      <div>
        <p className="text-md font-semibold mt-10">
          Remember to confirm this person’s identity by checking their ID.
        </p>
      </div>
      <div className="flex flex-row mt-4 gap-6">
        <button
          className="bg-white rounded p-2 outline outline-indigo-500 text-indigo-500 w-[80px]"
          onClick={handleBackClick}
        >
          Back
        </button>
        <button
          className="bg-indigo-500 rounded text-white p-2 w-[250px]"
          onClick={outboundShipment ? updatePickupPerson : updateReturnPerson}
          disabled={loading}
        >
          { loading ? <LoadingSpinner/> : 'Save assignment details' }
        </button>
      </div>
    </div>
  )
}

export default PickupOrReturnPerson
