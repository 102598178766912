import Cookies from 'js-cookie'
import ApiHelper from '../helpers/api-helper'
import { setProductsFromProductStatuses } from '../helpers/process-shipments'
class ProcessShipment {
  // GET Requests
  static fetchReceiveReservationShipment (idOrTrackingNumber) {
    let params = `${idOrTrackingNumber}`
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) params += `/?[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/inbound_shipments/${params}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static fetchReturnReservationShipment (idOrTrackingNumber) {
    let params = `${idOrTrackingNumber}`
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    if (eventRentalStoreIdCookie) params += `/?[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/outbound_shipments/${params}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  // PATCH Requests
  static updateProductStatuses ({ productId, reservationId, status }) {
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/products/${productId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        event_rental_store_id: eventRentalStoreIdCookie ?? '',
        reservation_id: reservationId,
        status
      }),
      headers: ApiHelper.getHeaders()
    })
  }

  static updateProductsAndShipment (idOrTrackingNumber, productStatuses, returnedByPersonId) {
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/process_shipments/${idOrTrackingNumber}/update_products_and_shipment`, {
      method: 'PATCH',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        event_rental_store_id: eventRentalStoreIdCookie ?? '',
        products: setProductsFromProductStatuses(productStatuses),
        returned_by_contact_id: returnedByPersonId
      })
    })
  }

  // POST Requests
  static postPickupProducts (idOrTrackingNumber, pickupPersonId, productStatuses, promoCode) {
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/outbound_shipments/${idOrTrackingNumber}/checkout`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        event_rental_store_id: eventRentalStoreIdCookie ?? '',
        picked_up_by_contact_id: pickupPersonId,
        products: setProductsFromProductStatuses(productStatuses),
        promo_code: promoCode
      })
    })
  }

  static postPurchaseProducts (idOrTrackingNumber, productStatuses, promoCode, returnedByPersonId) {
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/inbound_shipments/${idOrTrackingNumber}/purchase_products`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        event_rental_store_id: eventRentalStoreIdCookie ?? '',
        products: setProductsFromProductStatuses(productStatuses),
        promo_code: promoCode,
        returned_by_contact_id: returnedByPersonId
      })
    })
  }

  static fetchPurchaseInfo (reservationId, productsToPurchase) {
    const purchaseParams = ApiHelper.parameratizeArrayForRails('product_ids', productsToPurchase.purchaseProductIds)
    const lostParams = ApiHelper.parameratizeArrayForRails('lost_product_ids', productsToPurchase.lostProductIds)
    const damagedParams = ApiHelper.parameratizeArrayForRails('damaged_product_ids', productsToPurchase.damagedProductIds)
    const fraudParams = ApiHelper.parameratizeArrayForRails('fraud_product_ids', productsToPurchase.fraudProductIds)
    const stolenParams = ApiHelper.parameratizeArrayForRails('stolen_product_ids', productsToPurchase.stolenProductIds)
    const purchasedPastDueParams = ApiHelper.parameratizeArrayForRails('purchased_past_due_product_ids', productsToPurchase.purchasedPastDueProductIds)
    const eventRentalStoreIdCookie = Cookies.get('event_rental_store_id')
    let queryString = `${purchaseParams}&${lostParams}&${damagedParams}&${fraudParams}&${stolenParams}&${purchasedPastDueParams}`
    if (eventRentalStoreIdCookie) queryString += `&[event_rental_store_id]=${eventRentalStoreIdCookie}`

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/inbound_shipments/${reservationId}/purchase_preview?${queryString}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }
}

export default ProcessShipment
