import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import camelize from 'camelize'

import LoadingSpinner from '../shared/LoadingSpinner'
import ReservationOrderDetails from '../shared/ReservationOrderDetails'
import ReservationsApi from '../../api/ReservationsApi'
import ProcessShipmentApi from '../../api/ProcessShipmentApi'
import { ProcessShipmentContext } from '../../contexts/ProcessShipmentContext'
import BackButton from '../shared/BackButton'

const PickupProducts = () => {
  const reservationShipmentId = parseInt(useParams().id)
  const navigate = useNavigate()
  const { errorHandler } = useOutletContext()
  const { pickupOrReturnPerson, productStatuses } = useContext(ProcessShipmentContext)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [reservationInfo, setReservationInfo] = useState(null)
  const [promotion, setPromotion] = useState(null)

  useEffect(() => {
    if (!reservationInfo) {
      ReservationsApi.fetchReservation(reservationShipmentId, 'include_pickup_fee_tax_info=true&include_deposit_tax_info=true')
        .then(res => res.json())
        .then(json => {
          const response = camelize(json)
          const { errorMessage } = response

          if (response.reservationShipment) {
            setReservationInfo(response)
          } else {
            errorHandler({ bannerMessage: errorMessage || 'Reservation not found' })
          }
        })
        .catch(() => {
          errorHandler({
            bannerMessage: 'Something went wrong finding reservation'
          })
        })
    }
  }, [])

  const handlePickup = () => {
    setLoadingSubmit(true)
    const promoCode = promotion?.code

    ProcessShipmentApi.postPickupProducts(reservationShipmentId, pickupOrReturnPerson, productStatuses, promoCode)
      .then(res => res.json())
      .then(json => {
        const response = camelize(json)
        const { errorMessage } = response

        if (errorMessage) {
          errorHandler({ bannerMessage: errorMessage })
          setLoadingSubmit(false)
        } else {
          navigate(`/reservations/${reservationShipmentId}`)
        }
      })
      .catch(() => {
        setLoadingSubmit(false)
        errorHandler({ bannerMessage: 'Something went wrong, please try again' })
      })
  }

  const additionalFields = () => (
    <div className="py-4 px-8">
      <div className='flex justify-start mt-4'>
        <BackButton />
        <button
          className='text-white bg-indigo-500 disabled:opacity-50 w-64 px-5 py-2.5 rounded w-max confirm-agreement'
          onClick={handlePickup}
        >
          {
            loadingSubmit ? <LoadingSpinner/> : 'Process fee & confirm pickup'
          }
        </button>
      </div>
    </div>
  )

  return (
    <div className='-mx-16'>
      <div className="flex flex-col md:justify-start mx-2">
        <div className="flex flex-row lg:justify-between md:justify-left pt-[32px] px-[63px]">
          <div className="flex h-10">
            <div className="flex items-end text-3xl text-lefts -mb-1">
              Checkout
            </div>
          </div>
        </div>
      </div>
      {reservationInfo &&
        <ReservationOrderDetails
          additionalFields={additionalFields}
          paymentType="pickup"
          promotion={promotion}
          reservationInfo={reservationInfo}
          setPromotion={setPromotion}
        />
      }
    </div>
  )
}

export default PickupProducts
