import { React, useEffect, useState } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import ReservationsApi from '../../api/ReservationsApi'
import DatesHelper from '../../helpers/dates-helper'
import BackButton from '../shared/BackButton'
import LoadingSpinner from '../shared/LoadingSpinner'
import PickupDateInput from './PickupDateInput'
import ReturnDateInput from './ReturnDateInput'

const EditReservationPickup = () => {
  const navigate = useNavigate()
  const { state: { reservationShipment } } = useLocation()
  const [pickupDate, setPickupDate] = useState(null)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const { errorHandler } = useOutletContext()

  useEffect(() => {
    const date = new Date(reservationShipment.pickupDate)
    setPickupDate(DatesHelper.formatForDateInput(date))
  }, [reservationShipment])

  const handleSave = () => {
    setLoadingSubmit(true)
    const params = {
      pickupDate,
      reservationId: reservationShipment.id
    }

    ReservationsApi.editDates(params)
      .then((response) => response.json())
      .then((json) => {
        setLoadingSubmit(false)
        if (json.error_message) {
          errorHandler({ bannerMessage: json.error_message })
        } else {
          navigate(`/reservations/${reservationShipment.id}`)
        }
      })
      .catch((error) => {
        setLoadingSubmit(false)
        errorHandler(error)
      })
  }

  return (
    <div>
      <h1 className="text-3xl py-8 border-b">Edit Pickup Date</h1>
      <div className="py-16 h-[70vh]">
        <PickupDateInput pickupDate={pickupDate} setPickupDate={setPickupDate} />
        <ReturnDateInput pickupDate={pickupDate} />
      </div>
      <div className="flex pt-32">
        <BackButton />
        <button
          className='text-white bg-indigo-500 disabled:opacity-50 w-64 px-5 py-2.5 rounded w-max ml-auto confirm-agreement ml-auto'
          onClick={handleSave}
          disabled={loadingSubmit}
        >
          {
            loadingSubmit ? <LoadingSpinner/> : 'Save'
          }
        </button>
      </div>
    </div>
  )
}

export default EditReservationPickup
