import React from 'react'
import LoadingSpinner from '../../shared/LoadingSpinner'
import PropTypes from 'prop-types'
import PasswordField from '../shared/PasswordField'
import EmailField from '../shared/EmailField'
import { useNavigate } from 'react-router-dom'
import Authorization from '../../../helpers/authorization'

const LoginForm = ({ email, password, setEmail, setPassword, sendLoginInfo, loading }) => {
  const navigate = useNavigate()

  // only render password login if enabled on test environments (for non sso use)
  const renderPasswordLogin = () => {
    return (
      <form action={`${process.env.REACT_APP_BACKEND_URL}/login`} method='POST'>
        <EmailField email={email} setEmail={setEmail} />
        <PasswordField name='Password' value={password} setValue={setPassword}/>
        <div className='p-4 flex w-full'>
          <button type='button' className='text-indigo-700' onClick={() => navigate('/reset_password_request')}>Forgot password?</button>
          <button
            disabled={loading}
            type='button'
            className='w-52 ml-auto py-4 bg-indigo-500 text-white rounded disabled:bg-gray-300'
            onClick={sendLoginInfo}
          >
              {loading ? <LoadingSpinner/> : 'Sign in'}
          </button>
        </div>
      </form>
    )
  }

  // always render SSO login
  const renderSsoLogin = () => {
    return (
      <a href={`${process.env.REACT_APP_BACKEND_URL}/sso/init`} target='_blank'>
        <div className='text-center text-2xl my-4 py-4 bg-indigo-500 text-white rounded disabled:bg-gray-300'>
          Click here to login in with your Signet Account
        </div>
      </a>
    )
  }

  return (
    <div>
      {Authorization.passwordAuthEnabled() ? renderPasswordLogin() : null}
      {renderSsoLogin()}
    </div>
  )
}

export default LoginForm

LoginForm.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  sendLoginInfo: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}
