import React from 'react'
import PropTypes from 'prop-types'
import QcStepTextHelper from '../../helpers/qc-step-text-helper'

const ReservationCustomerHeader = ({ contact, qcStep, reservationShipmentId }) => {
  const qcStepHeaderText = QcStepTextHelper.statusForPage(qcStep)

  return (
    <>
      {qcStepHeaderText && <h4 className="mb-8 text-indigo-600 text-center font-semibold text-xl">{qcStepHeaderText}</h4>}
      <h3 className="text-center">
        QCing shipment for
      </h3>
      <h2 className="font-semibold text-xl text-center">
        {`${contact.firstName} ${contact.lastName} - [Reservation shipment - ${reservationShipmentId}]`}
      </h2>
    </>
  )
}

ReservationCustomerHeader.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }).isRequired,
  qcStep: PropTypes.string.isRequired,
  reservationShipmentId: PropTypes.number.isRequired
}

export default ReservationCustomerHeader
