import React from "react";
import { useState } from "react";
import AuthenticationApi from "../../../api/AuthenticationApi";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Authorization from "../../../helpers/authorization";
import { useEffect } from "react";

const ChooseStore = () => {
  const navigate = useNavigate();
  const [storeNumberInput, setStoreNumberInput] = useState("");
  const [storeResult, setStoreResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!Authorization.isLoggedIn()) {
      navigate("/login");
    }
  }, []);

  const handleStoreSearch = () => {
    AuthenticationApi.searchStore(storeNumberInput)
      .then((response) => {
        if (response.status !== 200) throw new Error("Not 2xx response")
        return response.json()
      })
      .then((json) => {
        if (json.store) {
          setStoreResult(json.store)
        } else {
          setStoreNumberInput('')
        }
      })
      .catch(() => {
        setError("Sorry, there are no stores with the provided store number")
      })
  }

  const handleStoreSelection = async () => {
    Cookies.set('event_rental_store_id', storeResult.id)
    await AuthenticationApi.getStoreAndAssociateInfo()
      .then(() => {
        navigate('/inventory')
      })
  }

  const handleAssignedStoreClick = async () => {
    const storeId =  JSON.parse(localStorage.getItem('eventRentalStore')).id
    Cookies.set('event_rental_store_id', storeId)
    await AuthenticationApi.getStoreAndAssociateInfo()
      .then(() => {
        navigate('/inventory')
      })
  }

  const initialCTA = () => {
    return (
      <div>
        <h5>Type in the store number you are currently working at</h5>
        <br/>
        <input
          type="text"
          placeholder="Store Number"
          className="border-2 border-gray-300 rounded-md p-2 w-1/2"
          value={storeNumberInput}
          onChange={(e) => setStoreNumberInput(e.target.value)}
        />
        <br/>
        <button
          className="w-1/2 inline-flex justify-center rounded-md border border-transparent bg-indigo-500 my-2 py-2 px-4 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={handleStoreSearch}
        >
          Continue
        </button>

        <br/>

        <div className="underline" onClick={handleAssignedStoreClick}>
          Login to assigned store
        </div>
      </div>
    )
  }

  const renderError = () => {
    return (
      <div>
        <h5>{error}</h5>
        <br/>
        <button
          className="w-1/2 inline-flex justify-center rounded-md border border-transparent bg-indigo-500 my-2 py-2 px-4 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setError(null)}
        >
          Try Again
        </button>
      </div>
    )
  }

  const renderStoreResult = () => {
    const {
      name,
      address,
      city,
      state,
      zip
    } = storeResult

    const {
      "store_number": storeNumber
    } = storeResult

    return (
      <div>
        <h5>You have indicated that you are working at the location below</h5>
        <br/>

        <div className="bg-white text-left border-gray-300 border px-4 py-2 w-2/3 mx-auto">
          <div className="font-bold">
            {name}
          </div>
          <div>
            {address}
          </div>
          <div>
            {city}, {state} {zip}
          </div>
          <div>
            Store Number: {storeNumber}
          </div>
        </div>

        <br/>

        <div className="flex justify-center gap-2">
          <button
            className="w-1/2 rounded-md border border-transparent bg-indigo-500 my-2 py-2 px-4 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleStoreSelection}
          >
            Confirm
          </button>
          <button
            className="w-1/2 rounded text-indigo-500 border border-indigo-500 my-2 py-2 px-4 font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => setStoreResult(null)}
          >
            Select another store
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className='bg-gradient-to-r from-indigo-50 h-screen w-screen'>
      <div className='w-1/2 mx-auto'>
        <div className='pt-40 pb-28 text-center relative'>
          <h1 className='text-5xl'>Select Store</h1>
          <br/>
          {error && renderError()}
          {!storeResult && !error && initialCTA()}
          {storeResult && renderStoreResult()}
        </div>
      </div>
    </div>
  )
}

export default ChooseStore;