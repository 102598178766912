import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import camelize from 'camelize'

import StatusHelper from '../../helpers/status-helper'
import ProductStatus from './ProductStatus'
import ReservationCustomerHeader from '../common/ReservationCustomerHeader'
import SingleProduct from '../common/SingleProduct'
import ProcessShipmentApi from '../../api/ProcessShipmentApi'
import { isReceiving } from '../../helpers/process-shipments'

const ProductConfirmation = () => {
  const [reservationShipment, setReservationShipment] = useState(null)
  const [missingStatuses, setMissingStatuses] = useState(null)
  const [presentStatuses, setPresentStatuses] = useState(null)
  const [statusType, setStatusType] = useState(null)
  const [showProductStatus, setShowProductStatus] = useState(false)

  const reservationShipmentId = parseInt(useParams().id)
  const reservationId = parseInt(useParams().reservationId)

  const handleSuccessResponse = (response) => {
    const { errorMessage } = response

    if (errorMessage) {
      console.log('Error: ', errorMessage)
    } else {
      const {
        missingStatuses,
        presentStatuses,
        reservationShipment
      } = response

      setMissingStatuses(missingStatuses)
      setPresentStatuses(presentStatuses)
      setReservationShipment(reservationShipment)
    }
  }

  useEffect(() => {
    if (isReceiving()) {
      ProcessShipmentApi.fetchReceiveReservationShipment(reservationShipmentId)
        .then(resp => resp.json())
        .then(json => {
          handleSuccessResponse(camelize(json))
        })
    } else {
      ProcessShipmentApi.fetchReturnReservationShipment(reservationShipmentId)
        .then(resp => resp.json())
        .then(json => {
          handleSuccessResponse(camelize(json))
        })
    }
  }, [])

  useEffect(() => {
    setShowProductStatus(false)
  }, [reservationId])

  const handleConfirmation = confirmationStatus => () => {
    setStatusType(confirmationStatus)
    setShowProductStatus(true)
  }

  const checkIfItemIsBeingReturned = () => {
    if (!isReceiving()) return false
    if (reservationShipment.statusName === 'With customer') return true
    return false
  }

  if (reservationShipment === null) return null

  const { contact, reservations } = reservationShipment
  const reservation = reservations.find(res => res.id === reservationId)

  const singleProduct = (
    <SingleProduct
      reservation={reservation}
      statuses={{ ...missingStatuses, ...presentStatuses }}
    />
  )

  if (showProductStatus) {
    const statuses = statusType === 'present'
      ? StatusHelper.filterStatuses(presentStatuses, reservation.product)
      : missingStatuses

    return (
      <ProductStatus
        statusType={statusType}
        reservation={reservation}
        reservationShipment={reservationShipment}
        singleProduct={singleProduct}
        statuses={statuses}
      />
    )
  }

  return (
    <div className="container mx-auto">
      <ReservationCustomerHeader
        contact={contact}
        reservationShipmentId={reservationShipmentId}
        qcStep={reservationShipment.statusName}
      />
      <h3 className="text-center font-bold text-indigo-500 text-xl">
        {checkIfItemIsBeingReturned() ? 'Is this piece being returned?' : 'Is this item here?' }
      </h3>
      {singleProduct}
      <div className="mt-8 grid grid-cols-2 m-2 gap-x-12">
        <button
          type="button"
          className="border border-blue-600 rounded p-2 w-1/4 text-blue-600 justify-self-end"
          onClick={handleConfirmation('missing')}
        >
          No
        </button>
        <button
          type="button"
          className="bg-indigo-600 rounded p-2 w-1/4 text-white justify-self-start"
          onClick={handleConfirmation('present')}
        >
          Yes
        </button>
      </div>
    </div>
  )
}

export default ProductConfirmation
