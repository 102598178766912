import React from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from './LoadingSpinner'

const PopUpModal = ({ closeModal, handleAccept, header, mainText, loading = false, leftButtonLabel = 'Nevermind', rightButtonLabel = 'Yes I\'m sure' }) => {
  return (
    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-30'>
      <div className='mx-auto mt-80 py-10 w-[60%] text-center p-4 bg-white rounded'>
        <h1 className='text-xl'>{header}</h1>
        <h4 className='py-10'>{mainText}</h4>
        <div className='flex justify-around'>
          {leftButtonLabel && <button
            className='border border-solid border-gray-600 px-5 py-2.5 rounded w-max'
            type='button'
            onClick={closeModal}
          >
            {leftButtonLabel}
          </button>}
          {rightButtonLabel && <button
            className='text-white w-45 bg-indigo-600 px-5 py-2.5 rounded w-max disabled:opacity-50'
            type='button'
            onClick={handleAccept}
            disabled={loading}
          >
            {loading ? <LoadingSpinner/> : rightButtonLabel }
          </button>}
        </div>
      </div>
    </div>

  )
}

PopUpModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  mainText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  rightButtonLabel: PropTypes.string
}

export default PopUpModal
