import React from 'react'
import PropTypes from 'prop-types'
import Authorization from '../helpers/authorization'
import { ReactComponent as LogoutIcon } from '../assets/logout-icon.svg'

const Header = ({ isProcessingShipment, setLoggedIn }) => {
  const getStoreName = () => {
    const storeName = JSON.parse(localStorage.getItem('eventRentalStore'))?.name
    return storeName ?? 'Rental Store'
  }
  const getAssociateEmail = () => {
    return localStorage.getItem('associateEmail')?.replace(/"|'/g, '')
  }
  const storeName = getStoreName().toUpperCase()

  const handleLogout = () => {
    Authorization.logout()
    setLoggedIn(false)
  }
  return (
     <div className='bg-white pt-[32px] px-[63px] sticky z-10'>
       { !isProcessingShipment && <div>
         <div className='flex'>
           <div className='grow font-bold xs:text-base sm:text-lg md:text-2xl lg:text-3xl'>
             {storeName}
           </div>
           <div
             onClick={handleLogout}
             className='inline-flex justify-center items-center rounded-md border border-transparent bg-[#6558F5] text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-[#FBFAFC] disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none w-24 h-10'
             type='button'
             data-testid='sign-out-button'
           >
             <span className="px-1">Sign out</span>
             <LogoutIcon className="pl-1"/>
           </div>
         </div>
         <div className='flex justify-start text-[#9C9C9C] underline text-xs pt-1'>
           <div className='email'>
             {getAssociateEmail()}
           </div>
         </div>
       </div>}
    </div>
  )
}

export default Header

Header.propTypes = {
  isProcessingShipment: PropTypes.bool.isRequired,
  setLoggedIn: PropTypes.func.isRequired
}
