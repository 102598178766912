import React from 'react'

const MpcMenu = () => {
  return (
    <div className="container mx-auto">
      <div className="border-b-2 my-6"/>
      <div className='flex gap-4 justify-center align-center'>
        <a className='bg-indigo-500 rounded text-white p-2' href="/mpc/mpc_count">Start MPC</a>
        <a className='bg-indigo-500 rounded text-white p-2' href="/mpc/report_lost">Report Lost Item</a>
        <a className='bg-indigo-500 rounded text-white p-2' href="/mpc/check_in_recovered">Check In Recovered Item</a>
      </div>
    </div>
  )
}

export default MpcMenu
