import React, { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import ReservationsApi from '../../api/ReservationsApi'
import PropTypes from 'prop-types'
import camelize from 'camelize'
import PopUpModal from '../shared/PopUpModal'

const CancelReservationButton = ({ reservation, redirectTo = null, closeModal }) => {
  const navigate = useNavigate()
  const { errorHandler } = useOutletContext()
  const [loading, setLoading] = useState(false)

  redirectTo = redirectTo || 0

  const cancelAndRestart = () => {
    setLoading(true)
    // api post to cancel current reservation
    ReservationsApi.cancelReservation(reservation.id)
      .then(res => res.json())
      .then((json) => {
        const result = camelize(json)
        if (result.errorMessage) {
          errorHandler({ bannerMessage: result.errorMessage }, document)
          setLoading(false)
        } else {
          navigate(redirectTo)
        }
      })
      .catch(() => {
        errorHandler({ bannerMessage: 'Something went wrong cancelling this reservation. Please try again.' }, document)
        setLoading(false)
      })
  }
  return (
    <PopUpModal
      closeModal={closeModal}
      handleAccept={cancelAndRestart}
      header='Cancel Reservation'
      loading={loading}
      mainText='Are you sure you want to cancel?'
    />
  )
}

CancelReservationButton.propTypes = {
  reservation: PropTypes.object.isRequired,
  redirectTo: PropTypes.string,
  closeModal: PropTypes.func.isRequired
}

export default CancelReservationButton
