class DatesHelper {
  static getDayOfTheWeek = (date, whichDay) => {
    date = new Date(date)
    const first = date.getDate() - (date.getDay() - 1) // First day is the day of the month - the day of the week(+1)
    const last = first + 6 // last day is the first day + 6

    const firstday = new Date(date.setDate(first)).toDateString()
    const lastday = new Date(date.setDate(last)).toDateString()

    switch (whichDay) {
      case 'first':
        return firstday
      case 'last':
        return lastday
      default:
        return date
    }
  }

  static addWeeksToDate = (dateObj, numberOfWeeks) => {
    dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7)
    return dateObj
  }

  static addDaysToDate = (dateObj, numberOfDays) => {
    dateObj.setDate(dateObj.getDate() + numberOfDays)
    return dateObj
  }

  static formatStandard (date) {
    return (new Date(date).toISOString().split('T')[0])
  }

  static formatDateWithMonthAbbr (date) {
    return new Date(date).toLocaleDateString('en-us', { timeZone: 'UTC', day: 'numeric', month: 'short', year: 'numeric' })
  }

  static formatForDateInput (date) {
    return date.toISOString().split('T')[0]
  }
}

export default DatesHelper
