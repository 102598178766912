import React, { useEffect, useState } from 'react'
import ReservationsApi from '../../api/ReservationsApi'
import ReservationsTable from '../shared/ReservationsTable'
import ErrorHelper from '../../helpers/error-helper'
import ErrorPopup from '../shared/ErrorPopup'
import camelize from 'camelize'

const PastDue = () => {
  const [pastDueReservations, setPastDueReservations] = useState([])
  const [error, setError] = useState({})
  const [sort, setSort] = useState('desc')
  const [sortedField, setSortField] = useState('id')

  const handleSort = (field) => {
    setSortField(field)
    setSort(prevSort => prevSort === 'desc' ? 'asc' : 'desc')
  }

  const queryAndSetReservations = () => {
    const params = {
      order: sort,
      sort: sortedField
    }

    ReservationsApi.fetchPastDueReservations(params)
      .then(response => response.json())
      .then(json => {
        if (json.error_message) {
          const error = { bannerMessage: json.error_message }
          ErrorHelper.addError(error, setError, document.querySelector('div'))
        } else {
          const camelized = camelize(json)
          const reservations = camelized.reservationShipments
          setPastDueReservations(reservations)
        }
      })
      .catch(() => {
        ErrorHelper.addError('Something went wrong checking fetching past due reservations', setError, document.querySelector('div'))
      })
  }

  // fetch reservations when component mounts
  useEffect(() => {
    queryAndSetReservations()
  }, [sort, sortedField])

  return (
    <div className="container mx-auto">
      <div className="sticky top-40 z-30 border-b-2 bg-white pb-6 pt-2">
        {error && <ErrorPopup error={error}/>}
      </div>
        <ReservationsTable handleSort={handleSort} reservations={pastDueReservations} sort={sort} sortedField={sortedField} sorting={true}/>
    </div>
  )
}

export default PastDue
