import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const BackButton = ({ buttonLabel, className, dataTestId, handleGoBack }) => {
  const navigate = useNavigate()

  const defaultHandleGoBack = () => {
    navigate(-1)
  }

  return (
    <div className='flex'>
      <div
        onClick={handleGoBack ?? defaultHandleGoBack}
        className={className ?? 'px-5 py-2.5 rounded border mr-8 back'}
        type='button'
        data-testid={dataTestId ?? 'back-button'}
      >
       {buttonLabel ?? 'Back'}
      </div>
    </div>
  )
}

BackButton.propTypes = {
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  handleGoBack: PropTypes.func
}

export default BackButton
