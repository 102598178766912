class FormValidations {
  static checkFieldValidity (fieldNames, fieldNameSuffix = null) {
    const invalidFields = []

    fieldNames.forEach(value => {
      const name = fieldNameSuffix ? value.concat(fieldNameSuffix) : value
      const field = document.getElementsByName(name)[0]
      if (!field.checkValidity()) {
        invalidFields.push(field)
      }
    })

    return invalidFields
  }

  static addErrorBorderToField (field, borderToRemove = 'border-gray-200') {
    field.classList.add('border-solid')
    field.classList.add('border-red-600')
    field.classList.remove(borderToRemove)

    setTimeout(() => {
      document.addEventListener('click', () => {
        field.classList.remove('border-red-600')
        field.classList.add(borderToRemove)
      }, { once: true })
    }, 500)
  }

  static addErrorMessageToField (field) {
    const errorMessageDiv = field.parentNode.querySelector('.validationMessage')
    if (field.title) {
      errorMessageDiv.textContent = field.title
    } else {
      errorMessageDiv.textContent = field.validationMessage
    }

    setTimeout(() => {
      document.addEventListener('click', () => {
        errorMessageDiv.textContent = ''
      }, { once: true })
    }, 500)
  }

  // auto formats a phone number based on elem.
  // Takes in a callback to update state based on key/value
  static formatPhoneNumberWithCallback (phoneNumber, phoneCallback) {
    const formattedPhoneNumber = this.formatPhoneNumber(phoneNumber)

    phoneCallback('phone', formattedPhoneNumber)
  }

  static formatPhoneNumber (phoneNumber) {
    const str = phoneNumber.replace(/\D/g, '').replace(/^1/, '')
    let formattedString = str
    if (str.length > 6) {
      formattedString = str.replace(/(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3')
    } else if (str.length > 3) {
      formattedString = str.replace(/(\d{3})(\d{1,3})/, '$1-$2')
    }
    const newString = '1-' + formattedString

    return newString
  }
}

export default FormValidations
