import React from 'react'
import PropTypes from 'prop-types'

import DatesHelper from '../../helpers/dates-helper'
import MoneyHelper from '../../helpers/money-helper'
import ReservationsHelper from '../../helpers/reservations-helper'

const DepositProductDetailsColumn = ({ promotion, reservationShipment }) => {
  const { depositTaxInfo, pickupFeeTaxInfo } = reservationShipment

  return (
    <>
      <div className="border-b py-4 px-8 grid grid-cols-2">
        <div className="text-gray-500">
          Subtotal
        </div>
        <div className="justify-self-end pb-4" data-testid='subtotal-amount'>
          {MoneyHelper.centsToDollars(reservationShipment.totalRetailPrice)}
        </div>
        <div className="text-gray-500 pb-4">Total Rental Fee</div>
        <div className="justify-self-end" data-testid="total-rental-fee-amount">
          {MoneyHelper.centsToDollars(reservationShipment.rentalFee)}
        </div>
        <div className="text-gray-500">
          Amount due on {DatesHelper.formatDateWithMonthAbbr(reservationShipment.pickupDate)}
        </div>
        <div className="justify-self-end" data-testid='total-due-at-pickup-amount'>
          {MoneyHelper.centsToDollars(pickupFeeTaxInfo.totalBeforeTax)}
        </div>
      </div>

      <div className="border-b py-4 px-8 grid grid-cols-3 pb-4">
        <div className="font-bold col-span-2">
          Amount to be paid today
        </div>
        <div className="justify-self-end pb-4 font-bold col-span-1" data-testid='total-due-first-day-amount'>
          {MoneyHelper.centsToDollars(depositTaxInfo.totalBeforeTax)}
        </div>
        <div className="text-gray-500 col-span-2" data-testid='tax-rate'>
          Tax ({MoneyHelper.formatTaxRate(depositTaxInfo.taxRate * 100)}%)
        </div>
        <div className="justify-self-end text-gray-500 col-span-1" data-testid='tax-due-amount'>
          {MoneyHelper.centsToDollars(depositTaxInfo.taxInCents)}
        </div>
        {promotion &&
          <>
            <div className="text-gray-500 col-span-2" data-testid='promotion'>
              {promotion.name}
            </div>
            <div className="justify-self-end text-gray-500 col-span-1" data-testid='promotion-amount'>
              - {MoneyHelper.centsToDollars(ReservationsHelper.getPromotionDiscount(promotion, depositTaxInfo.totalBeforeTax))}
            </div>
          </>
        }
      </div>

      <div className="border-b py-4 px-8 grid grid-cols-3 pb-4">
        <div className="font-bold col-span-2">
          Due now
        </div>
        <div className="justify-self-end font-bold col-span-1" data-testid='total-due-now-amount'>
          {MoneyHelper.centsToDollars(depositTaxInfo.totalAfterTax - ReservationsHelper.getPromotionDiscount(promotion, depositTaxInfo.totalBeforeTax))}
        </div>
      </div>

      <div className="py-4 px-8">
        <p>Cancellation.  You must cancel your reservation ten (10) days before your Pick Up Day to avoid being charged.  You will be charged $15 if you cancel within 9 days of your Pick Up Day.</p>
      </div>
    </>
  )
}
DepositProductDetailsColumn.defaultProps = {
  promotion: null
}

DepositProductDetailsColumn.propTypes = {
  promotion: PropTypes.object,
  reservationShipment: PropTypes.object.isRequired
}

export default DepositProductDetailsColumn
