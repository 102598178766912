import Cookies from 'js-cookie'
import AuthenticationApi from '../api/AuthenticationApi'
class Authorization {
  static isLoggedIn = () => {
    const hasCredentials = localStorage.getItem('jwtToken')
    const credentialsExpired = Cookies.get('loggedIn') !== 'true'

    if (hasCredentials) {
      if (credentialsExpired) {
        this.logout()
      } else {
        return true
      }
    } else {
      this.logout()
    }
  }

  static setLogin = (token) => {
    this.setJwtToken(token)
    this.setLoginCookie('true')
  }

  static storeIdCookie = () => {
    return Cookies.get('event_rental_store_id')
  }

  static setJwtToken = (token) => {
    localStorage.setItem('jwtToken', token)
  }

  // we can set this cookie to expire which will auto logoff associates
  // JWT is set in backend to expire after 1 hours
  // we use the loggedInTime to prompt them to refresh their JWT by expiring it slightly earlier than the JWT expiry
  static setLoginCookie = (value) => {
    const inOneHour = 1 / 24
    const inOneHourDate = new Date(Date.now() + 60 * 60 * 1000)
    Cookies.set('loggedIn', value, {
      expires: inOneHour
    })
    Cookies.set('loggedInTime', inOneHourDate, {
      expires: inOneHour
    })
  }

  // want to call this whenever there is a 401 from the backend
  static logout = () => {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('eventRentalStore')
    Cookies.remove('event_rental_store_id')
    this.setLoginCookie('false')
  }

  // this is where we can set the FullStory user
  static identifyFullstoryUser = () => {
    const store = JSON.parse(localStorage.getItem('eventRentalStore'))
    const email = localStorage.getItem('associateEmail')
    if (store && email && process.env.REACT_APP_REACT_ENV === 'production') {
      window.FS.identify(email, {
        displayName: email,
        email,
        storeName: store.name
      })
    }
  }

  static setRefreshToken = () => {
    AuthenticationApi.getRefreshToken()
      .then(res => res.json())
      .then(json => {
        console.log('refresh token success')
        if (json.jwt) {
          Authorization.setLogin(json.jwt)
        } else {
          console.log('refresh token failed')
        }
      })
      .catch((err) => {
        console.log('refresh token error', err)
      })
  }

  static passwordAuthEnabled = () => {
    return process.env.REACT_APP_PASSWORD_AUTH_ENABLED === 'true'
  }
}

export default Authorization
