import React, { useState } from 'react'
import MoneyHelper from '../../helpers/money-helper'
import PropTypes from 'prop-types'
import ReservationsHelper from '../../helpers/reservations-helper'

const ReservationShipmentProducts = ({ reservationShipment, reservationsBySkus }) => {
  const getPdInfo = () => {
    const { reservations } = reservationShipment
    return ReservationsHelper.hashedPdsByReservations(reservations)
  }

  const [productDescriptorInfo] = useState(getPdInfo())

  return (
    <div>
      {
        reservationsBySkus.map((skuInfo, index) => {
          const details = productDescriptorInfo[[skuInfo.sku]]
          return (
            <div className="grid grid-cols-6 border-b py-4 px-8 text-gray-500" key={skuInfo.sku}>
              <div className="col-span-1 p-2">
                <img src={details.imageUrl} />
              </div>
              <div className="col-span-2">
                <div className="font-semibold text-black">{details.name}</div>
                <div>SKU: {details.sku}</div>
                <div>Color: {details.color}</div>
              </div>
              <div className="pl-6 col-span-1">
                x{skuInfo.quantity}
              </div>
              <div className="col-span-2 justify-self-end text-black" data-testid={`retail-amount-${index}`}>
                {MoneyHelper.centsToDollars(details.retailCents)}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ReservationShipmentProducts

ReservationShipmentProducts.propTypes = {
  reservationShipment: PropTypes.object.isRequired,
  reservationsBySkus: PropTypes.array.isRequired
}
