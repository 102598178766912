import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import ConfirmShipment from './ConfirmShipment'
import PickupOrReturnPerson from './PickupOrReturnPerson'
import ProcessShipment from './ProcessShipment'
import ProductConfirmation from './ProductConfirmation'
import PickupProducts from './PickupProducts'
import ScanShipment from './ScanShipment'
import { ProcessShipmentContext } from '../../contexts/ProcessShipmentContext'

const ProcessOutboundShipmentContainer = () => {
  const [productStatuses, setProductStatuses] = useState([])
  const [pickupOrReturnPerson, setPickupOrReturnPerson] = useState(null)

  const addProductStatus = productStatus => {
    setProductStatuses(prevProductStatuses => [...prevProductStatuses, productStatus])
  }

  return (
    <ProcessShipmentContext.Provider value={{ productStatuses, addProductStatus, pickupOrReturnPerson, setPickupOrReturnPerson }}>
      <Routes>
        <Route path="scan" element={<ScanShipment />} />
        <Route path=":id" element={<ProcessShipment />} />
        <Route path=":id/:reservationId" element={<ProductConfirmation />} />
        <Route path=":id/confirm" element={<ConfirmShipment />} />
        <Route path=":id/pickup_person" element={<PickupOrReturnPerson />} />
        <Route path=":id/pickup" element={<PickupProducts />} />
      </Routes>
    </ProcessShipmentContext.Provider>
  )
}

export default ProcessOutboundShipmentContainer
