import React, { useState } from 'react'
import ErrorHelper from '../../../../helpers/error-helper'
import FormValidations from '../../../../helpers/form-validations'
import ErrorPopup from '../../../shared/ErrorPopup'
import PropTypes from 'prop-types'
import FormInputFieldWithLabel from '../../../shared/FormInputFieldWithLabel'

const AddOrEditPickupPersonModal = ({ addOrEditPickupPerson, closeModal, isEdit, currentFormValues }) => {
  if (isEdit && !currentFormValues) return

  const [formValues, setFormValues] = useState(currentFormValues ?? {
    firstName: '',
    lastName: '',
    email: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
  })

  const [error, setError] = useState(null)
  const [modifiedFields, setModifiedFields] = useState({})

  const handleFormChange = (event) => {
    const suffixStart = event.target.name.indexOf('-')
    const key = event.target.name.slice(0, suffixStart)
    const value = event.target.value

    if (isEdit) {
      updateModifiedFields(key, value)
    }

    if (key === 'phone') {
      FormValidations.formatPhoneNumberWithCallback(value, updateFormValue)
      return
    }
    updateFormValue(key, value)
  }

  const updateFormValue = (key, value) => {
    setFormValues(prevValues => (
      { ...prevValues, [key]: value }
    ))
  }

  const updateModifiedFields = (key, value) => {
    if (isAddressField(key)) {
      const address = getFullAddress(key, value)
      setModifiedFields({ ...modifiedFields, [key]: currentFormValues.address !== address })
      return
    }
    setModifiedFields({ ...modifiedFields, [key]: currentFormValues[key] !== value })
  }

  const isAddressField = (key) => {
    return key === 'streetAddress1' || key === 'streetAddress2'
  }

  const getFullAddress = (key, value) => {
    let fullAddress = value.trim()
    if (key === 'streetAddress1' && formValues.streetAddress2) {
      fullAddress += ' ' + formValues.streetAddress2
    } else if (key === 'streetAddress2' && formValues.streetAddress1) {
      fullAddress = formValues.streetAddress1 + ' ' + fullAddress
    }
    return fullAddress.trim()
  }

  const isSaveDisabled = () => {
    if (!isEdit) return false

    return Object.keys(modifiedFields).filter(key => modifiedFields[key]).length === 0
  }

  const handleFormSubmit = () => {
    const fieldNameSuffix = '-pickup-form'
    const invalidFields = FormValidations.checkFieldValidity(Object.keys(formValues), fieldNameSuffix)

    if (invalidFields.length > 0) {
      invalidFields.forEach(field => {
        FormValidations.addErrorBorderToField(field)
      })
      ErrorHelper.addError({ bannerMessage: 'Please fill out all required fields' }, setError, document.querySelector('form'))
      FormValidations.addErrorMessageToField(invalidFields[0])
    } else {
      closeModal()
      addOrEditPickupPerson(formValues)
      setError(null)
    }
  }

  return (
    <div id='add-pickup-person-modal' tabIndex="-1" className='fixed flex inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-x-hidden overflow-y-auto h-modal'>
      <div className="bg-white p-8 inset-0 text-left border border-solid border-solid-gray border-4 w-[80%] my-auto mx-auto py-10">
        <h1 className="text-3xl font-bold pb-8">
          Alternate assignment details
        </h1>
        {error && <ErrorPopup error={error} />}
        <form>
          <div className='md:col-span-4 bg-neutral-100 border-t-2 border-gray-200 outline-none'>
            <div className='px-8 pt-6'>
              <h3 className='text-md font-medium leading-6 text-gray-900'>Pickup Person Information</h3>
            </div>
            <div className='grid grid-cols-4 gap-4'>
              <FormInputFieldWithLabel
                label='First Name*'
                className='col-span-2 pl-8 pt-3'
                onChange={handleFormChange}
                value={formValues.firstName}
                inputName='firstName-pickup-form'
                required
                ariaLabel='pickup-form-firstName-input'
              />
              <FormInputFieldWithLabel
                label='Last Name*'
                className='col-span-2 pt-3 pr-8'
                onChange={handleFormChange}
                value={formValues.lastName}
                inputName='lastName-pickup-form'
                required
                ariaLabel='pickup-form-lastName-input'
              />
              <FormInputFieldWithLabel
                label='Email Address'
                className='col-span-2 pl-8'
                onChange={handleFormChange}
                value={formValues.email}
                inputName='email-pickup-form'
                type='email'
                ariaLabel='pickup-form-email-input'
              />
              <FormInputFieldWithLabel
                label='Phone*'
                className='col-span-2 pr-8'
                onChange={handleFormChange}
                value={formValues.phone}
                inputName='phone-pickup-form'
                type='telephone'
                required
                pattern=".{0}|.{14,14}"
                title=" (Format is 1-xxx-xxx-xxxx)"
                ariaLabel='pickup-form-phone-input'
              />
              <FormInputFieldWithLabel
                label='Street Address 1'
                className='col-span-4 px-8'
                onChange={handleFormChange}
                value={formValues.streetAddress1}
                inputName='streetAddress1-pickup-form'
                ariaLabel='pickup-form-streetAddress1-input'
              />
              <FormInputFieldWithLabel
                label='Street Address 2'
                className='col-span-4 px-8'
                onChange={handleFormChange}
                value={formValues.streetAddress2}
                inputName='streetAddress2-pickup-form'
                ariaLabel='pickup-form-streetAddress2-input'
              />
              <FormInputFieldWithLabel
                label='City'
                className='col-span-2 pl-8'
                onChange={handleFormChange}
                value={formValues.city}
                inputName='city-pickup-form'
                ariaLabel='pickup-form-city-input'
              />
              <FormInputFieldWithLabel
                label='State'
                className='col-span-1 pl-8'
                onChange={handleFormChange}
                value={formValues.state}
                inputName='state-pickup-form'
                ariaLabel='pickup-form-state-input'
              />
              <FormInputFieldWithLabel
                label='Zip Code'
                className='col-span-1 pr-8'
                onChange={handleFormChange}
                value={formValues.zip}
                inputName='zip-pickup-form'
                ariaLabel='pickup-form-zip-input'
              />
            </div>
            <div className='col-span-2 flex flex-row space-x-8 pl-8 pb-8'>
              <button
                className='text-zinc-800 border border-solid border-gray-200 inline-flex justify-center py-2 px-4 text-sm font-medium text-white shadow-sm rounded w-max h-10' type='button'
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className='inline-flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-[#FBFAFC] disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none h-10'

                type='button'
                onClick={handleFormSubmit}
                disabled={isSaveDisabled()}
              >
                {isEdit ? 'Save' : 'Add to reservation'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

AddOrEditPickupPersonModal.propTypes = {
  addOrEditPickupPerson: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  currentFormValues: PropTypes.object
}

export default AddOrEditPickupPersonModal
