const TableBody = ({ tableData, columns }) => {
  return (
    <tbody>
      {tableData.map((data) => {
        return (
          <tr key={data.id}>
            {columns.map(({ accessor }) => {
              let tData = data[accessor] ? data[accessor] : "——"
              accessor === 'updatedAt' ? tData = new Date(tData).toDateString() : tData

              return (
                <td className="border p-2" key={accessor}>
                  {tData}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  );
};

export default TableBody;
