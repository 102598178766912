import ApiHelper from '../helpers/api-helper'

class MpcApi {
  static checkInventory (action) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/cycle_counts/inventory_check?mpc_action=${action}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static exitProductFromInventory (productId, exitReason) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/cycle_counts/inventory_exit`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        product_id: productId,
        exit_reason: exitReason
      })
    })
  }

  static enterProductToInventory (productId) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/cycle_counts/inventory_entrance`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        product_id: productId
      })
    })
  }

  static fetchInventory () {
    console.log('fetchInventory')
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/store_inventory?store_id=${ApiHelper.getStoreId()}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static newCycleCount () {
    console.log('newCycleCount')
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/cycle_counts/new?store_associate_email=${ApiHelper.getStoreAssociateEmail()}`, {
      method: 'GET',
      headers: ApiHelper.getHeaders()
    })
  }

  static submitCycleCount (count, attemptNumber) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/cycle_counts`, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body: JSON.stringify({
        cycle_count: {
          count
        },
        attempt_number: attemptNumber,
        store_associate_email: ApiHelper.getStoreAssociateEmail()
      })
    })
  }
}

export default MpcApi
